import { RouteObject } from 'react-router';
import MembershipConfirmPage from './MembConfirm/MembConfirm.page';
import MembershipFinishPage from './MembFinish/MembFinish.page';
import MembershipPasswordPage from './MembPassword/MembPassword.page';
import MembershipRegisterPage from './MembRegister/MembRegister.page';

export const MembershipRoutes: Array<RouteObject> = [
	{
		path: '/registry',
		element: <MembershipRegisterPage />,
	},
	// {
	// 	path: '/registry-password',
	// 	element: <MembershipPasswordPage />,
	// },
	{
		path: '/registry-finish',
		element: <MembershipFinishPage />,
	},
	{
		path: '/registry-confirm',
		element: <MembershipConfirmPage />,
	},
];
