import ehpAxios from 'shared/axios/axios.service';
import { ReservationDataModel } from 'shared/models/reservation.model';

export module ReservationService {
	export const getCurrentTripByTicketTerritory = async (uuid: string): Promise<Array<ReservationDataModel.Trip>> => {
		try {
			const result = await ehpAxios.get(`/trip/territory/${uuid}`);
			return result.data;
		} catch (e) {
			console.log(e);
			throw e;
		}
	};
}
