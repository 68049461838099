import styled from 'styled-components';
import { breakpoints } from 'theme/breakpoints';
import colors from 'theme/colors';

const AffilSelectionStyles = styled.div`
  max-width: 390px;
  margin: 0 auto;
  padding-top: 0;
  background-color: ${colors.white};

  .title-banner {
    background-color: ${colors.blueDianne};
    padding: 0.5rem;
    margin-bottom: 35px;
  }

  @media ${breakpoints.tablet} {
    max-width: 480px;
    padding: 12px 10px;
  }

  .affil-selection-title-1 {
    color: ${colors.white};
    text-align: center;
    padding: 0 35px;
    font-size: 14px;
  }

  .affil-selection-title {
    color: ${colors.white};
    text-align: center;
    padding: 0 35px 0;

    span {
      text-transform: uppercase;
    }
  }

  .affil-selection-subtitle {
    color: ${colors.grayJuniper};
    margin-top: 20px;
    margin-bottom: 15px;
    padding: 0 38px;
    font-size: 18px;
  }

  .affil-selection-form {
    margin-bottom: 31px;
    padding: 0 20px;
  }

  .affil-selection-option-container {
    margin-bottom: 31px;
    display: block;
  }

  .affil-selection-radio {
  }

  .affil-selection-passengers {
    flex-direction: row;
    align-items: center;
    padding-left: 10px;
    margin-bottom: 10px;

    label {
      font-family: Spartan Medium;
      font-size: 16px;
    }

    input {
      height: 24px;
      width: 60px;
      margin-left: 10px;
      text-align: center;
      font-size: 16px;
    }
  }

  .affil-selection-info {
    background-color: ${colors.bluePolar};
    padding: 20px 35px;
    margin-bottom: 26px;
  }

  .affil-selection-membership {
    text-align: center;
    line-height: 1.3;
  }

  .affil-selection-price-regular {
    text-align: center;
    margin-bottom: 20px;

    span {
      font-family: Spartan Bold;
      text-decoration: line-through;
    }
  }

  .affil-selection-price-final {
    text-align: center;
    span {
      font-family: Spartan Bold;
      color: ${colors.blueTurquoise};
    }
  }

  .affil-selection-actions {
    display: flex;
    justify-content: space-between;
    flex-direction: column;
    gap: 16px;
    padding: 0 35px;
    margin-bottom: 30px;
    margin-top: 40px;
    button {
      width: 100%;
      border-radius: 5px;
      max-height: 60px !important;
      line-height: 28px !important;
      font-size: 1.2rem !important;
    }
  }
  .select-passengers{
    width: 70px;
    height: 30px;
    font-size: 20px;
    
  }
  .custom-select {
    -webkit-appearance: none;
   
    -moz-appearance: none;
    background-color: ${colors.white};
    color: ${colors.blueDianne};
    border-radius: 5px;
    width: 70px;
    height: 30px;
    font-size: 20px;
    font-family: Spartan Medium, sans-serif;
    text-align: center;
    text-indent: 1px;
    border-width:2px;
    text-overflow: '';
    
    
    border: 2px solid ${colors.black};
    
  }

  .custom-select:focus {
    -webkit-appearance: none;
    -moz-appearance: none;
    background-color: ${colors.white};
    color: ${colors.blueDianne};
    border-radius: 5px;
    width: 70px;
    height: 30px;
    font-size: 20px;
    font-family: Spartan Medium, sans-serif;
    text-align: center;
    text-indent: 1px;
    text-overflow: '';
    border: 2px solid ${colors.black};
    
  }

  .custom-select:active {
    -webkit-appearance: none;
    -moz-appearance: none;
    background-color: ${colors.white};
    color: ${colors.blueDianne};
    border-radius: 5px;
    width: 70px;
    height: 30px;
    font-size: 20px;
    font-family: Spartan Medium, sans-serif;
    text-align: center;
    text-indent: 1px;
    text-overflow: '';
    border: 2px solid ${colors.black};
    
  }

  .selection-combo-container {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 10px;
  }

  .item-alert-warning {
    line-height: 25px;
    text-align: left;
    padding: 10px 20px;
    margin: 20px 15px;
    border-radius: 14px;
    background: #ffbb33;
    font-weight: 400;
    font-size: 18px;
  }

  .selection-affiliation-passsengers {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 10px 40px;
    .label-4 {
      padding-left: 6px;
    }
  }

  button:disabled {
    background-color: ${colors.grayTower};
    border-color: ${colors.grayTower};
  }

    .option-disabled-territory-warning {
        background-color: rgba(255,204,0, 0.12);
        margin-bottom: 20px;
        padding: 10px;
        text-decoration: none;
        color: #264653;
        text-align: center;
        border-radius: 10px;
        display: block;

        span {
            font-weight: bold;
        }
    }
`;

export default AffilSelectionStyles;
