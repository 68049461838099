import GroupIcon from 'asset/Group.png';
import WhatsappStyles from './Whatsapp.styles';
import { useTranslation } from 'react-i18next';
export function Whatsapp(): JSX.Element {
  const { t } = useTranslation();
  return (
    <WhatsappStyles>
       <img src="https://firebasestorage.googleapis.com/v0/b/easyhop-ac384.appspot.com/o/resources%2Fwhatsapp-icon.svg?alt=media&token=2f572f63-14b6-4a75-9f44-92118c5c2a10" alt='Whatsapp' />
      <a href='https://api.whatsapp.com/send?phone=50661309784'>
       {t('assistanceInfo')}
      </a>
    </WhatsappStyles>
  );
}
