import ehpAxios from 'shared/axios/axios.service';
import { CategoryDataModel } from 'shared/models';
import { LocalStorageService } from './localStorage.service';

export module CategoryService {
  export const getTicketCategories = async (): Promise<
    Array<CategoryDataModel.ICategory>
  > => {
    try {
      const result = await ehpAxios.get('/ticket/category');
      return result.data;
    } catch (e) {
      console.log(e);
      throw e;
    }
  };

  export const getTicketCategoryType = async (
    uuid: string,
    passengers: number
  ): Promise<CategoryDataModel.ICategory> => {
    try {
      const result = await ehpAxios.get(
        `/ticket/category-type/${uuid}/${passengers}`
      );
      return result.data;
    } catch (e) {
      console.log(e);
      throw e;
    }
  };

  export const getTicketPassengersAllowed = async (): Promise<Number[]> => {
    try {
      const result = await ehpAxios.get('/costs-config/people-allowed');
      return result.data;
    } catch (e) {
      console.log(e);
      throw e;
    }
  };

  export const getTicketConfigByType = async (
    uuid: string
  ): Promise<Array<CategoryDataModel.TicketConfigurationResponse>> => {
    try {
      const territory =
        LocalStorageService.getLocalStorage().territory.territoryUuid;
      const result = await ehpAxios.get(
        `costs-config/price/${uuid}/${territory}`
      );
      return result.data;
    } catch (e) {
      console.log(e);
      throw e;
    }
  };
}
