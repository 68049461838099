import { collection, doc, getDoc, getFirestore } from '@firebase/firestore';
import axios from 'axios';
import { setDoc } from 'firebase/firestore';
import { EHFirebaseApp } from 'shared';
import { FirebaseStorageService } from 'shared/firebase/firebase-storage.service';
import { ProfileDataModel } from './profile.model';

export module ProfileService {
	export const getUserProfile = async (firebaseId: string): Promise<ProfileDataModel.IUserProfile> => {
		try {
			const firestore = getFirestore(EHFirebaseApp);
			const docRef = doc(firestore, 'Users', firebaseId);
			const docSnap = await getDoc(docRef);
			if (docSnap.exists()) {
				return docSnap.data();
			} else {
				throw Error('USER_DOESNT_EXIST');
			}
		} catch (e) {
			throw e;
		}
	};

	export const updateUserProfile = async (data: ProfileDataModel.IUserProfile) => {
		try {
			const firestore = getFirestore(EHFirebaseApp);
			const userRef = collection(firestore, 'Users');
			const result = await setDoc(doc(userRef, data.firebaseId), data);
			return result;
		} catch (e) {}
	};

	export const updateUserPicture = async (picture: string, newFile?: File) => {
		try {
			if (newFile) {
				const fileUploadResult = await FirebaseStorageService.uploadFile(
					newFile,
					FirebaseStorageService.FB_STORAGE_PATHS.PASSENGERS
				);
				return fileUploadResult;
			}
		} catch (e) {
			throw e;
		}
	};
	export const getPdfTicket = async (user: any, ticket: any) => {
		try {
			const body = {
				email: user.email,
				userName: user.fullName,
				ticketNumber: ticket.ticketNumber,
				peopleNumber: ticket.personNumber,
				validUntil: ticket.expirationDate,
				membershipType: ticket.categoryName,
				territoryName: ticket.territoryName,
			};
			const request = await axios.post(`${process.env.REACT_APP_EHP_URL}/health/pdf`, body, {
				responseType: 'blob',
			});
			return request;
		} catch (e) {}
	};
}
