import { setFlowAction, toogleLoading } from 'app.action';
import { AffiliationItemInfo } from 'components/global/AffiliationItemInfo/AffiliationItemInfo';
import { HopButton } from 'components/global/HopButton/HopButton';
import {
  ISelectionBoxData,
  SelectionBox,
} from 'components/global/SelectionBox/SelectionBox';
import { Whatsapp } from 'components/global/Whatsapp/Whatsapp';
import { setCommerceUuidAction } from 'modules/Membership/MembRegister.action';
import { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router';
import { useSearchParams } from 'react-router-dom';
import { CategoryDataModel } from 'shared/models';
import { CommerceDataModel } from 'shared/models/commerce.model';
import { CategoryService } from 'shared/services';
import { CommerceService } from 'shared/services/commerce.service';
import { LocalStorageService } from 'shared/services/localStorage.service';
import mixpanel from 'shared/services/mixpanel';
import { PaymentService } from 'shared/services/payment.service';
import { TerritoryService } from 'shared/services/territory.service';
import { RootState } from 'store';
import { setTerritoryAction } from '..';
import {
  setPassengersQtyAction,
  setTicketConfigAction,
} from '../affiliation.action';
import TitleBanner from '../TitleBanner/TitleBanner.page';
import AffilSelectionStyles from './AffilSelection.styles';
import {
  ChakraProvider,
  FormControl,
  FormLabel,
  Container,
  Stack,
  Select,
} from '@chakra-ui/react';
import { Value } from 'sass';

const AffilSelectionPage = (): JSX.Element => {
  const [selAffiliation, setSelAffiliation] = useState<string>('');
  const [pointInfo, setPointInfo] = useState<any>();
  const [additionalAmount, setAdditionalAmount] = useState<number>(0);
  const [selPeriod, setSelPeriod] = useState<any>('');
  const [categoryTypes, setCategoryTypes] = useState<
    Array<CategoryDataModel.ICategory>
  >([]);
  const [passengersAllowed, setPassengersAllowed] =
    useState<CategoryDataModel.ICategoryPassengersAllowed>([]);
  const [categoryViews, setCategoryViews] = useState<
    Array<CategoryDataModel.ICategory>
  >([]);
  const [passengerNumber, setPassengerNumber] = useState<number>(1);
  const [selectedOption, setSelectedOption] =
    useState<CategoryDataModel.TicketConfigurationResponse>();
  const [selectedCategory, setSelectedCategory] =
    useState<CategoryDataModel.ICategory>();
  const [commerce, setCommerce] = useState<CommerceDataModel.ICommerce>();
  const selectRef = useRef<any>(null);
  const flow = localStorage.getItem('flow');
  const [territory, setTerritory] = useState<any>({});

  const { t } = useTranslation();

  const navigate = useNavigate();
  const dispatch = useDispatch();

  const { isAuthenticated } = useSelector((state: RootState) => state.auth);

  const [searchParams] = useSearchParams();

  useEffect(() => {
    try {
      init();
    } catch (e) {
      console.log(e);
    }
  }, []);

  const init = async () => {
    dispatch(toogleLoading(true));
    const localData = await LocalStorageService.getLocalStorage();
    console.log('LOCAL DATA==>', localData);
    setTerritory(localData.territory);
    await getCommerceName(localData?.commerceUuid);
    await getPassengersAllowed().then((result) => {
      getCategories(result).then(() => {
        handleLocalStorageValues();
        handleAdditionals();
        dispatch(toogleLoading(false));
      });
    });
  };

  useEffect(() => {
    if (
      territory?.territoryUuid == 'a55d2374-f65e-4623-869a-658c62b14272' ||
      territory?.territoryUuid == '508cbe6f-865b-478f-8842-211eb34b4723'
    ) {
      filterByPassengers(2);
    }
  }, [territory, categoryTypes]);

  useEffect(() => {
    handleAdditionals();
  }, [selectedCategory, passengerNumber, selectedOption]);

  const handleAdditionals = () => {
    const localData = LocalStorageService.getLocalStorage();
    if (localData?.pointInfo?.additionals) {
      if (localData?.selectedOption?.typeKey === 'one') {
        setAdditionalAmount(localData?.pointInfo?.additionals?.one_way);
        LocalStorageService.updateLocalStorage({
          additionalAmount: localData?.pointInfo?.additionals?.one_way,
        });
      } else if (localData?.selectedOption?.typeKey === 'two') {
        setAdditionalAmount(localData?.pointInfo?.additionals?.round_trip);
        LocalStorageService.updateLocalStorage({
          additionalAmount: localData?.pointInfo?.additionals?.round_trip,
        });
      } else if (localData?.selectedOption?.typeKey === 'daily') {
        setAdditionalAmount(localData?.pointInfo?.additionals?.daily);
        LocalStorageService.updateLocalStorage({
          additionalAmount: localData?.pointInfo?.additionals?.daily,
        });
      } else {
        setAdditionalAmount(0);
        LocalStorageService.updateLocalStorage({
          additionalAmount: 0,
        });
      }
    } else {
      setAdditionalAmount(0);
      LocalStorageService.updateLocalStorage({
        additionalAmount: 0,
      });
    }
  };

  const handleLocalStorageValues = () => {
    const localData = LocalStorageService.getLocalStorage();
    localData?.ticketCategory && setSelectedCategory(localData?.ticketCategory);
    localData?.selectedOption && setSelectedOption(localData?.selectedOption);
    localData?.selPeriod && setSelPeriod(localData?.selPeriod);
    localData?.pointInfo && setPointInfo(localData?.pointInfo);
    localData?.passengerQty &&
      setPassengerNumber(parseInt(localData?.passengerQty));
  };

  const onSelectPeriod = (
    id: string,
    categoryView?: CategoryDataModel.ICategory
  ) => {
    // console.log("ID",id,"CATEGORY",categoryView)
    const option = categoryView?.configs?.find((config) => {
      return config.configUuid === id;
    });
    setSelectedCategory(categoryView);
    LocalStorageService.updateLocalStorage({
      ticketCategory: categoryView,
    });
    setSelectedOption(option);
    LocalStorageService.updateLocalStorage({
      selectedOption: option,
    });
    setSelPeriod(id);
    LocalStorageService.updateLocalStorage({
      selPeriod: id,
    });
    // console.log("OPTION",option)
  };

  const onSetStops = () => {
    dispatch(setTicketConfigAction(selectedOption!));
    goToTerritoryPage();
  };

  const onPayment = () => {
    dispatch(setTicketConfigAction(selectedOption!));
    goToTerritoryPage();
  };

  const onCancel = () => {
    navigate('/affiliation-territory');
  };

  const goToTerritoryPage = async () => {
    const localData = LocalStorageService.getLocalStorage();
    LocalStorageService.updateLocalStorage({
      ticketCategory: selectedCategory,
      selectedOption: selectedOption,
      selPeriod: selPeriod,
    });
    LocalStorageService.updateLocalStorage({
      passengerQty: String(selectRef.current?.value),
    });

    mixpanel.register({
      territory: localData?.territory?.territoryName,
      'membership-type': selectedOption?.typeValue,
      people: selectRef.current?.value,
    });

    mixpanel.track('Choose membership', {
      'new user': !isAuthenticated,
    });
    navigate('/ticket/buy/trip');

    // if (pointInfo) {
    //   mixpanel.track('Choose membership complete', {
    //     'new user': !isAuthenticated,
    //   });
    //   navigate('/registry');
    // } else {
    //   mixpanel.track('Choose membership initial', {
    //     'new user': !isAuthenticated,
    //   });
    //   navigate('/ticket/buy/trip');
    // }
  };

  const getCategories = async (passengersAllowed: Array<Number>) => {
    try {
      const localData = LocalStorageService.getLocalStorage();
      const result = await CategoryService.getTicketCategories();
      for (let i = 0; i < result.length; i++) {
        const ticketConfiguration =
          (await getCategoriesConfig(result[i].uuid || '')) || [];
        result[i].configurations = ticketConfiguration;
      }
      setCategoryTypes(result);

      const categoryViews = [...result];

      categoryViews.forEach((category) => {
        let categoryFilter: any = [];
        if (category.categoryKey !== 'personal') {
          categoryFilter = category.configurations?.filter((value) => {
            return (
              value.peopleNumber == +localData?.passengerQty ??
              passengersAllowed[1]
            );
          });
        } else {
          categoryFilter = category.configurations?.filter((value) => {
            return value.peopleNumber === 1;
          });
        }
        category.configs = categoryFilter;
      });

      let personCategory: any = categoryViews.find(
        (key) => key.categoryName == 'Personal'
      );
      //THIS CODE IS FOR THE SPECIAL TERRITORY THAT IS SAN JOSE AIRPORT
      console.log('TERRITORY INSIDE CATEGORIES==>', localData);
      if (
        localData.territory.territoryUuid ==
        'a55d2374-f65e-4623-869a-658c62b14272'
      ) {
        // console.log("SI ESTIY EN EL PRIMERO")
        personCategory = categoryViews.find(
          (key) => key.categoryName == 'Familiar'
        );
      }
      const selectedPeriodConfig = personCategory?.configs?.find(
        (config: any) => {
          return config.typeKey === 'daily';
        }
      );

      const selectedPeriod = selectedPeriodConfig
        ? selectedPeriodConfig!
        : personCategory?.configs[0];

      !localData?.selPeriod &&
        onSelectPeriod(selectedPeriod?.uuid!, personCategory);
      !localData?.ticketCategory && setSelectedCategory(personCategory!);
      setSelAffiliation(personCategory?.uuid!);
      setCategoryViews(categoryViews);
    } catch (e) {
      throw e;
    }
  };

  const getCategoriesSelectionType = (
    category: CategoryDataModel.ICategory
  ) => {
    const result: ISelectionBoxData[] =
      category.configs?.map((config) => {
        // console.log("DATA CONFIG",config)
        if (config.typeKey == 'daily') {
          return {
            id: config.configUuid || '',
            label: `${t(config.typeKey || '')} - $${config.finalPrice}` || '',
            extraLabel: `${t(config.typeKey + '' + config.typeKey)}`,
          };
        }
        return {
          id: config.configUuid || '',
          label: `${t(config.typeKey || '')} - $${config.finalPrice}` || '',
          extraLabel: `${config.usesByPerson} ${t(
            config.typeKey + '' + config.typeKey
          )}`,
        };
      }) || [];

    return result;
  };

  const getCategoriesConfig = async (uuid: string) => {
    try {
      const result = await CategoryService.getTicketConfigByType(uuid);
      return result;
    } catch (e) {
      throw e;
    }
  };

  const getPassengersAllowed = async () => {
    try {
      const result = [
        1,
        ...(await CategoryService.getTicketPassengersAllowed()),
      ];

      setPassengersAllowed(result);
      return result;
    } catch (e) {
      throw e;
    }
  };

  const filterByPassengers = async (passengerQty: number) => {
    try {
      let currentSelectedCategory;
      dispatch(setPassengersQtyAction(passengerQty));
      setPassengerNumber(passengerQty);
      LocalStorageService.updateLocalStorage({
        passengerQty: passengerQty,
      });
      const categoryViews = [...categoryTypes];
      if (passengerQty > 1) {
        const familyCategory = categoryViews.find(
          (key) => key.categoryName == 'Familiar'
        );
        setSelAffiliation(familyCategory?.uuid!);
        setSelectedCategory(familyCategory!);
        LocalStorageService.updateLocalStorage({
          ticketCategory: familyCategory!,
        });
        currentSelectedCategory = familyCategory;
      } else {
        const personCategory = categoryViews.find(
          (key) => key.categoryName == 'Personal'
        );
        setSelAffiliation(personCategory?.uuid!);
        setSelectedCategory(personCategory!);
        LocalStorageService.updateLocalStorage({
          ticketCategory: personCategory!,
        });
        currentSelectedCategory = personCategory;
      }

      categoryViews.forEach((category) => {
        let categoryFilter: any = [];
        if (category.categoryKey !== 'personal') {
          categoryFilter = category.configurations?.filter((value) => {
            return value.peopleNumber === passengerQty;
          });
        } else {
          categoryFilter = category.configurations?.filter((value) => {
            return value.peopleNumber === 1;
          });
        }
        category.configs = categoryFilter;
      });
      const selectedPeriodConfig = currentSelectedCategory?.configs?.find(
        (config) => {
          return config.typeKey === selectedOption?.typeKey;
        }
      );

      setCategoryViews(categoryViews);
      onSelectPeriod(
        selectedPeriodConfig?.uuid!,
        currentSelectedCategory || {}
      );
      setSelAffiliation(currentSelectedCategory?.uuid!);
    } catch (e) {
      throw e;
    }
  };

  const getCommerceName = async (uuid: string) => {
    try {
      const result = await CommerceService.getCommerceByUuid(uuid);
      // Disable Santa Teresa on QR
      if (result?.territoryName === 'Santa Teresa') {
        navigate(`/affiliation-territory`);
        return;
      }
      setCommerce(result);
      dispatch(setCommerceUuidAction(uuid));
      const territory = await TerritoryService.getTerritoryByUuid(
        result?.territoryUuid!
      );
      console.log('TERRITORY==>', territory);
      setTerritory(territory);
      LocalStorageService.updateLocalStorage({
        // territory: result,
        commerceUuid: uuid,
      });
      localStorage.setItem('territory', JSON.stringify(result?.territoryUuid));
      localStorage.setItem('commerceUuid', uuid);

      mixpanel.register({
        commerce: result?.name,
        territory: result?.territoryName,
        Language: 'English',
      });

      mixpanel.track('Init purchase', {
        Source: 'QR',
        'new-user': !isAuthenticated,
      });
      mixpanel.time_event('Ticket Successful');

      dispatch(setTerritoryAction(territory));
    } catch (e) {
      console.log(e);
    }
  };

  const familyCategoryTemp = categoryViews.find(
    (key) => key.categoryName == 'Familiar'
  );

  const personalCategoryTemp = categoryViews.find(
    (key) => key.categoryName == 'Personal'
  );

  const localStorageData = LocalStorageService.getLocalStorage();

  return (
    <AffilSelectionStyles>
      <TitleBanner
        territoryName={localStorageData?.territory?.territoryName}
        commerce={commerce?.name}
      />

      {territory?.territoryUuid == '00a99cbd-0cff-4b43-a99f-f1e94a8f4172' && (
        <a
          className='option-disabled-territory-warning'
          href='https://api.whatsapp.com/send?phone=50661309784'
          target='_blank'
        >
          Reservas antes de 24h, verificar disponibilidad de espacio vía
          WhatsApp
        </a>
      )}
      <div className='selection-affiliation-passsengers'>
        <label className='label-4'>{t('numPassengers')}</label>

        <select
          ref={selectRef}
          onChange={(e) => {
            filterByPassengers(Number(e.target.value));
          }}
          className='chakra-select select-passengers'
          value={passengerNumber}
        >
          {passengersAllowed &&
            passengersAllowed.map((item, index) => {
              //  console.log("THIS ARE THE VALUES",index)
              //  console.log("ITEM==>",item)
              if (
                (territory.territoryUuid ==
                  'a55d2374-f65e-4623-869a-658c62b14272' ||
                  territory.territoryUuid ==
                    '508cbe6f-865b-478f-8842-211eb34b4723') &&
                index == 0
              ) {
                // console.log("SI ESTIY EN EL PRIMERO")
                return;
              }
              return (
                <option key={index} value={String(item)}>
                  {String(item)}
                </option>
              );
            })}
        </select>
      </div>
      <h2 className='title-3 affil-selection-subtitle'>
        {t('membershipTime')}:
      </h2>

      <div className='affil-selection-form'>
        <div className='affil-selection-option-container'>
          {personalCategoryTemp && passengerNumber === 1 && (
            <SelectionBox
              headerLabel={t('choseMembership')}
              data={getCategoriesSelectionType(personalCategoryTemp)}
              selectedItem={
                personalCategoryTemp.uuid === selectedCategory?.uuid
                  ? selPeriod
                  : ''
              }
              selectionEvent={
                personalCategoryTemp.uuid === selectedCategory?.uuid
                  ? (id: string) => {
                      onSelectPeriod(id, personalCategoryTemp);
                    }
                  : () => {}
              }
            />
          )}
          {familyCategoryTemp && passengerNumber > 1 && (
            <SelectionBox
              headerLabel={t('choseMembership')}
              data={getCategoriesSelectionType(familyCategoryTemp)}
              selectedItem={
                familyCategoryTemp.uuid === selectedCategory?.uuid
                  ? selPeriod
                  : ''
              }
              selectionEvent={
                familyCategoryTemp.uuid === selectedCategory?.uuid
                  ? (id: string) => {
                      onSelectPeriod(id, familyCategoryTemp);
                    }
                  : () => {}
              }
            />
          )}
        </div>
      </div>

      {/* {selectedOption?.peopleNumber !== 1 && (
        <div className='item-alert-warning label-2'>
          {t('affiliationWarning')}
        </div>
      )} */}
      <AffiliationItemInfo
        members={passengerNumber}
        amount={selectedOption?.finalPrice}
        regularPrice={selectedOption?.price}
        typeName={selectedOption?.typeValue}
        // typeName={}
        pointInfo={pointInfo}
        additionalAmount={additionalAmount}
        passengerNumber={passengerNumber}
      />
      <div className='affil-selection-actions'>
        <HopButton
          disabled={!selPeriod}
          onClick={onSetStops}
          look='primary'
          content={!selPeriod ? t('membershipNoSelected') : t('set_stops')}
        />
        <HopButton onClick={onCancel} look='secondary' content={t('back')} />
      </div>

      {/* <Whatsapp /> */}
    </AffilSelectionStyles>
  );
};

export default AffilSelectionPage;
