import styled from 'styled-components';
import {breakpoints} from 'theme/breakpoints';
import colors from 'theme/colors';

const AffilItineraryStyles = styled.div`
  max-width: 390px;
  margin: 0 auto;
  background-color: ${colors.white};

  @media ${breakpoints.tablet} {
    max-width: 600px;
  }

  .affil-register-title {
    color: ${colors.blueDianne};
    margin-bottom: 10px;
    text-align: center;
    padding: 0 35px;
  }
  
  .membership-additional, .membership-regular {
    display: none;
  }
  
  .aff-info {
    .wrapper {
      gap: 5px;
    }
  }

  .affil-itinerary-actions {
    display: flex;
    justify-content: space-between;
    flex-direction: column;
    gap: 16px;
    padding: 0 35px;
    margin-bottom: 30px;
    margin-top: 40px;
    button {
      width: 100%;
      border-radius: 5px;
    }
  }
  
  .content {
    padding: 32px 20px;
  }
  .instructions {
    font-size: 18px;
    color: ${colors.grayJuniper};
  }
  .itinerary {
    margin: 0 0 0 20px;
    .title {
      display: none;
    }
  }
  .calendar {
    display: flex;
    justify-content: center;
    .calendar-c {
      width: 100vw;
      display: flex;
      flex-direction: column;
      align-items: center;
      .btn-action {
        margin: auto;
      }
      .calendar-wrapper {
        position: relative;
        width: 100%;
        max-width: 300px;
      }
    }
  }
`;

export default AffilItineraryStyles;
