import { createSlice } from '@reduxjs/toolkit';
import { MembRegistryDataModel } from 'modules/Membership/MembRegister.model';

export interface RegistrySliceState {
	member?: MembRegistryDataModel.IRegistryMemberState;
	password?: MembRegistryDataModel.IRegistryPasswordState;
	userId?: MembRegistryDataModel.IRegistryIdState;
}

export const RegistrySlice = createSlice({
	name: 'registry',
	initialState: {
		member: undefined,
		password: undefined,
		userId: undefined,
	} as RegistrySliceState,
	reducers: {
		setMember: (state: RegistrySliceState, action) => {
			state.member = action.payload;
		},
		cleanMember: (state: RegistrySliceState) => {
			state.member = undefined;
		},
		setPassword: (state: RegistrySliceState, action) => {
			state.password = action.payload;
		},
		cleanPassword: (state: RegistrySliceState) => {
			state.password = undefined;
		},
		setUserId: (state: RegistrySliceState, action) => {
			state.userId = action.payload;
		},
		cleanUserId: (state: RegistrySliceState) => {
			state.userId = undefined;
		},
	},
});

export const { setMember, setPassword, setUserId, cleanMember, cleanPassword, cleanUserId } = RegistrySlice.actions;
