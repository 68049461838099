import { color } from 'html2canvas/dist/types/css/types/color';
import styled from 'styled-components';
import { breakpoints } from 'theme/breakpoints';
import colors from 'theme/colors';

const AffilTripStyles = styled.div`
  max-width: 390px;
  margin: 0 auto;
  padding-top: 0px;
  background-color: ${colors.white};

  @media ${breakpoints.tablet} {
    max-width: 480px;
    padding: 12px 10px;
  }

  .affil-selection-title-1 {
    color: ${colors.grayJuniper};
    margin-bottom: 35px;
    text-align: center;
    padding: 0 35px;
    font-size: 12px;
  }

  .infoIcon{
    height: 100%;
    padding-right: 5px;
  }
  .calendarGroup{
    z-index: 1;
  }

  .affil-selection-title {
    color: ${colors.blueDianne};
    text-align: center;
    padding: 19px 35px 0;
    font-size: 24px;
  }

  .affil-selection-subtitle {
    color: ${colors.grayJuniper};
    margin-top: 18px;
    margin-bottom: 6px;
    padding: 0 26px;
    font-size: 18px;
    text-align: center;
  }

  .affil-stop-form {
    margin-bottom: 31px;
    padding: 1px 13px 0;
  }

  .affil-selection-option-container {
    margin-bottom: 31px;
    display: block;
  }

  .affil-selection-radio {
  }

  .affil-selection-passengers {
    flex-direction: row;
    align-items: center;
    padding-left: 10px;
    margin-bottom: 10px;

    label {
      font-family: Spartan Medium, serif;
      font-size: 16px;
    }

    input {
      height: 24px;
      width: 60px;
      margin-left: 10px;
      text-align: center;
      font-size: 16px;
    }
  }

  .affil-selection-info {
    background-color: ${colors.bluePolar};
    padding: 20px 35px;
    margin-bottom: 26px;
  }

  .affil-selection-membership {
    text-align: center;
    line-height: 1.3;
  }

  .affil-selection-price-regular {
    text-align: center;
    margin-bottom: 20px;

    span {
      font-family: Spartan Bold;
      text-decoration: line-through;
    }
  }

  .affil-selection-price-final {
    text-align: center;

    span {
      font-family: Spartan Bold;
      color: ${colors.blueTurquoise};
    }
  }

  .affil-stop-actions {
    display: flex;
    justify-content: space-between;
    flex-direction: column;
    gap: 16px;
    padding: 0 35px;
    margin-bottom: 30px;
    margin-top: 20px;

    button {
      width: 100%;
      border-radius: 5px;
      max-height: 60px !important;
      line-height: 28px !important;
      font-size: 1.2rem !important;
    }
  }

  .custom-select {
    background-color: ${colors.white};
    color: ${colors.blueDianne};
    border-radius: 5px;
    width: 70px;
    height: 30px;
    font-size: 20px;
    font-family: Spartan Medium, sans-serif;
    text-align: center;
    text-indent: 1px;
    text-overflow: '';
    border: 2px solid ${colors.black};
    -webkit-appearance: none;
    -moz-appearance: none;
  }

  .selection-combo-container {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 10px;
  }

  .item-alert-warning {
    display: flex;
    line-height: 25px;
    text-align: left;
    padding: 10px 20px;
    margin: 20px 15px;
    border-radius: 14px;
    background: #ffbb33;
    font-weight: 400;
    font-size: 18px;
    margin-top: 2rem;

    .warning-list {
      margin-top: 0.5rem;
    }

    
  }

  .selection-affiliation-passsengers {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 4px 40px 0;

    .label-4 {
      padding-left: 6px;
    }
  }

  //

  button:disabled {
    background-color: ${colors.grayTower};
    border-color: ${colors.grayTower};
  }

  .arrow-icon {
    display: inline;
    &.back {
      transform: rotate(180deg);
    }
  }
  .chakra {
    &-input,
    &-select {
      border: solid 2px ${colors.dullBull};
      color: ${colors.blueDianne};
      font-size: 18px;
      line-height: 24px;
    }
    &-form {
      &__label {
        font-family: Spartan Medium, sans-serif;
        font-size: 18px;
        color: ${colors.blueDianne};
        margin: 0;
      }
    }
  }
`;

export default AffilTripStyles;
