import axios from 'axios';
import { PaymentDataModel } from 'shared/models/payment.model';
import { COUNTRIES } from './constants';
import { LocalStorageService } from './localStorage.service';

export module PaymentService {
	export const sendToPayment = async (
		amount: number,
		description: string
	): Promise<PaymentDataModel.IPagueloFacilResultLink> => {
		try {
			const params = new URLSearchParams();
			params.append(
				'CCLW',
				'01B5F5749E149E5EE29A924142BFA423D6B68354FEACC41C7182B9765BF2C31D0668D0DE97DA6402A991CB8DF2052C4F3C49A35603461DB9700E64EE3FB99A06'
			);
			params.append('CMTN', String(amount));
			params.append('CDSC', description);
			params.append('RETURN_URL', `${window.location.origin}/affiliation-confirm`);
			const result = await axios.post('https://secure.paguelofacil.com/LinkDeamon.cfm', params);
			return result.data;
		} catch (e) {
			console.log(e);
			throw e;
		}
	};

	export const handlePaymentSecureLink = async (
		amount: number,
		description: string
	): Promise<PaymentDataModel.IPagueloFacilResultLinkData | undefined> => {
		try {
			// for local test !!!!!
			// if (process.env.REACT_APP_EHP_ENV === 'DEV') {
			// 	window.location.href =
			// 		window.location.origin +
			// 		`/affiliation-confirm?TotalPagado=1.0&Fecha=26/07/2022&Hora=22%3A50%3A33&Tipo=VISA&Oper=${new Date().getTime()}&Usuario=hernan+brenes&Email=eh@test.com&Estado=Aprobada&Razon=VER+UNAVAILABLE&CMTN=1.0&CDSC=testing&CCLW=01B5F5749E149E5EE29A924142BFA423D6B68354FEACC41C7182B9765BF2C31D0668D0DE97DA6402A991CB8DF2052C4F3C49A35603461DB9700E64EE3FB99A06&activityCode=0000054209&txAmount=1.0&PARM_1=19816201`;
			// 	return;
			// }

			const result = await sendToPayment(amount, description);
			if (result.success) {
				return result.data;
			}
		} catch (e) {
			console.log(e);
			throw e;
		}
	};

	export const generateToken = () => {
		var result = '';
		var characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
		var charactersLength = characters.length;
		for (var i = 0; i < 6; i++) {
			result += characters.charAt(Math.floor(Math.random() * charactersLength));
		}
		return result;
	};

	export const onPay = async () => {
		// const ticketConfig = store.getState().payment.ticketConfig;
		try {
			// if (process.env.REACT_APP_EHP_ENV === 'DEV') {
			// 	window.location.href =
			// 		window.location.origin +
			// 		`/affiliation-confirm?ticketCode=${ticketCode}&code=3D3&description=518-3DS1%20verify%20enrollment%20error:%20NS-524%20&auth=&order=WDZ6GQ&tpt=301810&crd=&padded=&authorization=&brand=MasterCard&last-digits=&gateway-transaction=&tilopay-transaction=301810&OrderHash=80af33a8f837a799671bb8925f3e8a7cc760206b339825c73487668b6426a1a4${new Date().getTime()}`;
			// 	return;
			// }
			const dataToken = await tiloPayLogin();
			localStorage.setItem('bearedToken', JSON.stringify(dataToken));
			const token = dataToken?.access_token;

			if (token) {
				const result = await tiloPayProcessPayment(`${token}`, generateToken());
				localStorage.setItem('code', JSON.stringify(result));
				window.location.href = `${result?.url}`;
			}

			// localStorage.setItem('code', result?.code!);
		} catch (e) {
			console.log(e);
		}
	};

	export const tiloPayLogin = async (): Promise<PaymentDataModel.LoginResulInterface | undefined> => {
		try {
			const data = {
				apiuser: process.env.REACT_APP_TILOPAY_API_USER,
				password: process.env.REACT_APP_TILOPAY_API_PASS,
			} as PaymentDataModel.LoginInterface;

			const result = await axios.post('https://app.tilopay.com/api/v1/login', data);
			return result.data;
		} catch (e) {
			console.log(e);
			throw e;
		}
	};

	export const tiloPayProcessPayment = async (
		token: string,
		ticketCode: string
	): Promise<PaymentDataModel.ProcessPaymentResultInterface | undefined> => {
		try {
			const localData = LocalStorageService.getLocalStorage();
			// console.log("localDatA tilo pay ==>",localData)
			const additional = +localData?.additionalAmount * +localData.passengerQty;

			// console.log("Additional==> tilo pay ==>",additional)
			
			if(localData?.ticketsPrice==undefined){
				localData.ticketsPrice=0
			}
			else{
				localData.ticketsPrice=+Number(localData?.ticketsPrice)* +localData.passengerQty
			}
			// console.log("TICKETS PRICE==> tilo pay ==>",localData?.ticketsPrice)
			const totalAmount = +Number(localData?.selectedOption?.finalPrice )+Number( additional) +Number(localData?.ticketsPrice);
			// console.log("THIS IS TOTAL AMOUNT==>",totalAmount)

			const country: any = COUNTRIES?.find((country) => {
				return country.country === localData?.user?.billToCountry;
			});

			// for local dev: amount should be "0.00" to not charge the credit card
			const data = {
				redirect: `${window.location.origin}/registry`,
				key: process.env.REACT_APP_TILOPAY_API_KEY,
				amount: `${totalAmount}`,
				//local testing
				// amount: "0.00",
				currency: 'USD',
				billToFirstName: `${localData?.user?.fullName}`,
				billToLastName: `${localData?.user?.fullName}`,
				billToAddress: `${localData?.user?.billToAddress}`,
				billToAddress2: `${localData?.user?.billToAddress}`,
				billToCity: 'SJ',
				billToState: 'SJ',
				billToZipPostCode: '10061',
				billToCountry: country?.alpha2,
				billToTelephone: `${localData?.user?.phoneNumber}`,
				billToEmail: `${localData?.user?.userEmail}`,
				orderNumber: `${ticketCode}`,
				capture: '1',
				subscription: '0',
				platform: 'Esto es una prueba desde postman',
			} as PaymentDataModel.ProcessPaymentInterface;

			const config = {
				headers: { Authorization: `Bearer ${token}` },
			};
			console.log("DATA TILO PAY",data)

			const result = await axios.post('https://app.tilopay.com/api/v1/processPayment', data, config);

			return result.data;
		} catch (e) {
			console.log(e);
			throw e;
		}
	};
}
