import ehpAxios from 'shared/axios/axios.service';

export module CommerceService {
	export const getCommerceByUuid = async (uuid: string) => {
		try {
			const result = await ehpAxios.get(`/commerce/${uuid}`);
			return result.data;
		} catch (e) {
			throw e;
		}
	};
	export const getCommerceByShorcut_url = async (code_url: string) => {
		try {
			const result = await ehpAxios.get(`/commerce/urlCode/${code_url}`);
			return result.data;
		} catch (e) {
			throw e;
		}
	};
}
