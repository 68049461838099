import styled from 'styled-components';
import colors from "../../../theme/colors";
import verticalLines from 'asset/vertical-lines.svg';

const ItineraryStyles = styled.div`
  margin-top: 41px;
  .title {
    font-family: Spartan Regular, sans-serif;
    font-size: 20px;
    color: ${colors.grayJuniper};
    line-height: 25px;
    &.main {
      text-transform: uppercase;
    }
    &.secondary {
      text-transform: lowercase;
    }
  }
  .itinerary {
    margin-top: 23px;
    display: flex;
    flex-direction: column;
    gap: 23px;
    background-image: url("data:image/svg+xml,%3Csvg width='2' height='23' viewBox='0 0 2 23' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cline x1='1' y1='1' x2='0.999999' y2='22' stroke='%2339677A' stroke-width='2' stroke-linecap='round' stroke-dasharray='4 4'/%3E%3C/svg%3E");
    background-repeat-x: no-repeat;
    background-position-x: 9.5%;
    background-position-y: 6px;
    &--item {
      display: flex;
      gap: 20px;
      background-color: ${colors.white};
      padding-top: 10px;
      &__detail {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        &--from, &--to {
          color: ${colors.blueDianne};
        }
        &--from {
          font-family: Spartan Regular, sans-serif;
          font-size: 20px;
        }
        &--to {
          font-family: Spartan Medium, sans-serif;
          font-size: 18px;
          text-decoration: underline;
        }
      }
      &__time {
        font-family: Spartan Bold, sans-serif;
        color: ${colors.blueDianne};
        font-size: 25px;
      }
      &__delete {
        background: none;
        border: none;
        align-self: flex-end;
        cursor: pointer;
      }
    }
    
    &--add {
      display: flex;
      gap: 20px;
      background-color: ${colors.white};
      padding-top: 10px;
      &__icon {
        margin-left: 16px;
      }
      &__btn {
        background-color: ${colors.darkBlue};
        color: ${colors.white};
        font-family: Spartan Medium, sans-serif;
        border: none;
        border-radius: 30px;
        padding: 6px 12px;
        cursor: pointer;
      }
    }
  }
`;

export default ItineraryStyles;
