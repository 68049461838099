import styled from 'styled-components';
import colors from 'theme/colors';

const TitleBannerStyles = styled.div`
  background-color: ${colors.blueDianne};
  padding: 0.5rem;
  margin-bottom: 35px;

  .banner-affil-selection-title-1 {
    color: ${colors.white};
    text-align: center;
    padding: 0 35px;
    font-size: 14px;
  }

  .banner-affil-selection-title {
    color: ${colors.white};
    text-align: center;
    padding: 0 35px 0;

    span {
      text-transform: uppercase;
    }
  }
`;

export default TitleBannerStyles;
