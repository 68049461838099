import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RoutesDataModel } from 'modules/Routes';
import { ReservationDataModel } from 'shared/models/reservation.model';
import { TerritoryDataModel } from 'shared/models/territory.model';

export interface ReservationSliceState {
	currentPosition: any;
	tripPosition: any;
	tripData: any;
	trip?: ReservationDataModel.Trip;
	center: any;
	routes?: Array<TerritoryDataModel.IRoute>;
	points?: Array<TerritoryDataModel.IPoint>;
	selectedReservation?: RoutesDataModel.SelectedBooking;
}

export const ReservationSlice = createSlice({
	name: 'app',
	initialState: {
		currentPosition: undefined,
		tripPosition: undefined,
		tripData: undefined,
		trip: undefined,
		center: undefined,
		routes: undefined,
		points: undefined,
	} as ReservationSliceState,
	reducers: {
		setCurrentPosition: (state: ReservationSliceState, action: PayloadAction<any>) => {
			state.currentPosition = action.payload;
		},
		setTripPosition: (state: ReservationSliceState, action: PayloadAction<any>) => {
			state.tripPosition = action.payload;
		},
		setTripData: (state: ReservationSliceState, action: PayloadAction<any>) => {
			state.tripData = action.payload;
		},
		setTrip: (state: ReservationSliceState, action: PayloadAction<ReservationDataModel.Trip>) => {
			state.trip = action.payload;
		},
		setCenter: (state: ReservationSliceState, action: PayloadAction<any>) => {
			state.center = action.payload;
		},
		setPoints: (state: ReservationSliceState, action: PayloadAction<Array<TerritoryDataModel.IPoint>>) => {
			state.points = action.payload;
		},
		setRoutes: (state: ReservationSliceState, action: PayloadAction<Array<TerritoryDataModel.IRoute>>) => {
			state.routes = action.payload;
		},
		setSelectedReservation: (state: ReservationSliceState, action: PayloadAction<RoutesDataModel.SelectedBooking>) => {
			state.selectedReservation = action.payload;
		},
	},
});

export const {
	setCurrentPosition,
	setTripPosition,
	setTripData,
	setTrip,
	setCenter,
	setPoints,
	setRoutes,
	setSelectedReservation,
} = ReservationSlice.actions;
