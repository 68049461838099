import { toogleLoading } from 'app.action';
import userAsset from 'asset/user.png';
import { HopButton } from 'components/global/HopButton/HopButton';
import { HopRadioButton } from 'components/global/HopRadioOption/HopRadioButton';
import { InputGroup } from 'components/global/InputGroup/InputGroup';
import { ProfileService, setUserProfileAction } from 'modules/Profile';
import { useRef, useState } from 'react';
import { useForm } from 'react-hook-form';
import toast from 'react-hot-toast';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { RootState } from 'store';
import { registerId, resetId } from '../MembRegister.action';
import { MembRegistryDataModel } from '../MembRegister.model';
import MembFinishStyles from './MembFinish.styles';

interface IFormRegistry extends MembRegistryDataModel.IRegistryIdState {}

const MembershipFinishPage = (): JSX.Element => {
	const [terms, setTerms] = useState(false);
	const [termsError, setTermsError] = useState('');
	const [picture, setPicture] = useState(null);
	const [file, setFile] = useState(null);
	const dispatch = useDispatch();
	const navigate = useNavigate();
	const hiddenFileInput = useRef(null);
	const { register, handleSubmit, reset, formState } = useForm<IFormRegistry>();
	const { member, password } = useSelector((state: RootState) => state.registry);
	const flow = localStorage.getItem('flow');
	const auth = useSelector((state: RootState) => state.auth);
	const { t } = useTranslation();

	const onSubmit = async (formValues: IFormRegistry) => {
		try {
			dispatch(toogleLoading(true));
			if (!terms) {
				setTermsError('Debe aceptar los terminos y condiciones');
				return;
			}
			formValues.isTC = true;
			if (file) {
				formValues.file = file;
			}
			const userId = await registerId(formValues);
			dispatch(userId);

			const user = { ...auth.user };
			user.userId = formValues.id;
			await ProfileService.updateUserProfile(user);
			dispatch(setUserProfileAction(user));

			goToConfirmation();
		} catch (e: any) {
			console.log(e);
			validateCodeErrors(e.code);
		} finally {
			dispatch(toogleLoading(false));
		}
	};

	const validateCodeErrors = (code: string) => {
		switch (code) {
			case 'auth/email-already-in-use': {
				toast.error('Ya existe una cuenta con el correo usado');
				break;
			}
			default: {
				toast.error('Error del sistema, intente en unos minutos');
				break;
			}
		}
	};

	const handleClick = (event: any) => {
		try {
			if (hiddenFileInput && hiddenFileInput.current) {
				const current: any = hiddenFileInput.current;
				current.click();
			}
		} catch (e) {
			console.log(e);
		}
	};

	const handleChange = (event: any) => {
		const file = event.target.files[0];
		const reader = new FileReader();
		reader.onload = (event: ProgressEvent<FileReader>) => {
			file.url = event?.target?.result;
			setFile(file);
			setPicture(file.url);
		};
		reader.readAsDataURL(file);
		setPicture(file.url);
	};

	const goToConfirmation = () => {
		navigate('/registry-confirm');
	};

	const onCancel = () => {
		reset();
		dispatch(resetId());
		navigate(-1);
	};

	return (
    <MembFinishStyles>
      <div className="memb-finish-container">
        <h1 className="title-2 memb-finish-title">{t("membershipRegistry")}</h1>

        <h2 className="title-3 memb-finish-subtitle">
          {t("contactInformation")}
        </h2>

        <label className="label-4">{t("fullName")}:</label>
        <br />
        <br />
        <label className="title-3 memb-finish-subtitle">
          {auth.user?.fullName}
        </label>
        <br />
        <br />
        <label className="label-4">{t("email")}:</label>
        <br />
        <br />
        <label className="title-3 memb-finish-subtitle">
          {auth.user?.userEmail}
        </label>
        <br />
        <br />
        <br />

        <h2 className="title-3 memb-finish-subtitle">
          {t("confirmYourIdentity")}
        </h2>
        <form onSubmit={handleSubmit(onSubmit)}>
          <div className="memb-finish-form">
            <InputGroup
              label={t("idNumber")}
              inputType="text"
              placeholder={t("idNumberPlaceholder")}
              registerProps={register("id", {
                required: "La identificación no puede estar vacia",
              })}
              errorMsg={formState.errors?.id?.message}
            />
          </div>
          <div className="memb-finish-photo-container">
            {!picture && <img alt="User thumb" src={userAsset} />}
            {picture && (
              <img
                className="memb-finish-photo"
                alt="User thumb"
                src={picture}
              />
            )}
            <p className="label-4">{t("idNumberDescription")}</p>
          </div>

          <input
            onChange={handleChange}
            ref={hiddenFileInput}
            type="file"
            style={{ visibility: "hidden" }}
          />

          <HopButton
            className="memb-finish-photo-action memb-button"
            look="primary"
            content={t("uploadPicture")}
            onClick={(e) => {
              handleClick(e);
            }}
          />
          <div className="memb-terms-condition-container">
            <HopRadioButton
              value={!terms}
              active={terms}
              className="label-4 memb-finish-terms"
              isLink={true}
              link={
                <p>
                  {t("termsConditionsStart")}{" "}
                  <a
                    target="_blank"
                    href={"https://www.easyhop.co/terms"}
                    rel="noreferrer"
                    className={"label-4 memb-finish-terms"}
                  >
                    {t("termsConditionsLink")}
                  </a>{" "}
                  {t("termsConditionsEnd")}
                </p>
              }
              clickEvent={(value) => {
                setTerms(value as boolean);
                if (value) {
                  setTermsError("");
                }
              }}
            />
            {termsError && <p className="label-5 form-errors">{termsError}</p>}
          </div>
          <div className="memb-finish-actions">
            <HopButton
              className="memb-button"
              look="primary"
              content={t("activateMembership")}
              type="submit"
            />
            <HopButton
              className="memb-button"
              look="secondary"
              content={t("back")}
              type="button"
              onClick={onCancel}
            />
          </div>
        </form>
      </div>
    </MembFinishStyles>
  );
};

export default MembershipFinishPage;
