import { HopButton } from 'components/global/HopButton/HopButton';
import { HopRadioButton } from 'components/global/HopRadioOption/HopRadioButton';
import { SelectionBox } from 'components/global/SelectionBox/SelectionBox';
import { FC, ReactElement, useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import toast from 'react-hot-toast';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { RootState } from 'store';
import { setSelectedReservationAction } from '../routes.action';
import { RoutesDataModel } from '../routes.model';
import { RoutesService } from '../routes.service';
import RoutesReservationStyles from './RoutesReservation.styles';

interface IRoutesMap {}

const RoutesReservationPage: FC = (): ReactElement => {
	const { register, handleSubmit, formState } = useForm<IRoutesMap>();
	const dispatch = useDispatch();
	const navigate = useNavigate();
	const { t } = useTranslation();

	const { user } = useSelector((state: RootState) => state.auth);
	const { ticket } = useSelector((state: RootState) => state.ticket);
	const { routes, trip, points } = useSelector((state: RootState) => state.reservation);
	const [pointSelected, setPointSelected] = useState<any>();
	const [addressSelected, setAddressSelected] = useState<string>('ida');
	const [customPoints, setCustomPoints] = useState<any>();
	const [passengerNumber, setPassengerNumber] = useState<number>(1);

	const [ticketPasssengersQty, setTicketPasssengersQty] = useState(
		Array.from({ length: ticket?.personNumber || 0 }, (v, k) => k + 1)
	);

	useEffect(() => {
		createPoints();
		setAddressSelected('ida');
		setPointSelected(points ? points[0].uuid : '');
	}, []);

	const createPoints = () => {
		const tmpPoints: Array<any> = [];
		points?.forEach((point) => {
			const item = {
				id: point.uuid,
				label: point.name,
			};
			tmpPoints.push(item);
		});
		setCustomPoints({
			id: '',
			label: '',
			periods: tmpPoints,
		});
	};

	const locationData = [
		{
			label: `${t('going')}`,
			value: 'ida',
		},
		{
			label: `${t('returning')}`,
			value: 'regreso',
		},
	];

	const goToReservationConfirmation = () => {
		navigate('/routes-confirmation');
	};

	const goToMapRoute = () => {
		navigate('/routes-map');
	};

	const onSelectPoint = (id: string) => {
		setPointSelected(id);
	};

	const onSubmitBooking = async () => {
		try {
			const request: RoutesDataModel.BookReservationRequest = {
				tripId: trip?.tripId,
				userId: user?.uuid,
				passengersQty: ticket?.personNumber === 1 ? String(1) : String(0),
				direction: addressSelected,
			};
			const result = await RoutesService.addNewReservation(request);
			if (result) {
				if (result.code) {
					toast.error(t(result.code));
				} else {
					dispatch(
						setSelectedReservationAction({
							passengersQty: ticket?.personNumber === 1 ? String(1) : String(0),
							point:
								points?.find((point) => {
									return pointSelected === point.uuid;
								})?.name || '',
							direction: addressSelected,
							ticket: ticket?.ticketNumber,
							time: String(routes && routes.length ? routes[0].duration : 0),
						})
					);
					goToReservationConfirmation();
				}
			}
		} catch (e) {
			toast.error(t('error'));
		}
	};

	return (
		<RoutesReservationStyles>
			<h1 className="title-2">{t('routes')}</h1>
			<div className="title-3 routes-reservation-subtitle">{t('saveSpace')}</div>
			<div className="routes-reservation-members">
				<label className="label-4">{t('passQty')}: </label>
				<select
					onChange={(e) => {
						setPassengerNumber(Number(e.target.value));
					}}
					className="custom-select"
					disabled={ticket?.personNumber === 1}>
					{ticketPasssengersQty &&
						ticketPasssengersQty.map((num) => {
							return <option value={num}>{num}</option>;
						})}
				</select>
			</div>
			<div className="routes-reservation-point">
				<label className="label-4">{t('point')} </label>
				<br />
				{customPoints && (
					<SelectionBox
						customClass="routes-reservation-selection"
						headerLabel={t('selectPoint')}
						data={customPoints.periods}
						selectedItem={pointSelected}
						selectionEvent={onSelectPoint}
					/>
				)}
			</div>
			<div>
				<label className="label-4">{t('address')}: </label>
				{locationData &&
					locationData.map((location, index) => {
						return (
							<div key={index}>
								<HopRadioButton
									value={location.value}
									label={location.label}
									active={addressSelected === location.value}
									className="label-5"
									clickEvent={(locationValue) => setAddressSelected(locationValue as string)}
								/>
							</div>
						);
					})}
			</div>

			<label className="label-4">
				{t('availableSpaces')}: {trip?.availableSeats}
			</label>

			<label className="label-4">
				{t('estimatedTime')}: <span className="label-1 ">{routes && routes.length ? routes[0].duration : 0}m</span>
			</label>

			<div className="routes-reservation-actions">
				<HopButton
					onClick={onSubmitBooking}
					className="affil-confirm-ticket-action"
					look="primary"
					content={t('saveSpace')}
				/>
				<HopButton
					onClick={goToMapRoute}
					className="affil-confirm-ticket-action"
					look="secondary"
					content={t('back')}
				/>
			</div>
		</RoutesReservationStyles>
	);
};

export default RoutesReservationPage;
