import React, {useState} from 'react';
import {format} from 'date-fns';
import {DayPicker} from 'react-day-picker';
import 'react-day-picker/dist/style.css';
import CalendarSelectorStyles from "./CalendarSelector.styles";
import CalendarIcon from 'asset/calendar.svg';

interface ICalendar {
    required: boolean;
    disabled: boolean;
    onSetDate: any;
    formatDate?: string;
}

const CalendarSelector = ({required, disabled, formatDate, onSetDate}: ICalendar) => {
    const today = new Date()
    if (formatDate == undefined) {
        formatDate = 'd/M/Y'
    }

    const [ShowCalendar, setShowCalendar] = useState(false);
    const [selectedDay, setSelectedDay] = useState<Date | undefined>(new Date());
    // toggle calendar
    const toggleCalendar = () => {
        setShowCalendar(!ShowCalendar)
    }

    const dateToText = (selectedDay && format(selectedDay, 'dMy') != format(today, 'dMy')) ? (
        <span>{format(selectedDay, formatDate)}</span>
    ) : (
        <span>Today</span>
    )

    return (
        <CalendarSelectorStyles>
			<div className="calendar-c">
				<button onClick={toggleCalendar} className="btn-action">{dateToText} <img src={CalendarIcon}
																						  className="calendar-icon"/>
				</button>
				<div className="calendar-wrapper">
					{ShowCalendar &&

						<DayPicker
							mode="single"
							fromDate={today}
							required
							selected={selectedDay}
							onSelect={(data) => {
								setSelectedDay(data);
								toggleCalendar()
								onSetDate(data)
							}
							}
						/>

					}
				</div>
			</div>

        </CalendarSelectorStyles>
    );
}
export default CalendarSelector;
