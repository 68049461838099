import ehpAxios from 'shared/axios/axios.service';
import { PointsAdditionalsDataModel } from 'shared/models/pointsAdditionals.model';

export module PointAdditionalsService {
  export const findAdditionalsByPoints = async (
    pickUpPoint: string,
    returnPoint: string,
    routeUUID:string
  ): Promise<PointsAdditionalsDataModel.IPointsAdditional> => {
    try {
      const result = await ehpAxios.get(
        `/point-additionals/findAdditionalsByPoints?pickUpPoint=${pickUpPoint}&returnPoint=${returnPoint}&routeUUID=${routeUUID}`
      );
      return result.data;
    } catch (e) {
      console.log(e);
      throw e;
    }
  };
}
