import { deleteObject, getDownloadURL, getStorage, ref, uploadBytesResumable, UploadTask } from 'firebase/storage';
import { EHFirebaseApp } from 'shared';

export module FirebaseStorageService {
	export enum FB_STORAGE_PATHS {
		PASSENGERS = 'passengers_photos',
	}

	export const deleteFile = async (fileName: string) => {
		try {
			const storage = getStorage(EHFirebaseApp);
			const storageRef = ref(storage, fileName);
			const result = await deleteObject(storageRef);
			return result;
		} catch (e) {
			throw e;
		}
	};

	export const uploadFile = async (file: File, path: string) => {
		try {
			const storage = getStorage(EHFirebaseApp);
			const storageRef = ref(storage, `${path}/${file.name}`);
			const uploadTask = uploadBytesResumable(storageRef, file);
			const result = await handleFileUploadTask(uploadTask);
			return result;
		} catch (e) {
			throw e;
		}
	};

	const handleFileUploadTask = async (task: UploadTask): Promise<string> => {
		return new Promise((resolve, reject) => {
			task.on(
				'state_changed',
				(snapshot) => {
					const progress = Math.round((snapshot.bytesTransferred / snapshot.totalBytes) * 100);
				},
				(error) => {
					reject(error);
				},
				() => {
					getDownloadURL(task.snapshot.ref).then((downloadURL) => {
						resolve(downloadURL);
					});
				}
			);
		});
	};
}
