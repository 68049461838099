import { useTranslation } from 'react-i18next';
import TitleBannerStyles from './TitleBanner.styles';

const TitleBanner = (props: any): JSX.Element => {
  const { t } = useTranslation();

  const { territoryName, commerce } = props;

  return (
    <TitleBannerStyles className='title-banner'>
      <h1 className='title-2 banner-affil-selection-title'>
        <span>{territoryName}</span>
        {t('ehAffiliation')}
      </h1>

      {commerce != null && (
        <h1 className='title-2 banner-affil-selection-title-1'>{commerce}</h1>
      )}
    </TitleBannerStyles>
  );
};

export default TitleBanner;
