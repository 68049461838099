import styled from 'styled-components';
import { breakpoints } from 'theme/breakpoints';
import colors from 'theme/colors';
import { buttonsStyles } from 'theme/typography';

const MembRegisterStyles = styled.div`
	max-width: 390px;
	margin: 0 auto;
	padding-top: 20px;
	background-color: ${colors.white};

	@media ${breakpoints.phone} {
		zoom: unset;
	}

	@media ${breakpoints.tablet} {
		padding-top: 60px;
		max-width: 640px;
		zoom: 80%;
	}

	.memb-register-form-container {
		margin-top: 30px;
	}
	.form-errors{
		font-size: 12px;
    font-weight: 400;
    line-height: initial;
    margin-top: 10px;
    text-align: left;
    color: #FF5736;

	}

	.memb-register-subtitle {
		color: ${colors.blueDianne};
		margin-top: 35px;
		text-align: center;
		align-self: flex-start;
		font-weight: 700;
		font-size: 18px;
		line-height: 35px;
	}

	.memb-finish-terms {
		margin-top: 40px;
	}

	.memb-register-title {
		color: ${colors.blueDianne};
		margin-bottom: 35px;
		text-align: center;
		padding: 0 35px;
	}

	.memb-register-info {
		text-align: center;
	}

	.memb-register-ticket-label {
		color: ${colors.grayJuniper};
		margin-bottom: 15px;
	}

	.memb-register-ticket-value {
		font-family: Spartan Bold;
		margin-bottom: 35px;
	}

	.memb-register-form {
		margin-bottom: 10px;
		padding: 0 35px;
		display: flex;
		flex-direction: column;
		align-items: center;
	}

	.memb-register-nationality {
		display: flex;
		align-items: flex-start;
    width: 100%;
    margin-bottom: 25px;

		button {
			margin-right: 30px;

			&:last-of-type {
				margin-right: 0;
			}
		}
	}

	.memb-register-social {
		display: flex;
		flex-direction: column;
		align-items: center;
		width: 100%;
		margin-bottom: 20px;
	}

	.memb-register-actions {
		display: flex;
		justify-content: space-between;
		padding: 0 35px;

		button {
			cursor: pointer;
			width: 150px;
			@media ${breakpoints.smallPC} {
				width: 421px;
				padding: 12px 10px;
			}
		}

		@media ${breakpoints.smallPC} {
			justify-content: center;
			padding: 0px;
			flex-direction: column;
			align-items: center;
		}
	}

	.item-alert-warning {
		line-height: 25px;
		text-align: left;
		padding: 10px 20px;
		margin: 20px 15px;
		border-radius: 14px;
		background: #ffbb33;
		font-weight: 400;
		font-size: 18px;

		strong {
			font-weight: bold;
			font-size: 20px;
		}
	  }
	  
	.item-alert-warning-payment {
		line-height: 25px;
		display: flex;
		text-align: left;
		padding: 10px 20px;
		margin: 20px 15px;
		border-radius: 14px;
		background: #ffbb33;
		font-weight: 400;
		font-size: 18px;

		img {
			height: 17px;
			width: 17px;
			margin-right: 5px;
		}

		.item-alert-warning-payment-title {
			font-weight: 700;
			font-size: 18px;
			line-height: 17px;
			margin-bottom: 10px;
		}

		p {
			font-weight: 400;
			font-size: 17px;
			line-height: 17px;
			margin: 5px 0;
		}
	  }

	  .paypal-section {
		margin-top: 30px;
		width: 80%;
	  }

	.memb-button {
		margin: ${buttonsStyles.verticalMarginSpacing}
		margin-bottom: 30px;
		width: 210px;
		cursor: pointer;
		font-size:1.2rem;
		line-height:28px;
		max-height: 60px !important;

		@media ${breakpoints.phone} {
			width: 250px;
		}

		&:last-of-type {
			margin-bottom: 0;
		}

		@media ${breakpoints.tablet} {
			width: 421px;
			padding: 12px 10px;
		}
	}

	.disabled-button {
		opacity: 0.5;
	}
`;

export default MembRegisterStyles;
