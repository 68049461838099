import { HopButton } from 'components/global/HopButton/HopButton';
import { InputGroup } from 'components/global/InputGroup/InputGroup';
import { registerMember } from 'modules/Membership/MembRegister.action';
import { FC, ReactElement } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { RootState } from 'store';
import { ProfileDataModel } from '../profile.model';
import ProfileUpdateStyles from './ProfileUpdate.styles';

interface IFormUser extends ProfileDataModel.IUserProfile {}

const ProfileUpdatePage: FC = (): ReactElement => {
	const dispatch = useDispatch();
	const navigate = useNavigate();
	const { register, handleSubmit, formState } = useForm<IFormUser>();
	const { user } = useSelector((state: RootState) => state.auth);
	const { t } = useTranslation();

	const onSubmit = async (formValues: IFormUser) => {
		try {
			dispatch(registerMember(formValues));
			let newUser = {
				...user,
				DNI: formValues.DNI,
				phoneNumber: formValues.phoneNumber,
				fullName: formValues.fullName,
				email: formValues.email,
			};
			let resultUrl = undefined;
		} catch (e) {
			console.log(e);
		}
	};

	const goToChangePassword = () => {
		navigate('/profile-password');
	};

	return (
		<ProfileUpdateStyles>
			<h1 className="title-2 profile-update-title">{t('myProfile')}</h1>

			<form onSubmit={handleSubmit(onSubmit)}>
				<div className="profile-update-form">
					<InputGroup
						label={t('fullName')}
						inputType="text"
						placeholder={t('fullNamePlaceholder')}
						registerProps={register('fullName', {
							value: user?.fullName || '',
							required: 'Nombre no puede estar vacio',
						})}
						errorMsg={formState.errors?.fullName?.message}
					/>

					<InputGroup
						label={t('idNumber')}
						inputType="number"
						placeholder={t('idNumberPlaceholder')}
						registerProps={register('DNI', {
							value: user?.userId || '',
							required: 'La identificación no puede estar vacio',
						})}
						errorMsg={formState.errors?.DNI?.message}
					/>

					<InputGroup
						label={t('email')}
						inputType="email"
						placeholder={t('emailPlaceholder')}
						registerProps={register('email', {
							value: user?.userEmail || '',
							required: 'Correo no puede estar vacio',
							pattern: {
								value:
									/^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
								message: 'El correo no tiene formato correcto',
							},
						})}
						errorMsg={formState.errors?.email?.message}
					/>

					<InputGroup
						label={t('phoneNumber')}
						inputType="number"
						placeholder={t('phoneNumberPlaceholder')}
						registerProps={register('phoneNumber', {
							value: user?.phoneNumber || '',
							required: 'Telefono no puede estar vacio',
							minLength: {
								value: 8,
								message: 'Telefono deben ser 8 numeros seguidos',
							},
							maxLength: {
								value: 8,
								message: 'Telefono deben ser 8 numeros seguidos',
							},
						})}
						errorMsg={formState.errors?.phoneNumber?.message}
					/>
				</div>
				<div className="profile-update-social">
					<HopButton look="primary" className="memb-button" content={t('save')} type="submit" />
				</div>
			</form>
		</ProfileUpdateStyles>
	);
};

export default ProfileUpdatePage;
