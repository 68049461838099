import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { LoginDataModel } from 'modules/login/login.model';
import { MembRegistryDataModel } from 'modules/Membership/MembRegister.model';

export interface AuthSliceState extends LoginDataModel.IAuth {}

export const AuthSlice = createSlice({
	name: 'auth',
	initialState: {
		isAuthenticated: false,
		token: undefined,
		user: undefined,
		authType: undefined,
	} as AuthSliceState,
	reducers: {
		setAuth: (state: AuthSliceState, action: PayloadAction<AuthSliceState>) => {
			localStorage.setItem('user', JSON.stringify(action.payload));
			state.isAuthenticated = action.payload.isAuthenticated;
			state.token = action.payload.token;
			state.user = action.payload.user;
			state.authType = action.payload.authType;
		},
		removeAuth: (state: AuthSliceState) => {
			localStorage.removeItem('token');
			localStorage.removeItem('territory');
			localStorage.removeItem('flow');
			localStorage.removeItem('ticketConfig');
			localStorage.removeItem('commerceUuid');
			localStorage.removeItem('user');
			localStorage.removeItem('passengerQty');
			localStorage.removeItem('ticketCode');
			localStorage.removeItem('code');
			localStorage.removeItem('bearedToken');

			state.isAuthenticated = false;
			state.token = undefined;
			state.user = undefined;
			state.authType = undefined;
		},

		setUser: (state: AuthSliceState, action: PayloadAction<MembRegistryDataModel.IEHUser>) => {
			state.user = action.payload;
		},
	},
});

export const { setAuth, removeAuth, setUser } = AuthSlice.actions;
