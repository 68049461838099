import TimeSelectorStyles from './TimeSelector.styles';
import { Button, Container, Grid, GridItem, Stack } from '@chakra-ui/react';
import { PointSeatsDataModel } from 'shared/models/pointSeats.model';
import Select, { components, DropdownIndicatorProps } from 'react-select';
import clockIcon from 'asset/clock-icon.svg';
import moment from 'moment';
import { useEffect } from 'react';
import { useState } from 'react';

export interface timeDataSelector {
  readonly label: any;
  readonly value: PointSeatsDataModel.IPointSeat;
  readonly seats: number;
  readonly isDisabled: boolean;
  readonly isSelected: boolean;
}
const createOption = (
  label: any,
  value: PointSeatsDataModel.IPointSeat,
  seats: number,
  isDisabled: boolean,
  isSelected: boolean
) => ({
  label,
  value,
  seats,
  isDisabled,
  isSelected,
});
const findOption = (
  value: string | undefined,
  collection: timeDataSelector[]
) => {
  return collection.find((element) => {
    return element.value.uuid === value;
  });
};
const DropdownIndicator = (
  props: DropdownIndicatorProps<timeDataSelector, true>
) => {
  return (
    <components.DropdownIndicator {...props}>
      <img src={clockIcon} />
    </components.DropdownIndicator>
  );
};

export interface timeData {
  id: string;
  label: string;
  seats: number;
  disable: boolean;
}
export interface ITimeSelector {
  data: PointSeatsDataModel.IPointSeat[];
  date?: any;
  selectedItem: string | undefined;
  selectionEvent: (point: PointSeatsDataModel.IPointSeat) => void;
  customClass?: string;
  isReadOnly?: boolean;
  reservation_time?:number
}
export function TimeSelector({
  data,
  date,
  selectionEvent,
  selectedItem,
  customClass,
  isReadOnly = false,
  reservation_time
}: ITimeSelector): JSX.Element {
  const [options, setOptions] = useState<any>();

  useEffect(() => {
    const defaultOptions: timeDataSelector[] = [];
    const selectedDate = moment(date).format('L');
    const today = moment().format('L');
    const availableHours = moment(selectedDate).isSame(today);
    // const hourTen = moment().add(10, 'minute').format('HH:mm');
    const hourTen = moment().add(reservation_time, 'minute').format('HH:mm');
    console.log("HOUR TEN==>",hourTen)
    data.map((time, index) => {
      let disable;
      disable = isReadOnly;
      console.log("THIS IS DISABLES",disable,"TIME",time)
      if (!disable) {
        if (availableHours) {
          if (hourTen > time.time_hour) {
            disable = true;
          }
        }
      }
      const selected = time.uuid === selectedItem;
      defaultOptions.push(
        createOption(
          <div className={`option_label ${disable ? 'disabled' : ''}`}>
            {time.time_hour}{' '}
            {/* <span className={'option_label--seat'}>
              {time.current_available_seats} seats
            </span> */}
          </div>,
          time,
          10,
          disable,
          selected
        )
      );
      setOptions(defaultOptions);
    });

    const firstItem: any = defaultOptions.find((key) => !key.isDisabled);
    selectionEvent(firstItem?.value);
  }, [date, data]);

  return (
    <TimeSelectorStyles>
      {options && (
        <Select
          // styles={}
          className='basic-single'
          classNamePrefix='Time'
          name='color'
          options={options}
          components={{ DropdownIndicator }}
          value={findOption(selectedItem, options)}
          onChange={(newValue: any) => {
            selectionEvent(newValue?.value);
          }}
        />
      )}
      {/* <Container maxW='80%' marginTop='10px'>
        <Grid templateColumns='repeat(4, 1fr)' gap={2}>
          {data.map((item, index) => {
            const selected = item.uuid === selectedItem ? 'active' : '';
            const disable = item.current_available_seats < 1 || isReadOnly;
            return (
              <GridItem w='100%' h='10' key={index}>
                <Stack align='center'>
                  <Button
                    className={`btn ${selected}`}
                    onClick={(e) => {
                      selectionEvent(item);
                    }}
                    disabled={disable}
                  >
                    {item.time_hour}
                  </Button>
                  {!isReadOnly && (
                    <span className='seats'>
                      {item.current_available_seats} seats
                    </span>
                  )}
                </Stack>
              </GridItem>
            );
          })}
        </Grid>
      </Container> */}
    </TimeSelectorStyles>
  );
}
