import styled from 'styled-components';
import { breakpoints } from 'theme/breakpoints';

const RoutesReservationStyles = styled.div`
	display: flex;
	width: 100%;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	gap: 20px;
	margin-top: 20px;

	.routes-reservation-actions {
		display: flex;
		width: 100%;
		align-items: center;
		justify-content: center;
		background: #ffffff;
		gap: 25px;
		flex-direction: column;
		button {
			@media ${breakpoints.phone} {
				width: 250px;
			}
			@media ${breakpoints.tablet} {
				width: 421px;
				padding: 12px 10px;
			}
		}
	}

	.routes-reservation-subtitle {
		background: #e0f7f7;
		width: 100%;
		text-align: center;
		padding: 10px;
	}

	.routes-reservation-selection {
		margin-top: 10px;
	}

	.routes-reservation-point {
		label {
			margin-bottom: 10px;
		}
		min-width: 320px;
		text-align: center;
	}

	.custom-select {
		background-color: #33cccc;
		color: #ffffff;
		padding: 0 7px;
		border-radius: 14px;
		border: none;
		width: 43px;
		height: 27px;
	}
`;

export default RoutesReservationStyles;
