import SelectionBoxStyles from './SelectionBox.styles';

export interface ISelectionBoxData {
  id: string;
  label: string;
  extraLabel?: string;
}
export interface ISelectionBox {
  data: ISelectionBoxData[];
  headerLabel: string;
  selectedItem: string;
  selectionEvent: (id: string) => void;
  customClass?: string;
}
export function SelectionBox({
  data,
  headerLabel,
  selectionEvent,
  selectedItem,
  customClass,
}: ISelectionBox): JSX.Element {
  return (
    <SelectionBoxStyles>
      <div className={`selection-box-options`}>
        {data.map((item, index) => {
          console.log("THIS IS ITEM==>",item)
          const selected = item.id === selectedItem ? 'selected' : '';
          console.log("THIS IS SELECTED==>",selectedItem)
          return (
            <div
              key={index}
              className={`subtitle-1 selection-box-option ${selected}`}
              onClick={(e) => {
                selectionEvent(item.id);
              }}
            >
              <input
                type='radio'
                name={item.label}
                checked={item.id === selectedItem}
              />
              <div>
                <label className='selection-box-primary-label'>
                  {item.label}
                </label>
              </div>
              <div>
                {item.extraLabel && (
                  <label className='selection-box-secondary-label'>
                    {item?.extraLabel}
                  </label>
                )}
              </div>
            </div>
          );
        })}
      </div>
    </SelectionBoxStyles>
  );
}
