import { MembRegistryDataModel } from 'modules/Membership/MembRegister.model';

export namespace LoginDataModel {
	export interface ILoginRequest {
		email: string;
		password: string;
	}

	export interface ILoginTicket {
		email: string;
		ticket: string;
	}

	export enum AUTH_TYPE {
		FBS = 'firebase',
		FB = 'facebook',
		GG = 'google',
		EH = 'eh',
	}

	export interface IAuth {
		isAuthenticated?: boolean;
		user?: MembRegistryDataModel.IEHUser;
		token?: string;
		authType?: string;
	}
}
