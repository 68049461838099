import ehpAxios from 'shared/axios/axios.service';
import { TicketDataModel } from 'shared/models/ticket.model';

export module TicketService {
	export const getTicketNumber = async (): Promise<TicketDataModel.ITicketCode> => {
		try {
			const result = await ehpAxios.get('/ticket/get/number');
			return result.data;
		} catch (e) {
			console.log(e);
			throw e;
		}
	};

	export const getTicketByUuid = async (uuid: string): Promise<TicketDataModel.ITicket> => {
		try {
			const result = await ehpAxios.get(`/ticket/${uuid}`);
			return result.data;
		} catch (e) {
			console.log(e);
			throw e;
		}
	};

	export const getTicketByUser = async (firebaseId: string): Promise<TicketDataModel.ITicket> => {
		try {
			const result = await ehpAxios.get(`/ticket/user/${firebaseId}`);
			return result.data[0];
		} catch (e) {
			console.log(e);
			throw e;
		}
	};

	export const getTicketByNumber = async (ticketNumber: string): Promise<TicketDataModel.ITicket> => {
		try {
			const result = await ehpAxios.get(`/ticket/${ticketNumber}`);
			return result.data[0];
		} catch (e) {
			console.log(e);
			throw e;
		}
	};
	export const getTicketByNumberV2 = async (ticketNumber: string): Promise<TicketDataModel.ITicket> => {
		try {
			const result = await ehpAxios.get(`/ticket/v2/${ticketNumber}`);
			return result.data[0];
		} catch (e) {
			console.log(e);
			throw e;
		}
	};

	export const getTicketItineraryByTicket = async (ticket: string): Promise<TicketDataModel.ITicketItinerary> => {
		try {
			const result = await ehpAxios.get(`/ticket/itinerary-by-ticket/${ticket}`);
			return result.data[0];
		} catch (e) {
			console.log(e);
			throw e;
		}
	};
	export const getTicketItineraryByTicketV2 = async (ticket: string): Promise<TicketDataModel.ITicketItinerary> => {
		try {
			const result = await ehpAxios.get(`/ticket/itinerary-by-ticket/v2/${ticket}`);
			return result.data[0];
		} catch (e) {
			console.log(e);
			throw e;
		}
	};

	export const buyTicketNumber = async (
		ticketPayment: TicketDataModel.IBuyTicketRequest
	): Promise<TicketDataModel.IBuyTicketResponse> => {
		try {
			const result = await ehpAxios.post(`/ticket/buy`, ticketPayment);
			return result.data;
		} catch (e) {
			console.log(e);
			throw e;
		}
	};
	export const buyTicketNumberV2 = async (
		ticketPayment: any
	): Promise<TicketDataModel.IBuyTicketResponse> => {
		try {
			const result = await ehpAxios.post(`/ticket/buy/multiple`, ticketPayment);
			return result.data;
		} catch (e) {
			console.log(e);
			throw e;
		}
	};

	// export const checkPayment = async (firebaseId: string): Promise<boolean> => {
	// 	try {
	// 		const result = await ehpAxios.get(`/ticket/buy/check/${firebaseId}`);
	// 		return result.data;
	// 	} catch (e) {
	// 		console.log(e);
	// 		throw e;
	// 	}
	// };

	export const resendTicket = async (ticketNumber: string, email: string, name: string) => {
		try {
			const result = await ehpAxios.post(`/ticket/resend`, {
				ticketNumber,
				email,
				name,
			});
			return result.data;
		} catch (e) {
			console.log(e);
			throw e;
		}
	};

	export const sendFailedNotification = async (ticketPayment: TicketDataModel.IBuyTicketRequest, error: string) => {
		try {
			await ehpAxios.post(`/ticket/purchase/failed`, {
				ticket: ticketPayment,
				error: error,
			});
		} catch (e) {
			console.log(e);
			throw e;
		}
	};
}
