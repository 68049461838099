import TextDividerStyles from './TextDivider.styles';

const TextDivider = (props: any): JSX.Element => {
  const { text } = props;

  return (
    <TextDividerStyles>
      <div className='login-line'></div>
      <label className='label-3'>{text}</label>
      <div className='login-line'></div>
    </TextDividerStyles>
  );
};

export default TextDivider;
