import fbAsset from 'asset/facebook.png';
import googleAsset from 'asset/google.png';
import HopButtonStyles from './HopButton.styles';
interface IHopButton {
  look: string;
  content: string;
  className?: string;
  type?: 'button' | 'submit' | 'reset';
  onClick?: (e: any) => void;
  disabled?: boolean;
}
export function HopButton({
  look,
  content,
  className,
  type,
  onClick,
  disabled = false,
}: IHopButton): JSX.Element {
  const isGoogle = look === 'google';
  const isFb = look === 'fb';
  return (
    <HopButtonStyles
      disabled={disabled}
      className={className}
      look={look}
      type={type || 'button'}
      onClick={(e) => {
        if (onClick) onClick(e);
      }}
    >
      {(isGoogle || isFb) && (
        <span className='button-icon-container'>
          {isGoogle && <img alt='google-logo' src={googleAsset} />}
          {isFb && <img alt='fb-logo' src={fbAsset} />}
        </span>
      )}
      {content}
    </HopButtonStyles>
  );
}
