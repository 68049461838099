import UrlFormatPage from 'modules/UrlFormat/UrlFormat.page';
import { RouteObject } from 'react-router';
import AffilConfirmPage from './AffilConfirm/AffilConfirm.page';
import AffilRegister from './AffilRegister/AffilRegister';
import AffilSelectionPage from './AffilSelection/AffilSelection.page';
import AffilTerritoryPage from './AffilTerritory/AffilTerritory.page';
import AffilItinerary from "./AffilItinerary/AffilItinerary.page";
import AffilTrip from "./AffilTrip/AffilTrip.page";
import AffilTicketParkPage from './AffilTicketPark/AffilTicketPark.page';
import AffilTicketParkOnlyPage from "./AffilTicketParkOnly/AffilTicketParkOnly.page";
export const AffiliationRoutes: Array<RouteObject> = [
	{
		path: '/ticket/buy/commerce',
		element: <AffilSelectionPage />,
	},
	{
		path: '/ticket/buy/itinerary',
		element: <AffilItinerary />,
	},
	{
		path: '/ticket/buy/trip',
		element: <AffilTrip />,
	},
	{
		path: '/ticket/buy/commerce/:uuid',
		element: <AffilTerritoryPage />,
	},
	{
		path: '/ticket/buy/tickets',
		element: <AffilTicketParkPage />,
	},
	{
		path: '/ticket/buy/tickets/only',
		element: <AffilTicketParkOnlyPage />,
	},
	{
		path: '/c/:urlShorcut',
		element: <UrlFormatPage />,
	},
	{
		path: '/affiliation-selection',
		element: <AffilSelectionPage />,
	},
	{
		path: '/affiliation-register',
		element: <AffilRegister />,
	},
	{
		path: '/affiliation-confirm',
		element: <AffilConfirmPage />,
	},
	{
		path: '/affiliation-territory',
		element: <AffilTerritoryPage />,
	},
	{
		path: '/:territory',
		element: <AffilTerritoryPage />,
	},
];
