import logoLgAsset from 'asset/logo-eh.png';
import { HopButton } from 'components/global/HopButton/HopButton';
import { InputGroup } from 'components/global/InputGroup/InputGroup';
import { LoginService } from 'modules/login';
import { FC, ReactElement } from 'react';
import { useForm } from 'react-hook-form';
import toast from 'react-hot-toast';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import ForgotPasswordStyles from './ForgotPassword.style';

interface IFormForgotPassword {
	email?: string;
}

const ForgotPasswordEmailPage: FC = (): ReactElement => {
	const navigate = useNavigate();
	const { t } = useTranslation();
	const { register, handleSubmit, formState } = useForm<IFormForgotPassword>();

	const onSubmit = async (formValues: IFormForgotPassword) => {
		try {
			await LoginService.forgotPassword(formValues.email || '');
			toast.success(t('forgotPasswordEmailSent'));
			goToLogin();
		} catch (e) {
			toast.success(t('forgotPasswordEmailSent'));
			console.log(e);
		}
	};

	const goToLogin = () => {
		navigate(-1);
	};

	return (
		<ForgotPasswordStyles>
			<img className="login-logo" src={logoLgAsset} alt="Easy Hop Logo" />
			<h1 className="title-1 memb-password-title">{t('forgotEmailPassword')}</h1>

			<form onSubmit={handleSubmit(onSubmit)}>
				<div className="memb-password-form">
					<InputGroup
						label={t('email')}
						inputType="email"
						placeholder={t('emailPlaceholder')}
						registerProps={register('email', {
							required: t('emptyEmail'),
						})}
						errorMsg={formState.errors?.email?.message}
					/>
				</div>

				<div className="memb-password-actions">
					<HopButton className="memb-button" look="primary" content={t('recoverPassword')} type="submit" />
				</div>
			</form>
		</ForgotPasswordStyles>
	);
};

export default ForgotPasswordEmailPage;
