import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { RootState } from "store/store";

export interface LangState {
  langSelected: string;
}

const initialState: LangState = {
  langSelected: "",
};

export const LangSlice = createSlice({
  name: "lang",
  initialState,
  reducers: {
    setLang: (state: LangState, action: PayloadAction<LangState>) => ({
      ...state,
      ...action.payload,
    }),
  },
});

export const selectLang = (state: RootState) => state.lang;

export const { setLang } = LangSlice.actions;

export default LangSlice.reducer;
