import { UserCredential } from '@firebase/auth';
import { ProfileDataModel, ProfileService } from 'modules/Profile';
import { AuthSliceState, setAuth } from 'store';
import { LoginDataModel as Model } from './login.model';
import { LoginService } from './login.service';

export const login = async (data: Model.ILoginRequest) => {
	try {
		const result = await LoginService.signIn(data);
		const token = await LoginService.getAuthToken();
		let userInfo = undefined;
		const flow = localStorage.getItem('flow');

		if (flow === 'login') {
			userInfo = await ProfileService.getUserProfile(result.user.uid);
		}
		const payload = mapToPayloadObject(result, token, userInfo);

		payload.authType = Model.AUTH_TYPE.FBS;
		return setAuth(payload);
	} catch (e) {
		throw e;
	}
};

export const loginWithCodeAction = async (data: Model.ILoginTicket) => {
	try {
		const result = await LoginService.loginWithCode(data);
		const payload = {
			user: result,
			isAuthenticated: true,
		};
		return setAuth(payload);
	} catch (e) {
		throw e;
	}
};

export const loginWithFacebook = async () => {
	try {
		const result = await LoginService.signInWithFacebook();
		const token = await result.user.getIdToken();
		let userInfo = undefined;
		const flow = localStorage.getItem('flow');

		if (flow === 'login') {
			userInfo = await ProfileService.getUserProfile(result.user.uid);
		}
		const payload = mapToPayloadObject(result, token, userInfo || {});
		payload.authType = Model.AUTH_TYPE.FB;
		return setAuth(payload);
	} catch (e) {
		throw e;
	}
};

export const loginWithGoogle = async () => {
	try {
		const result = await LoginService.signInWithGoogle();
		const token = await result.user.getIdToken();
		let userInfo = undefined;
		const flow = localStorage.getItem('flow');
		if (flow === 'login') {
			userInfo = await ProfileService.getUserProfile(result.user.uid);
		}
		const payload = mapToPayloadObject(result, token, userInfo);
		payload.authType = Model.AUTH_TYPE.GG;
		return setAuth(payload);
	} catch (e) {
		throw e;
	}
};

const mapToPayloadObject = (
	result: UserCredential,
	token: string,
	data?: ProfileDataModel.IUserProfile
): AuthSliceState => {
	return {
		user: {
			firebaseId: result.user.uid,
			email: result.user.email || '',
			phoneNumber: result.user.phoneNumber || '',
			fullName: result.user.displayName || '',
			DNI: data?.DNI,
			...data,
		},
		isAuthenticated: true,
		token,
	};
};
