import { HopButton } from 'components/global/HopButton/HopButton';
import { FC, ReactElement } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { RootState } from 'store';
import ReservationConfirm from './ReservationConfirm.styles';

interface IRoutesMap {}

const ReservationConfirmPage: FC = (): ReactElement => {
	const navigate = useNavigate();
	const { t } = useTranslation();
	const { selectedReservation } = useSelector((state: RootState) => state.reservation);

	const goToMapRoute = () => {
		navigate('/routes-map');
	};

	const onSubmitBooking = async () => {
		goToMapRoute();
	};

	return (
		<ReservationConfirm>
			<h1 className="title-2">{t('reservationConfirmationDesc')}</h1>
			<div className="title-3 routes-reservation-subtitle">{t('saveSpace')}</div>

			<label className="label-4">
				{t('passQty')}: <b>{selectedReservation?.passengersQty}</b>
			</label>

			<label className="label-4">
				{t('point')}: <b>{selectedReservation?.point}</b>
			</label>

			<label className="label-4">
				{t('address')}: <b>{selectedReservation?.direction}</b>
			</label>

			<label className="label-4">
				{t('estimatedTime')}: <b>{selectedReservation?.time}</b>
			</label>

			<div className="routes-reservation-actions">
				<HopButton
					onClick={onSubmitBooking}
					className="affil-confirm-ticket-action"
					look="primary"
					content={t('saveSpace')}
				/>
			</div>
		</ReservationConfirm>
	);
};

export default ReservationConfirmPage;
