import { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { getCommerceByCodeUr } from './UrlFormat.service';

const UrlFormatPage = (): JSX.Element => {
	const routeParams = useParams();
	const { urlShorcut } = routeParams;

	useEffect(() => {
		getCommerceUrlByShorcut(urlShorcut).then((response) => {
			const openUrl = `${process.env.REACT_APP_BACKOFFICE_PASSENGER_URL}${response.id}`;
			window.open(openUrl, '_self');
		});
	}, []);

	const getCommerceUrlByShorcut = async (urlShorcut: any) => {
		const commerceInformation = await getCommerceByCodeUr(urlShorcut);
		return commerceInformation;
	};

	return <></>;
};

export default UrlFormatPage;
