import profileAsset from 'asset/passFinished.svg';
import { HopButton } from 'components/global/HopButton/HopButton';
import { FC, ReactElement } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import ProfilePasswordFinishStyles from './ProfilePasswordFinish.styles';

const ProfilePasswordFinishPage: FC = (): ReactElement => {
	const navigate = useNavigate();
	const { t } = useTranslation();

	const goToMyProfile = () => {
		navigate('/profile');
	};

	return (
		<ProfilePasswordFinishStyles>
			<h1 className="title-2 memb-password-title">{t('changePassword')}</h1>

			<div className="memb-password-result">
				<img src={profileAsset} />
				<h1 className="title-2 memb-password-text">{t('changePasswordConfirm')}</h1>
				<div className="memb-password-actions">
					<HopButton
						className="memb-button"
						onClick={goToMyProfile}
						look="primary"
						content={t('myProfile')}
						type="submit"
					/>
				</div>
			</div>
		</ProfilePasswordFinishStyles>
	);
};

export default ProfilePasswordFinishPage;
