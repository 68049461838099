import styled from 'styled-components';
import colors from 'theme/colors';

const SelectionBoxStyles = styled.div`
  .selection-box-header {
    background-color: ${colors.blueTurquoise};
    color: ${colors.white};
    padding: 10px;
    border-radius: 35px;
  }

  .selection-box-options {
    padding: 0 16px;
  }
  .selection-box-option {
    display: flex;
    justify-content: space-between;
    cursor: pointer;
    padding: 6px 12px;
    color: ${colors.grayTower};
    border-bottom: 1px solid ${colors.bluePolar};
    font-size: 22px;
    color: ${colors.blueDianne};
    gap: 10px;
    &.selected {
      font-family: 'Spartan Bold', sans-serif;
      color: ${colors.blueDianne};
      background-color: ${colors.titanWhite};
    }
    div {
      flex-basis: 50%;
    }
  }

  .selection-box-primary-label {
    font-size: 16px;
  }

  .selection-box-secondary-label {
    color: ${colors.grayJuniper};
    font-size: 16px;
  }
`;

export default SelectionBoxStyles;
