import styled from 'styled-components';
import { breakpoints } from 'theme/breakpoints';
import colors from 'theme/colors';
interface IHopButtonStyles {
  look: string;
}

const handleTypeStyles = (type: string) => {
  switch (type) {
    case 'primary':
      return `
        background-color: ${colors.blueDianne};
        color: ${colors.white};
        border-color: ${colors.blueDianne};
      `;
    case 'secondary':
      return `
        background-color: ${colors.white};
        color: ${colors.blueDianne};
        border-color: ${colors.blueDianne};
      `;
    case 'fb':
      return `
        justify-content: center;
        background-color: ${colors.blueAzure};
        color: ${colors.white};
        border-color: ${colors.blueAzure};
      `;
    case 'google':
      return `
        justify-content: center;
        background-color: ${colors.white};
        color: ${colors.blueDianne};
        filter: drop-shadow(0px 0px 4px rgba(0, 0, 0, 0.25));
        border-color: ${colors.white};
      `;
    default:
      return `
        background-color: ${colors.blueDianne};
        color: ${colors.white};
        border-color: ${colors.blueDianne};
      `;
  }
};

const HopButtonStyles = styled.button<IHopButtonStyles>`
  display: flex;
  align-items: center;
  justify-content: center;
  font-family: Spartan Bold;
  font-size: 1rem;
  line-height: 15px;
  padding: 8px;
  border: 2px solid #264653;
  border-radius: 5px;
  cursor: pointer;
  max-height: 35px;


  

  :focus,
  :hover {
    background: #6b8995;
    border: 2px solid #6b8995;
    outline: none;
  }

  @media ${breakpoints.tablet} {
    font-size: 1.7rem;
    line-height: 28px;
    padding: 16px 10px;
    max-height: none;
  }

  ${({ look }) => handleTypeStyles(look)}

  .button-icon-container {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 20px;
    width: 20px;
    margin-right: 10px;

    @media ${breakpoints.tablet} {
      height: 30px;
      width: 30px;
      margin-right: 20px;
    }

    img {
      display: block;
      height: 100%;
    }
  }
`;

export default HopButtonStyles;
