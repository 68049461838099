import * as ReactDOM from 'react-dom/client';
import { Provider } from 'react-redux';
import { BrowserRouter as Router } from 'react-router-dom';
import App from './App';
import reportWebVitals from './reportWebVitals';
import store from './store/store';
import { PayPalScriptProvider } from '@paypal/react-paypal-js';

const container = document.getElementById('root');

if (container) {
  const root = ReactDOM.createRoot(container);
  root.render(
    <PayPalScriptProvider
      options={{ 'client-id': `${process.env.REACT_APP_PAYPAL_CLIENT_ID}` }}
    >
      <Router>
        <Provider store={store}>
          <App />
        </Provider>
      </Router>
    </PayPalScriptProvider>
  );
}

reportWebVitals();
