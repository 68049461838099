import { FC, ReactElement, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { TerritoryDataModel } from 'shared/models/territory.model';
import mixpanel from 'shared/services/mixpanel';
import { ReservationService } from 'shared/services/reservation.service';
import { TerritoryService } from 'shared/services/territory.service';
import { RootState } from 'store';
import {
  setCenterAction,
  setPointsAction,
  setRoutesAction,
  setTripAction,
} from '../routes.action';
import { RoutesService } from '../routes.service';
import RoutesMapStyles from './RoutesMap.styles';

interface IRoutesMap {}

const RoutesMapPage: FC = (): ReactElement => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [territories, setTerritories] =
    useState<Array<TerritoryDataModel.ITerritory>>();
  const { ticket } = useSelector((state: RootState) => state.ticket);
  const { center } = useSelector((state: RootState) => state.reservation);
  const [territoryLocations, setTerritoryLocations] = useState<
    Array<TerritoryDataModel.ILocation>
  >([]);
  const { t } = useTranslation();
  const [activeMarker, setActiveMarker] = useState(null);
  const { isAuthenticated } = useSelector((state: RootState) => state.auth);

  const handleActiveMarker = (marker: any) => {
    if (marker === activeMarker) {
      return;
    }
    setActiveMarker(marker);
  };

  useEffect(() => {
    navigator.geolocation.getCurrentPosition((position) => {
      const location = {
        name: 'Current Location',
        location: {
          lat: position.coords.latitude,
          lng: position.coords.longitude,
        },
      };
      dispatch(
        setCenterAction({
          lat: position.coords.latitude,
          lng: position.coords.longitude,
        })
      );
      addNewLocation(location);
    });
    mixpanel.track('view-map', { 'new user': !isAuthenticated });
    getTerritories();
  }, []);

  useEffect(() => {
    getPointsFromTerritory();
  }, []);

  useEffect(() => {
    onUpdateTripPosition();
  }, [center]);

  const onUpdateTripPosition = () => {
    const locationIndex = territoryLocations.findIndex((location) => {
      return (location.name = 'driver');
    });
    if (center) {
      const newCenter = {
        name: 'driver',
        location: {
          lat: center.lat,
          lng: center.lat,
        },
      };

      if (locationIndex > 0) {
        territoryLocations[locationIndex] = newCenter;
        setTerritoryLocations(territoryLocations);
      } else {
        territoryLocations.push(newCenter);
        setTerritoryLocations(territoryLocations);
      }
    }
  };

  const addNewLocation = (location: TerritoryDataModel.ILocation) => {
    territoryLocations.push(location);
    setTerritoryLocations(territoryLocations);
  };

  const findCurrentTrip = async (territoryUuid: string) => {
    try {
      const result = await ReservationService.getCurrentTripByTicketTerritory(
        territoryUuid
      );
      if (result && result.length > 0) {
        dispatch(setTripAction(result[0]));
        return result[0];
      }
    } catch (e) {
      console.log(e);
    }
  };

  const getTerritories = async () => {
    try {
      const result = await TerritoryService.getAllTerritories();
      setTerritories(result);
      const territory = result.find((terr) => {
        return terr.territoryUuid === ticket?.territory;
      });
      if (territory) {
        const trip = await findCurrentTrip(territory.territoryUuid!);
        if (trip) {
          await getPositionFromTrip(territory.territoryUuid!, trip.tripId!);
        } else {
        }
      } else {
      }
    } catch (e) {
      console.log(e);
    }
  };

  const getPointsFromTerritory = async () => {
    try {
      const routes = await TerritoryService.getRouteByTerritory(
        ticket?.territory!
      );

      dispatch(setRoutesAction(routes));
      if (routes && routes.length > 0) {
        const points = await TerritoryService.getPointsByRoute(routes[0].uuid!);

        dispatch(setPointsAction(points));
        if (points) {
          points.forEach((point) => {
            addNewLocation({
              name: point.name || '',
              location: {
                lat: point.lat || 0,
                lng: point.lon || 0,
              },
              icon: {
                url: 'https://firebasestorage.googleapis.com/v0/b/easyhop-ac384.appspot.com/o/maps%2Fmarker_point.svg?alt=media&token=28e85513-0569-4dfc-bc52-9fc503de390f',
                scale: 1,
              },
            });
          });
        }
      }
    } catch (e) {
      console.log(e);
    }
  };

  const getPositionFromTrip = async (name: string, trip: string) => {
    try {
      await RoutesService.getPositionFromTrip(name, trip);
    } catch (e) {
      console.log(e);
    }
  };

  const goToReservation = () => {
    navigate('/routes-reservation');
  };

  const mapStyles = {
    height: '60vh',
    width: '100%',
  };

  return (
    <RoutesMapStyles>
      <h1 className='title-2'>{t('routes')}</h1>

      {/* <div className="routes-map-actions">
				<HopButton className="affil-confirm-ticket-action" look="secondary" content={t('saveSpace')} />
			</div> */}
    </RoutesMapStyles>
  );
};

export default RoutesMapPage;
