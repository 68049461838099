import styled from 'styled-components';
import { breakpoints } from 'theme/breakpoints';

const RoutesMapStyles = styled.div`
	display: flex;
	width: 100%;
	flex-direction: column;
	align-items: center;
	justify-content: center;

	.title-2 {
		padding: 20px 0px;
	}

	.routes-map-actions {
		display: flex;
		width: 100%;
		flex-direction: column;
		align-items: center;
		justify-content: center;
		background: #ffffff;
		padding: 20px 0px;

		button {
			@media ${breakpoints.phone} {
				width: 250px;
			}
			@media ${breakpoints.tablet} {
				width: 421px;
				padding: 12px 10px;
			}
		}
	}
`;

export default RoutesMapStyles;
