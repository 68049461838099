import moment from "moment";
import { FC, ReactElement } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { CategoryDataModel } from "shared/models";
import mixpanel from "shared/services/mixpanel";
import AffiliationItemInfoStyles from "./AffiliationItemInfo.styles";

export interface IAffiliationItemInfo {
  members?: number;
  regularPrice?: number;
  amount?: number;
  expirationDate?: string;
  typeName?: string;
  editButton?: boolean;
  pointInfo?: CategoryDataModel.IPointInfo;
  additionalAmount?: number;
  passengerNumber?: number;
  parkTickets?: Array<any>;
  ticketsPrice?:number
}


export const AffiliationItemInfo: FC<IAffiliationItemInfo> = (
  props: IAffiliationItemInfo
): ReactElement => {
  const {
    members,
    regularPrice,
    amount = 0,
    expirationDate,
    typeName,
    editButton,
    pointInfo,
    parkTickets,
    additionalAmount = 1,
    passengerNumber = 1,
    ticketsPrice=0,
  } = props;
  const { t } = useTranslation();
  const navigate = useNavigate();

  const additional = +additionalAmount * +passengerNumber;
  const ticketsPriceAdditional= +ticketsPrice * +passengerNumber
  const totalAmount = +amount + additional +ticketsPriceAdditional;
  console.log("TICKETS ALONE==>",ticketsPrice)
  console.log("THIS IS THE additional AMOUNT OF THE PRICE==>",additional)
  console.log("THIS IS THE ticketsPriceAdditional AMOUNT OF THE PRICE==>",ticketsPriceAdditional)
  console.log("THIS IS THE totalAmount OF THE PRICE==>",totalAmount)

  return (
    <AffiliationItemInfoStyles>
      <div className="wrapper">
        <div>
          <label className="label-4 title">{t("yourSelection")}</label>
        </div>
        <div className="membership-type item-wrapper">
          <label className="label-2 column1Item">{t("membershipType")}: </label>
          <span className="label-3 column2Item affiliation-item-total-payment ">
            {typeName}
          </span>
        </div>
        <div className="membership-people item-wrapper">
          <label className="label-2 column1Item">{t("people")}: </label>
          <span className="label-3 column2Item affiliation-item-total-payment ">
            {members} {t("members")}
          </span>
        </div>
        {pointInfo?.useDate && (
          <div className="membership-additional item-wrapper">
            <label className="label-2 column1Item">Shuttle booking: </label>
            <span className="label-3 column2Item affiliation-item-total-payment">
              {moment(pointInfo?.useDate).format("MM/DD/YYYY")}
            </span>
          </div>
        )}
        {parkTickets?.map((park) => {
          let finalPrice;
          if(members!=undefined){
             finalPrice=members*park.price
          }
          else{
            finalPrice=park.price
          }
          
          return (
            <div className="membership-additional item-wrapper">
              <label className="label-2 column1Item">Tickets to {park.park_name} x{members}</label>
              <span className="label-3 column2Item affiliation-item-total-payment">
              {finalPrice}
              </span>
            </div>
          );
        })}
        {/* <div className='membership-regular'>
          <label className='label-2'>{t('regularCost')}: </label>
          <span className='label-3 affiliation-item-total-payment regular-price'>
            ${regularPrice}
          </span>
        </div> */}
        {/* {additionalAmount > 0 && (
          <div className='membership-additional item-wrapper'>
            <label className='label-2 column1Item'>
              {t('Additional cost')}:{' '}
            </label>
            <span className='label-3 column2Item affiliation-item-total-payment'>
              ${additional}
            </span>
          </div>
        )} */}

        <div className="total-section item-wrapper">
          <label className="label-2 column1Item total-section--label">
            {t("totalPayment")}:{" "}
          </label>
          <span className="label-3 column2Item affiliation-item-total-payment f-24">
            ${totalAmount}
          </span>
        </div>
        {expirationDate && (
          <div>
            <label>{t("validUntil")}: </label>
            <span>{expirationDate}</span>
          </div>
        )}
        {editButton && (
          <a href="" className="edit-btn">
            edit
          </a>
        )}
        {pointInfo && (
          <>
            <div className="label4Wrapper">
              <label className="label-4 title">{t("you_pickup")}</label>
            </div>
            <div
              className="stop_detail"
              onClick={() => {
                navigate("/ticket/buy/trip");
                mixpanel.track("Edit itinerary");
              }}
            >
              <div className="stop_detail--time">
                {pointInfo?.pickUpTime?.time_hour}
              </div>
              <div className="stop_detail--details item-wrapper">
                <label className="stop_detail--details__from">
                  from {pointInfo?.pickUpPoint?.name}
                </label>
                <label className="stop_detail--details__to">
                  to {pointInfo?.returnPoint?.name}
                </label>
              </div>
              {/* <div className='stop_detail--trash'>
                <img src={trashAsset} alt='' />
              </div> */}
              <a className="stop_detail--edit_btn">edit</a>
            </div>
          </>
        )}
      </div>
    </AffiliationItemInfoStyles>
  );
};
