import { FirebaseApp, FirebaseOptions, initializeApp } from 'firebase/app';

const firebaseConfig: FirebaseOptions = {
	apiKey: 'AIzaSyD-7LgoJrJfhg0OcNT9J6iSg04PwUmpdUc',
	authDomain: 'passenger.easyhop.co',
	projectId: 'easyhop-ac384',
	storageBucket: 'easyhop-ac384.appspot.com',
	messagingSenderId: '825731279248',
	appId: '1:825731279248:web:49acc9c4a65a46b0946b15',
	databaseURL: 'https://easyhop-ac384-default-rtdb.firebaseio.com',
};

const devfirebaseConfig: FirebaseOptions = {
	apiKey: 'AIzaSyCxAXrTgNBiAh1qPPRqjgwfLlUaU91lJV4',
	authDomain: 'easyhop-develop.firebaseapp.com',
	projectId: 'easyhop-develop',
	storageBucket: 'easyhop-develop.appspot.com',
	messagingSenderId: '313347941118',
	appId: '1:313347941118:web:c2e9b8e0499281170cbbaf',
	measurementId: 'G-8XBCBD2SZJ',
};

console.log('environment', process.env.REACT_APP_EHP_ENV);

export const EHFirebaseApp: FirebaseApp = initializeApp(
	process.env.REACT_APP_EHP_ENV === 'DEV' ? devfirebaseConfig : firebaseConfig
);
