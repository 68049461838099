export module LocalStorageService {
  export const updateLocalStorage = (data: any) => {
    const localData = JSON.parse(localStorage.getItem('easyhop') || '{}');
    localStorage.setItem(
      'easyhop',
      JSON.stringify(
        {
          ...localData,
          ...data,
        } || {}
      )
    );
  };

  export const getLocalStorage = () => {
    return JSON.parse(localStorage.getItem('easyhop') || '{}');
  };

  export const cleanCommerce = () => {
    const localData = JSON.parse(localStorage.getItem('easyhop') || '{}');
    localStorage.setItem(
      'easyhop',
      JSON.stringify(
        {
          ...localData,
          passengerQty: false,
          selectedOption: false,
          ticketCategory: false,
          selPeriod: false,
          parkTickets:[],
          territory: false,
          pointInfo: false,
          additionalAmount: false,
          commerceUuid: false,
          ticketResult: false,
          ticketsPrice:0
        } || {}
      )
    );
  };

  export const cleanLocalStorage = () => {
    localStorage.removeItem('easyhop');
  };
}
