import ehpAxios from 'shared/axios/axios.service';
import { TerritoryDataModel } from 'shared/models/territory.model';

export module TerritoryService {
  export const getAllTerritories = async (): Promise<
    Array<TerritoryDataModel.ITerritory>
  > => {
    try {
      const result = await ehpAxios.get('/territory');
      return result.data;
    } catch (e) {
      console.log(e);
      throw e;
    }
  };
  export const getTerritoryByUuid = async (
    uuid: string
  ): Promise<TerritoryDataModel.ITerritory> => {
    try {
      const result = await ehpAxios.get(`/territory/${uuid}`);
      return result.data;
    } catch (e) {
      console.log(e);
      throw e;
    }
  };

  export const getPointsByRoute = async (
    uuid: string
  ): Promise<Array<TerritoryDataModel.IPoint>> => {
    try {
      const result = await ehpAxios.get(`/point/route/${uuid}`);
      return result.data;
    } catch (e) {
      console.log(e);
      throw e;
    }
  };

  export const getPointsByTerritory = async (
    uuid: string
  ): Promise<Array<TerritoryDataModel.IPoint>> => {
    try {
      const result = await ehpAxios.get(`/point/territory/${uuid}`);
      return result.data;
    } catch (e) {
      console.log(e);
      throw e;
    }
  };

  export const getRouteByTerritory = async (
    uuid: string
  ): Promise<Array<TerritoryDataModel.IRoute>> => {
    try {
      const result = await ehpAxios.get(`/route/territory/${uuid}`);
      return result.data;
    } catch (e) {
      console.log(e);
      throw e;
    }
  };
  export const getParksByTerritory = async (
    uuid: string
  ): Promise<Array<TerritoryDataModel.IPark>> => {
    try {
      const result = await ehpAxios.get(`parks/${uuid}`);
      console.log("PARKS SERVICE",result)
      return result.data;
    } catch (e) {
      console.log(e);
      throw e;
    }
  };
}
