import { getDatabase, onValue, ref } from '@firebase/database';
import { EHFirebaseApp } from 'shared';
import ehpAxios from 'shared/axios/axios.service';
import store from 'store/store';
import { RoutesDataModel } from '.';
import { setTripDataAction } from './routes.action';

export module RoutesService {
	export const requestReservation = () => {};

	export const getPositionFromTrip = (territoryUuid: string, uuid: string) => {
		try {
			const database = getDatabase(EHFirebaseApp);
			const tripRef = ref(database, 'trip/' + territoryUuid + '/' + uuid);
			onValue(tripRef, (snapshot) => {
				const data = snapshot.val();
				if (data) {
					store.dispatch(setTripDataAction(data));
				}
			});
		} catch (e) {
			throw e;
		}
	};

	export const addNewReservation = async (
		reservation: RoutesDataModel.BookReservationRequest
	): Promise<RoutesDataModel.BookReservationResponse> => {
		try {
			const result = await ehpAxios.post(`/reservation`, reservation);
			return result.data;
		} catch (e) {
			throw e;
		}
	};
}
