import { RouteObject } from 'react-router';
import ProfilePage from './ProfileDashboard/ProfileDashboard.page';
import ProfilePasswordPage from './ProfilePassword/ProfilePassword.page';
import ProfilePasswordFinishPage from './ProfilePasswordFinish/ProfilePasswordFinish.page';
import ProfileUpdatePage from './ProfileUpdate/ProfileUpdate.page';

export const ProfileRoutes: Array<RouteObject> = [
	{
		path: '/profile',
		element: <ProfilePage />,
	},
	{
		path: '/profile-update',
		element: <ProfileUpdatePage />,
	},
	{
		path: '/profile-password',
		element: <ProfilePasswordPage />,
	},
	{
		path: '/profile-password-finish',
		element: <ProfilePasswordFinishPage />,
	},
];
