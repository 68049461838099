import { setFlowAction, toogleLoading } from 'app.action';
import logoLgAsset from 'asset/logo-eh.png';
import { HopButton } from 'components/global/HopButton/HopButton';
import { InputGroup } from 'components/global/InputGroup/InputGroup';
import TextDivider from 'components/TextDivider/TextDivider.page';
import { FC, ReactElement, useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { toast } from 'react-hot-toast';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import mixpanel from 'shared/services/mixpanel';
import { RootState } from 'store';
import { loginWithCodeAction } from './login.action';
import LoginStyles from './Login.style';
import { useParams } from "react-router-dom"

interface IFormLogin {
  email: string;
  ticket: string;
}

const LoginPage: FC = (): ReactElement => {
  const { register, handleSubmit, formState } = useForm<IFormLogin>();
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { isAuthenticated } = useSelector((state: RootState) => state.auth);
  const { email } = useParams()
  const { transfer } = useParams()

  useEffect(() => {
    mixpanel.register({Language:"English"});
    mixpanel.track('Init purchase', { Source: 'website' });
    mixpanel.time_event("Ticket Successful");
    
    console.log("EMAIL=>",email)
    console.log("TRANSFER=>",transfer)
    if(email && transfer){
      console.log("SE INGRESO CON URL CON LOS VALORES DE LOGIN")
      const formUrl = {} as IFormLogin;
      formUrl.email=email;
      formUrl.ticket=transfer
      onSubmit(formUrl)
    }


  }, []);

  const onSubmit = async (formValues: IFormLogin): Promise<void> => {
    try {
      localStorage.setItem('flow', 'login');
      dispatch(toogleLoading(true));
      const values = { ...formValues };
      values.email = formValues.email.toLowerCase();
      values.ticket = formValues.ticket.toUpperCase();
      const actionResult = await loginWithCodeAction(values);
      dispatch(setFlowAction('login'));
      dispatch(actionResult);
      dispatch(toogleLoading(false));
      mixpanel.track('login', {
        'new user': true,
        'ticket-number': formValues.ticket.toUpperCase(),
        email: formValues.email.toLowerCase(),
      });
      goToHomePage();
    } catch (e: any) {
      validateCodeErrors(e.code);
      dispatch(toogleLoading(false));
    }
  };

  const validateCodeErrors = (code: string) => {
    switch (code) {
      default: {
        toast.error(t('wrongCredentials'));
        break;
      }
    }
  };

  const goToHomePage = () => {
    navigate('/profile');
  };

  const goToRegistration = () => {
    dispatch(setFlowAction('registry'));
    localStorage.setItem('flow', 'registry');
    navigate('/registry');
  };

  const goToRecoverPassword = () => {
    navigate('/forgot-password-email');
  };

  const goToBuyMembership = () => {
    dispatch(setFlowAction('registry'));
    localStorage.setItem('flow', 'registry');
    navigate('/affiliation-territory');
  };

  useEffect(() => {
    if (isAuthenticated) {
      goToHomePage();
    }
  }, [isAuthenticated]);

  return (
    <LoginStyles>
      <img className='login-logo' src={logoLgAsset} alt='Easy Hop Logo' />
      <div className='login-easily'>
        <label className='label-4'>{t('loginEasy')}</label>
        <HopButton
          className='login-buy-here-button'
          look='secondary'
          content={t('buyHere')}
          type='button'
          onClick={goToBuyMembership}
        />
      </div>
      <TextDivider text={t('orLoginInYourAccount')} />
      <form onSubmit={handleSubmit(onSubmit)}>
        <div className='login-form'>
          <InputGroup
            label={t('email')}
            placeholder={t('emailPlaceholder')}
            inputType='text'
            registerProps={register('email', {
              required: t('emptyEmail'),
              pattern: {
                value:
                  /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
                message: t('emailErrorFormat'),
              },
            })}
            errorMsg={formState.errors.email?.message}
          />
          <InputGroup
            label={t('membershipCode')}
            placeholder={t('membershipCode')}
            inputType='text'
            registerProps={register('ticket', {
              required: t('emptyMembership'),
            })}
            errorMsg={formState.errors.ticket?.message}
          />
        </div>

        <HopButton
          className='login-button'
          look='primary'
          content={t('access')}
          type='submit'
        />
      </form>
      <input type='hidden' value='1.1.0' />
    </LoginStyles>
  );
};

export default LoginPage;
