import { removeAuth, setFlow, setFooter, setLanguage, setLoading, setSidebar } from 'store';

export const toogleLoading = (isLoading: boolean) => {
	return setLoading(isLoading);
};

export const setAppLanguage = (language: string) => {
	return setLanguage(language);
};

export const toogleSidebar = (isSidebarOpen: boolean) => {
	return setSidebar(isSidebarOpen);
};

export const toogleFooter = (isFooter: boolean) => {
	return setFooter(isFooter);
};

export const setFlowAction = (flow: string) => {
	return setFlow(flow);
};

export const logout = () => {
	return removeAuth();
};
