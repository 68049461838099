import ehpAxios from 'shared/axios/axios.service';
import { PointSeatsDataModel } from 'shared/models/pointSeats.model';
import axios from 'axios';
export module PointSeatsService {
  export const getSeatsAndHourByPointUuid = async (
    uuid: string,
    direction: string
  ): Promise<Array<PointSeatsDataModel.IPointSeat>> => {
    try {
      const result = await ehpAxios.get(
        `/point-seats/schedule/${uuid}?direction=${direction}`
      );
      return result.data;
    } catch (e) {
      console.log(e);
      throw e;
    }
  };
  export const getSeatsAndHourByPointUuidWithInventory = async (
    pointSeats: Array<any>,
    date: Date
  ): Promise<Array<PointSeatsDataModel.IPointSeat>> => {
    try {
      const baseURL= process.env.REACT_APP_EHP_URL
      let payload = { date: date, pointSeats: pointSeats };

      let result = await axios.post(`${baseURL}/ticket/inventory`, payload);
      // let data = res.data;
      console.log("BASE URL",baseURL);
      console.log("RESULT DATA",result.data)
      return result.data;
    } catch (e) {
      console.log(e);
      throw e;
    }
  };
}
