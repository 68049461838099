import styled from 'styled-components';
import { breakpoints } from 'theme/breakpoints';
import colors from 'theme/colors';

const AffilTerritoryStyles = styled.div`
  .affil-territory-container{
    display:flex;
    justify-content:center;
    flex-wrap:wrap;
  }
  .image-territory{
    /* height: 90px; */
    top:20px;
    position: absolute;
    right: 35px;
    height: 30px;
    width:30px;
    object-fit: scale-down;
  }

  max-width: 390px;
  margin: 0 auto;
  padding-top: 48px;
  background-color: ${colors.white};

  @media ${breakpoints.tablet} {
    max-width: 600px;
  }

  .affil-selection-title {
    color: ${colors.blueDianne};
    margin-bottom: 35px;
    text-align: center;
    padding: 0 35px;
  }

  .affil-selection-subtitle {
    color: ${colors.grayJuniper};
    margin-top: 18px;
    margin-bottom: 6px;
    padding: 0 26px;
    font-size: 18px;
    text-align: center;
  }
  /* .affil-territory-item-container {
    display: flex;
    width: 90%;
    background-color: #FAFBFF;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    padding:20px;
    margin-bottom: 10px;
    border-radius: 10px;
    box-shadow: 2px 2px 10px 2px rgba(0,0,0,.8);
  } */

  .affil-territory-item {
    /* background: #FAFBFF; */
    position:relative;
    filter: drop-shadow(0px 4px 4px rgba(0,0,0,0.13));
    width: 350px;
    background-color: #FAFBFF;
    border-radius: 10px;
    padding: 25px 20px;
    margin-bottom: 20px;
    display: flex;
    gap: 10px;
    align-items: stretch;
    flex-wrap: nowrap;
    align-content: center;
    flex-direction: row;
    
  }

  .buy-pass-button {
    /* border: 2px solid ${colors.blueDianne}; */
    background-color: ${colors.white};
    color: ${colors.blueDianne};
  }

  .affil-territory-name {
    width: 200px;
    font-weight: bold;
    font-size: 1.2rem;
    text-align: left;
    color: rgb(38,70,83);
    font-family: 'Spartan Medium';
  }

  button {
    width: 150px;
    height: 45px;
  }
  
  .option-disabled-territory {
    background-color: rgba(0, 0, 0, 0.12);
      cursor: not-allowed;
  }

  .option-disabled-territory-warning {
    background-color: rgba(255,204,0, 0.12);
    margin-bottom: 20px;
    padding: 10px;
    text-decoration: none;
    color: #264653;
    text-align: center;
    border-radius: 10px;
    
    span {
      font-weight: bold;
    }
  }
  
`;

export default AffilTerritoryStyles;
