import moment from 'moment';
import { FC, ReactElement } from 'react';
import { useTranslation } from 'react-i18next';
import ItineraryStyles from './Itinerary.styles';
import addIcon from 'asset/add-item.svg';
import trashIcon from 'asset/icon-trash.svg';
export interface Iitinerary {
	time?: string;
	from?: string;
	top?: number;
	uuid?: string;
	addAction?: any;
	removeAction?: any;
	editAction?: any;
}

export const Itinerary: FC<Iitinerary> = (props: Iitinerary): ReactElement => {
	const {
		addAction,
		removeAction,
		editAction
	} = props;
	const { t } = useTranslation();

	return (
		<ItineraryStyles>
			<h2 className='title main'>{t('yourItinerary')}</h2>
			<h2 className='title secondary'>6 {t('availableTrips')}</h2>
			<div className='itinerary'>
				<div className='itinerary--item'>
					<div className='itinerary--item__time'>
						12:00
					</div>
					<div className='itinerary--item__detail'>
						<span className='itinerary--item__detail--from'>from Fortuna Downtown</span>
						<span className='itinerary--item__detail--to'>to Baldi</span>
					</div>
					<button className='itinerary--item__delete' onClick={removeAction}><img  alt='delete' src={trashIcon}/></button>
				</div>

				<div className='itinerary--item'>
					<div className='itinerary--item__time'>
						12:00
					</div>
					<div className='itinerary--item__detail'>
						<span className='itinerary--item__detail--from'>from Fortuna Downtown</span>
						<span className='itinerary--item__detail--to'>to Baldi</span>
					</div>
					<button className='itinerary--item__delete' onClick={removeAction}><img  alt='delete' src={trashIcon}/></button>
				</div>


				<div className='itinerary--add'>
					<img className='itinerary--add__icon' src={addIcon} alt='add new'/>
					<button className='itinerary--add__btn' onClick={addAction}>
						+ add trip
					</button>
				</div>
			</div>
		</ItineraryStyles>
	);
};
