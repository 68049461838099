import styled from 'styled-components';
import { breakpoints } from 'theme/breakpoints';
import colors from 'theme/colors';
import shadows from 'theme/shadows';
import { buttonsStyles } from 'theme/typography';

const MembConfirmStyles = styled.div`
	margin: 0 auto;
	padding-top: 48px;
	background-color: ${colors.white};
	display: flex;
	align-items: center;
	justify-content: center;

	.memb-confirm-container {
	}

	.memb-confirm-title {
		color: ${colors.blueDianne};
		margin-bottom: 35px;
		text-align: center;
	}

	.memb-confirm-ticket {
		display: flex;
		align-items: center;
		justify-content: center;
		flex-direction: column;
		text-align: center;
		background: #ffffff;
		box-shadow: ${shadows.black_o10};
		border-radius: 20px;
		padding: 16px 35px 40px;
		color: ${colors.blueDianne};
		margin-bottom: 30px;

		@media ${breakpoints.tablet} {
			width: 640px;
		}
	}

	.memb-confirm-ticket-title {
		margin-bottom: 15px;
		font-family: Spartan Bold;
	}

	.memb-confirm-ticket-desc {
		margin-bottom: 20px;
	}

	.memb-confirm-ticket-action {
		margin: 0 auto;
		width: 190px;
	}

	.memb-button {
		margin: ${buttonsStyles.verticalMarginSpacing}
		width: 210px;
		cursor: pointer;
		font-size:1.2rem;
		line-height:28px;
		max-height: 60px !important;

		@media ${breakpoints.phone} {
			width: 250px;
		}

		@media ${breakpoints.tablet} {
			width: 421px;
			padding: 12px 10px;
		}
	}
	.next-button-container {
		display: flex;
		width: 100%;
		align-items: center;
		justify-content: center;
	}
`;

export default MembConfirmStyles;
