const colors = {
	black: '#00000',
	black_o10: 'rgba(0, 0, 0, 0.1)',
	blueDianne: '#264653',
	bluePolar: '#E0F7F7',
	blueAzure: '#396EB5',
	blueTurquoise: '#33CCCC',
	blueTurquoise_o15: 'rgba(51, 204, 204, 0.15)',
	purpleLight: '#E6E8FF',
	grayTower: '#A4B7BF',
	grayJuniper: '#6B8995',
	orangeOutrageous: '#FF5736',
	white: '#FFFFFF',
	titanWhite: '#E5E8FF',
	darkBlue: '#39677A',
	dullBull: '#447C93',
	sanctuarySpa: '#6BADEB',
	orochimaru: '#D9D9D9',
	lightMutedCyan: '#33CCCC'
};

export default colors;
