import { toogleLoading, setFlowAction } from 'app.action';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router';
import { TerritoryDataModel } from 'shared/models/territory.model';
import { LocalStorageService } from 'shared/services/localStorage.service';
import mixpanel from 'shared/services/mixpanel';
import { TerritoryService } from 'shared/services/territory.service';
import { RootState } from 'store';
import { useParams } from 'react-router-dom';
import { useSearchParams } from 'react-router-dom';
import { setCommerceUuidAction } from 'modules/Membership/MembRegister.action';

import {
  setTerritoryAction,
  setTicketConfigAction,
} from '../affiliation.action';
import AffilTerritoryStyles from './AffilTerritory.styles';
import { CommerceDataModel } from 'shared/models/commerce.model';
import { CommerceService } from 'shared/services/commerce.service';

const AffilTerritoryPage = (): JSX.Element => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { t, i18n } = useTranslation();
  const { isAuthenticated } = useSelector((state: RootState) => state.auth);
  const { territory } = useParams();

  const [territories, setTerritories] = useState<
    Array<TerritoryDataModel.ITerritory>
  >([]);
  const [searchParams] = useSearchParams();
  const [commerce, setCommerce] = useState<CommerceDataModel.ICommerce>();

  const greenAirwaysUrl = `https://partner.costaricagreenair.com/${i18n.language}/easy-hop/`;

  const getCommerceNameByUuid = async (uuid: string) => {
    try {
      console.log('TRY TOI GET COMMERCE');
      const result = await CommerceService.getCommerceByUuid(uuid);
      console.log('RESULT COMMERCE', result);
      // Disable Santa Teresa on QR
      // if (result?.territoryName === 'Santa Teresa') {
      //   navigate(`/affiliation-territory`);
      //   return;
      // }
      setCommerce(result);
      dispatch(setCommerceUuidAction(uuid));
      const territory = await TerritoryService.getTerritoryByUuid(
        result?.territoryUuid!
      );
      LocalStorageService.updateLocalStorage({
        territory: result,
        commerceUuid: uuid,
      });
      localStorage.setItem('territory', JSON.stringify(result?.territoryUuid));
      localStorage.setItem('commerceUuid', uuid);

      mixpanel.register({
        commerce: result?.name,
        territory: result?.territoryName,
        Language: 'English',
      });

      mixpanel.track('Init purchase', {
        Source: 'QR',
        'new-user': !isAuthenticated,
      });
      mixpanel.time_event('Ticket Successful');

      dispatch(setTerritoryAction(territory));
    } catch (e) {
      console.log(e);
    }
  };

  const getCommerceNameByShortUrl = async (short_url: string) => {
    try {
      console.log('TRY TOI GET COMMERCE BY SHORT');
      const result = await CommerceService.getCommerceByShorcut_url(short_url);
      console.log('RESULT COMMERCE', result);
      // Disable Santa Teresa on QR
      // if (result?.territoryName === 'Santa Teresa') {
      //   navigate(`/affiliation-territory`);
      //   return;
      // }
      setCommerce(result);
      dispatch(setCommerceUuidAction(result.id));
      const territory = await TerritoryService.getTerritoryByUuid(
        result?.territoryUuid!
      );
      LocalStorageService.updateLocalStorage({
        territory: result,
        commerceUuid: result.id,
      });
      localStorage.setItem('territory', JSON.stringify(result?.territoryUuid));
      localStorage.setItem('commerceUuid', result.id);

      mixpanel.register({
        commerce: result?.name,
        territory: result?.territoryName,
        Language: 'English',
      });

      mixpanel.track('Init purchase', {
        Source: 'QR',
        'new-user': !isAuthenticated,
      });
      mixpanel.time_event('Ticket Successful');

      dispatch(setTerritoryAction(territory));
    } catch (e) {
      console.log(e);
    }
  };

  const init = async () => {
    LocalStorageService.updateLocalStorage({
      ticketsPrice: 0,
    });
    dispatch(toogleLoading(true));
    const param = Array.from(searchParams.entries());
    console.log('PARAMS OF THE PAGE==>', param);
    if (param && param.length > 0) {
      dispatch(setFlowAction('commerce'));
      mixpanel.register({
        Source: 'QR',
      });
      LocalStorageService.cleanCommerce();
      localStorage.setItem('flow', 'commerce');
      dispatch(setCommerceUuidAction(param[0][0]));
      if (param[0][0].length == 4) {
        await getCommerceNameByShortUrl(param[0][0]);
      } else {
        await getCommerceNameByUuid(param[0][0]);
      }
    } else {
      console.log('ELSE OF COMMERCE LOGIC');
      mixpanel.register({
        Source: 'website',
      });
    }
    // getPassengersAllowed().then((result) => {
    //   getCategories(result).then(() => {
    //     handleLocalStorageValues();
    //     handleAdditionals();
    //     dispatch(toogleLoading(false));
    //   });
    // });
  };

  useEffect(() => {
    dispatch(toogleLoading(true));
    init();
    getTerritories();
    removeLocalConfig();
    setTerritoryFromUrl();
  }, []);

  const removeLocalConfig = () => {
    dispatch(setTicketConfigAction({}));
    LocalStorageService.cleanCommerce();
  };
  const setTerritoryFromUrl = async () => {
    if (territory) {
      //HERE I REPLACE ALL THE CHARACTERS THAT I DONT NEED
      const filterTerritory: string = territory.replace('-', '');

      const result = await TerritoryService.getAllTerritories();

      mixpanel.register({ Language: 'English' });
      mixpanel.track('Init purchase', { Source: 'website' });
      mixpanel.time_event('Ticket Successful');

      //HERE I FILTER THE CORRECT TERRITORY WITH THE POSIBLE VALUES THAT I HAVE available
      const resultFilter = result.filter((element: any) => {
        return (
          filterTerritory ===
          element.territoryName.replace(' ', '').toLowerCase()
        );
      });
      if (resultFilter.length > 0) {
        const territorySelectedFromUrl = resultFilter[0];
        onSelectTerritory(territorySelectedFromUrl);
      }
    }
  };

  const getTerritories = async () => {
    try {
      const result = await TerritoryService.getAllTerritories();
      setTerritories(result);
      console.log(territories);
      dispatch(toogleLoading(false));
    } catch (e) {
      console.log(e);
    }
  };

  const goToAffiliationSelection = async () => {
    try {
      navigate('/ticket/buy/commerce');
    } catch (e) {
      throw e;
    }
  };

  const onSelectTerritory = async (
    territory: TerritoryDataModel.ITerritory
  ) => {
    console.log('THIS IS THE TERRITORY==>', territory);
    LocalStorageService.updateLocalStorage({
      territory: territory,
    });
    mixpanel.register({
      territory: territory.territoryName,
    });
    mixpanel.track('Choose territory', {
      Source: 'website',
      'new user': !isAuthenticated,
    });
    dispatch(setTerritoryAction(territory));
    await goToAffiliationSelection();
  };

  return (
    <AffilTerritoryStyles>
      <h3 className='title-3 affil-selection-subtitle'>{commerce?.name} </h3>
      <h1 className='title-2 affil-selection-title'>{t('chooseTerritory')} </h1>

      <div className='affil-territory-container'>
        <a
            className='option-disabled-territory-warning'
            href='https://api.whatsapp.com/send?phone=50661309784'
            target='_blank'
        >
          Bienvenido a easy hop, la forma más sencilla de descubrir costa rica
        </a>

        <a
            className='option-disabled-territory-warning'
            href='https://api.whatsapp.com/send?phone=50661309784'
            target='_blank'
        >
          Nuestro servicio se encuentra en mantenimiento, contáctanos al WhatsApp y descubre cómo movilizarle en Santa
          Teresa y La Fortuna.
        </a>

        {/*<div*/}
        {/*    onClick={() => {*/}
        {/*      navigate('/ticket/buy/tickets/only');*/}
        {/*    }}*/}
        {/*    className='affil-territory-item'*/}
        {/*>*/}
        {/*  <label className='affil-territory-name'>La Fortuna Entrances</label>*/}
        {/*  /!* <img src={territory.picture} className="image-territory" /> *!/*/}
        {/*  <img*/}
        {/*      src='https://firebasestorage.googleapis.com/v0/b/easyhop-ac384.appspot.com/o/resources%2Farrow-right.svg?alt=media&token=af9bc6cf-3578-4aba-bb39-e26574a326c7'*/}
        {/*      className='image-territory'*/}
        {/*  />*/}
        {/*</div>*/}

        {territories &&
            territories.map((territory, index) => {
              const isManzanillo = false;
              const isDisable = ['cd0790f3-da71-4762-b978-92272473577f', '3ca3f4d0-d67c-46e6-912e-90dfa91c3547', 'a03d86ac-9376-4db3-b4ca-a9fbf8b886bb', '26b09ee0-bce1-4020-8279-7e8b4372838f'].includes(territory?.territoryUuid || '');
              const disableClass = isDisable ? 'option-disabled-territory' : '';
              return (
                  <div
                      key={index}
                      className={`affil-territory-item-container`}
                      onClick={() => {
                        if (!isDisable) {
                          if (!isManzanillo) {
                            onSelectTerritory(territory);
                          }
                        }
                      }}
                  >
                    <div
                        className={`affil-territory-item ${
                            isManzanillo ? 'option-disabled-territory' : ''
                        } ${disableClass}`}
                    >
                      <label className='affil-territory-name'>
                        {territory.territoryName}
                      </label>

                      <img
                          src='https://firebasestorage.googleapis.com/v0/b/easyhop-ac384.appspot.com/o/resources%2Farrow-right.svg?alt=media&token=af9bc6cf-3578-4aba-bb39-e26574a326c7'
                          className='image-territory'
                      />
                    </div>
                    {/* <HopButton
                      onClick={() => {
                        onSelectTerritory(territory);
                      }}
                      look="primary"
                      content={">"}
                      className="buy-pass-button"
                    /> */}
                  </div>
              );
            })}

        <div
            onClick={() => {
              mixpanel.register({
                externalService: 'SHARED SHUTTLES IN OTHER TOWNS',
              });
              mixpanel.track('externalService', {
                Source: 'website',
                'new user': !isAuthenticated,
              });
              window.location.href = greenAirwaysUrl;
            }}
            className='affil-territory-item'
        >
          <label className='affil-territory-name'>Flights Costa Rica</label>
          {/* <img src={territory.picture} className="image-territory" /> */}
          <img
              src='https://firebasestorage.googleapis.com/v0/b/easyhop-ac384.appspot.com/o/resources%2Farrow-right.svg?alt=media&token=af9bc6cf-3578-4aba-bb39-e26574a326c7'
              className='image-territory'
          />
        </div>

        <div
            onClick={() => {
              mixpanel.register({
                externalService: 'SHARED SHUTTLES IN OTHER TOWNS',
              });
              mixpanel.track('externalService', {
                Source: 'website',
                'new user': !isAuthenticated,
              });
              window.location.href =
                  'https://www.interbusonline.com/apps/book/book.php?pinz=2319&pinx=731a4767f0b47f17c7c97a879db145e751022e8c&PickUpType=0&DropOffTYpe=1&Adult=1&Children=0';
            }}
            className='affil-territory-item'
        >
          <label className='affil-territory-name'>
            SHARED SHUTTLES IN OTHER TOWNS
          </label>
          {/* <img src={territory.picture} className="image-territory" /> */}
          <img
              src='https://firebasestorage.googleapis.com/v0/b/easyhop-ac384.appspot.com/o/resources%2Farrow-right.svg?alt=media&token=af9bc6cf-3578-4aba-bb39-e26574a326c7'
              className='image-territory'
          />
        </div>
        <div
            onClick={() => {
              mixpanel.register({
                externalService: 'RENT-A-CAR',
              });
              mixpanel.track('externalService', {
                Source: 'website',
                'new user': !isAuthenticated,
              });
              window.location.href =
                  'https://budget.co.cr/new-renting/?bcdNumber=C2340000';
            }}
            className='affil-territory-item'
        >
          <label className='affil-territory-name'>RENT A CAR</label>
          {/* <img src={territory.picture} className="image-territory" /> */}
          <img
              src='https://firebasestorage.googleapis.com/v0/b/easyhop-ac384.appspot.com/o/resources%2Farrow-right.svg?alt=media&token=af9bc6cf-3578-4aba-bb39-e26574a326c7'
              className='image-territory'
          />
        </div>
      </div>
    </AffilTerritoryStyles>
  );
};

export default AffilTerritoryPage;
