import { UseFormRegisterReturn } from 'react-hook-form';
import InputGroupStyles from './InputGroup.styles';

interface IInputGroup {
  className?: string;
  inputType: string;
  label: string;
  placeholder: string;
  registerProps?: UseFormRegisterReturn;
  errorMsg?: string;
  value?: string;
  autofocus?: boolean;
  onChange?: (e: React.ChangeEvent<HTMLInputElement>) => void;
}

export function InputGroup({
  className,
  inputType,
  label,
  placeholder,
  registerProps,
  errorMsg,
  value,
  autofocus = false,
  onChange,
}: IInputGroup): JSX.Element {
  return (
    <InputGroupStyles className={className}>
      <label className='label-5'>{label}</label>
      <input
        autoFocus={autofocus}
        defaultValue={value}
        placeholder={placeholder}
        type={inputType}
        {...registerProps}
        onChange={onChange}
      />
      {errorMsg && <p className='label-5 form-errors'>{errorMsg}</p>}
    </InputGroupStyles>
  );
}
