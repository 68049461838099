import styled from 'styled-components';
import { breakpoints } from 'theme/breakpoints';
import colors from 'theme/colors';

const CalendarSelectorStyles = styled.div`
  
.btn-action {
	border: solid 2px ${colors.dullBull};
	height: 39px;
    width: 180px;
    text-align: left;
    padding: 0 9px;
    background: white;
    border-radius: 5px;
    font-size: 18px;
    font-family: Spartan Medium, sans-serif;
    color: ${colors.dullBull};
    display: flex;
    justify-content: space-between;
    align-items: center;
}
.calendar-wrapper {
	position: relative;
}
.rdp {
	position: absolute;
	background-color: ${colors.white};
	right: 0;
	box-shadow: 1px 0px 6px 0px rgba(0,0,0,0.75);
	border-radius: 6px;
    border: solid 1px rgb(92 92 92 / 30%);
	&-caption_label {
	position: absolute;
	left: 0;
	right: 0;
	margin: auto;
    width: fit-content;
    padding: 10px 14px;
    
	}
	&-nav {
	    width: 100%;
    	display: flex;
    	justify-content: space-between;
    }
}
.rdp-day_selected, .rdp-day_selected:focus-visible, .rdp-day_selected:hover {
    background: white;
    color: ${colors.blueAzure};
    border-bottom: solid 2px;
    border-radius: 0;
}
  img {
    width: 24px;
  }
`
export default CalendarSelectorStyles;
