import { createSlice, PayloadAction } from '@reduxjs/toolkit';

export interface AppSliceState {
	isOverlayLoading?: boolean;
	language?: string;
	isSidebar?: boolean;
	showFooter?: boolean;
	flow?: string;
}

export const AppSlice = createSlice({
	name: 'app',
	initialState: {
		isOverlayLoading: false,
		language: 'en',
		isSidebar: false,
		showFooter: true,
		flow: '',
	} as AppSliceState,
	reducers: {
		setLoading: (state: AppSliceState, action: PayloadAction<boolean>) => {
			state.isOverlayLoading = action.payload;
		},

		setLanguage: (state: AppSliceState, action: PayloadAction<string>) => {
			state.language = action.payload;
		},

		setSidebar: (state: AppSliceState, action: PayloadAction<boolean>) => {
			state.isSidebar = action.payload;
		},

		setFooter: (state: AppSliceState, action: PayloadAction<boolean>) => {
			state.isSidebar = action.payload;
		},

		setFlow: (state: AppSliceState, action: PayloadAction<string>) => {
			state.flow = action.payload;
		},
	},
});

export const { setLoading, setLanguage, setSidebar, setFooter, setFlow } = AppSlice.actions;
