import styled from 'styled-components';
import { breakpoints } from 'theme/breakpoints';
import colors from 'theme/colors';
import { buttonsStyles } from 'theme/typography';

const LoginStyles = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 0 35px;
  justify-content: center;
  text-align: center;

  @media ${breakpoints.phone} {
    zoom: unset;
  }

  @media ${breakpoints.tablet} {
    margin: 0 auto;
    padding-top: 104px;
    max-width: 740px;
    zoom: 80%;
  }

  .login-easily {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
    @media ${breakpoints.tablet} {
    }
    label {
      width: 175px;
      @media ${breakpoints.tablet} {
        width: 100%;
      }
      line-height: 23px;
      text-align: left;
      color: #264653;
    }
    margin: 40px 0;
  }

  .login-buy-here-button {
    width: 163px !important;
    background-color:#FFCF70;
    max-height: 60px !important;
    line-height: 28px !important;
    font-size: 1.2rem !important;
    @media ${breakpoints.tablet} {
      margin-left: 30px;
      width: 340px !important;
    }
  }

  form {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .login-logo {
    width: 100px;
    @media ${breakpoints.tablet} {
      width: 130px;
      height: 105px;
      margin-bottom: 48px;
    }
  }

  .login-label-sign-up {
    text-decoration: underline;
  }

  h1 {
    margin-bottom: 14px;
    color: ${colors.blueDianne};
  }

  .login-new-label {
    margin-bottom: 40px;
    color: ${colors.blueDianne};
    cursor: pointer;
    a {
      font-weight: bold;
    }

    @media ${breakpoints.phone} {
      margin-bottom: 20px;
    }
  }

  .login-form {
    width: 100%;
    margin-top: 50px;
    margin-bottom: 35px;
    @media ${breakpoints.phone} {
    }
    label {
      text-align: left;
    }
  }

  .login-forgot-label {
    cursor: pointer;
    margin-top: 10px;
    color: ${colors.blueDianne};
    text-decoration: underline;

    @media ${breakpoints.tablet} {
      text-align: right;
      padding-right: 27px;
    }
  }

  .login-button {
    width: 164px;
    cursor: pointer;
    max-height: 60px !important;
    line-height: 28px !important;
    font-size: 1.2rem !important;

    @media ${breakpoints.phone} {
    }

    &:last-of-type {
      margin-bottom: 0;
    }

    @media ${breakpoints.tablet} {
      width: 421px;
    }
  }
`;

export default LoginStyles;
