import styled from 'styled-components';
import colors from '../../../theme/colors';

const AffiliationItemInfoStyles = styled.div`
.item-wrapper{
  align-content: space-between;
    display: flex;
    justify-content: space-evenly;
    width: 70%;

}
.column1Item{
  width: 130px ;
  max-width: 130px !important;
  text-align: initial;

}
.column2Item{
  width: 90px ;
  align-self: center;
}
  .wrapper {
    display: flex;
    align-items: center;
    justify-content: center;
    background: #e1e7ea;

    padding: 20px;
    flex-direction: column;
    gap: 10px;
    position: relative;
  }

  .title {
    color: #447c93;
  }

  .affiliation-item-info-members {
    font-weight: bolder;
    color: #264653;
  }

  .affiliation-item-info-price-1 {
    font-weight: bolder;
    color: #264653;
  }

  .affiliation-item-info-price-2 {
    font-weight: bolder;
    color: #264653;
  }

  .affiliation-item-info-to-pay {
    font-weight: bolder;
    color: #264653;
  }

  .affiliation-item-total-payment {
    font-family: Spartan Bold;
    font-weight: bolder;
    color: #264653;
  }
  .label4Wrapper{
    padding-top: 10px;
  }
  .label-4 {
    font-size: 20px;
  }
  .label-2 {
    font-size: 16px;
  }
  .label-3 {
    font-size: 16px;
    &.f-24 {
      font-size: 24px;
    }
    &.regular-price {
      text-decoration: line-through;
      opacity: 0.75;
    }
  }
  .total-section {
    display: flex;
    /* justify-content: center;
    align-items: center;
    gap: 5px; */
    &--label {
      font-family: Spartan Bold, sans-serif;
      font-size: 18px;
      max-width: 120px;
      line-height: 17px;
    }
  }

  .stop_detail {
    display: flex;
    gap: 10px;
    margin-bottom: 2rem;

    &--edit_btn {
      font-style: normal;
      font-family: Spartan Bold, sans-serif;
      font-weight: 600;
      font-size: 1rem;
      line-height: 13px;
      display: flex;
      align-items: center;
      text-align: right;
      text-decoration-line: underline;
      color: ${colors.blueDianne};
      position: absolute;
      bottom: 16px;
      right: 15px;
    }
    &--time {
      font-size: 28px;
      font-family: Spartan Bold, sans-serif;
      color: ${colors.blueDianne};
    }
    &--details {
      display: flex;
      flex-direction: column;
      label {
        font-family: Spartan Regular, sans-serif;
        color: ${colors.blueDianne};
      }
      &__from {
        font-size: 20px;
        font-family: Spartan Semibold, sans-serif;
        font-weight: bold;
      }
      &__to {
        text-decoration: underline;
      }
    }
    &--trash {
      align-self: flex-end;
    }
  }
  .edit-btn {
    position: absolute;
    bottom: 20px;
    right: 25px;
    color: ${colors.blueDianne};
    font-size: 12px;
    font-family: Spartan Semibold, sans-serif;
    font-weight: bold;
  }
`;

export default AffiliationItemInfoStyles;
