import styled from 'styled-components';

const TextDividerStyles = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  label {
    font-size: 16px;
    color: #6b8995;
    margin: 0px 15px;
  }

  .login-line {
    width: 65px;
    background: #6b8995;
    height: 2px;
  }
`;

export default TextDividerStyles;
