import { breakpoints } from './breakpoints';

export const buttonsStyles = {
	verticalMarginSpacing: '27px 0px 0px 0px;',
};

export const typography = `
	body {
	    font-family: Spartan Regular;
	}
  .title-1 {
    font-family: Spartan Bold;
    font-style: normal;
    font-weight: 700;
    font-size: 64px;
    line-height: 59px;
  }

  .title-2 {
    font-family: Spartan Bold;
    font-style: normal;
    font-weight: 700;
    font-size: 24px;
    line-height: 28px;
  }

  .title-3 {
    font-family: Spartan Medium;
    font-style: normal;
    font-weight: 400;
    font-size: 20px;
    line-height: 18px;
  }

  .subtitle-1 {
    font-family: Spartan Medium;
    font-style: normal;
    font-weight: 500;
    font-size: 18px;
    line-height: 17px;
  }

  .label-1 {
    font-family: Spartan Regular;
    font-style: normal;
    font-weight: 400;
    font-size: 32px;
    line-height: 30px;
  }

  .label-2 {
    font-family: Spartan Regular;
    font-style: normal;
    font-weight: 400;
    font-size: 24px;
    line-height: 22px;
  }

  .label-3 {
    font-family: Spartan Regular;
    font-style: normal;
    font-weight: 400;
    font-size: 1.4rem;
    line-height: 16px;
  }

  .label-4 {
    font-family: Spartan Medium, sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 18px;
    line-height: 14px;
  }

  .label-5 {
    font-family: Spartan Regular;
    font-style: normal;
    font-weight: 400;
    font-size: 1.4rem;
    line-height: 32px;
    
    @media ${breakpoints.tablet} {
      font-size: 1.8rem;
      line-height: 35px;
    }
  }

  .paragraph {
    font-family: Spartan Regular;
    font-style: normal;
    font-weight: 400;
    font-size: 1.4rem;
    line-height: 30px;
  }

  .small-paragraph {
    font-family: Spartan Regular;
    font-style: normal;
    font-weight: 400;
    font-size: 1rem;
    line-height: 11px;
  }
`;
