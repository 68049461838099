import styled from 'styled-components';
import { breakpoints } from 'theme/breakpoints';
import colors from "../../../theme/colors";

const ItemInfoStyles = styled.div`
	.iteminfo-container {
		display: flex;
		align-items: center;
		padding: 10px 37px;
		width: 100%;
		justify-content: left;
		background: #e0f7f7;
		min-height: 115px;

		@media ${breakpoints.tablet} {
			justify-content: center;
			align-items: center;
			width: unset;
			padding: 15px 24px;
		}

		.iteminfo-avatar {
			width: 70px;
			height: 70px;
			border-radius: 100%;
			@media ${breakpoints.tablet} {
				width: 128.8px;
				height: 128.8px;
			}
		}

		.iteminfo-user-edit {
			cursor: pointer;
			position: relative;
			left: 12px;
			@media ${breakpoints.tablet} {
				left: 48px;
			}
		}

		.iteminfo-user {
			display: flex;
			align-items: flex-start;
			justify-content: left;
			flex-direction: column;
			width: 100%;
			gap: 12px;
            padding: 0 50px;
			label {
				text-align: left;
                font-size: 18px;
                color: ${colors.blueDianne};
			}

			@media ${breakpoints.tablet} {
				justify-content: center;
				align-items: flext-start;
				width: unset;
				gap: 6px;
			}
		}

		.iteminfo-avatar-container {
			margin-right: 24px;
			display: flex;
			flex-direction: column;
			align-items: flex-start;
			justify-content: center;
			width: 80px;

			@media ${breakpoints.tablet} {
				width: 130px;
			}
		}
	}
`;

export default ItemInfoStyles;
