import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { TicketDataModel } from 'shared/models';

export interface TicketSliceState {
	ticket?: TicketDataModel.ITicket;
	buyTicket?: TicketDataModel.IBuyTicketResponse;
}

export const TicketSlice = createSlice({
	name: 'app',
	initialState: {
		ticket: undefined,
		buyTicket: undefined,
	} as TicketSliceState,
	reducers: {
		setTicket: (state: TicketSliceState, action: PayloadAction<TicketDataModel.ITicket>) => {
			state.ticket = action.payload;
		},
		setBuyTicket: (state: TicketSliceState, action: PayloadAction<TicketDataModel.IBuyTicketResponse>) => {
			state.buyTicket = action.payload;
		},
	},
});

export const { setTicket, setBuyTicket } = TicketSlice.actions;
