import logoLgAsset from 'asset/logo-eh.png';
import {HopButton} from 'components/global/HopButton/HopButton';
import {FC, ReactElement, useEffect} from 'react';
import TempStyles from './Temp.style';

interface IFormLogin {
    email: string;
    ticket: string;
}

const TempPage: FC = (): ReactElement => {

    const handleBackClick = () => {
        window.location.href = 'https://www.easyhop.co/';
    }


    return (<TempStyles>
            <img className='login-logo' src={logoLgAsset} alt='Easy Hop Logo'/>
            <div className='login-easily'>
                <h1>Gracias por visitar Easy Hop.</h1>
                <label className='label-4 mb-5'>En este momento, nuestro servicio está en mantenimiento y no estamos
                    operando. Te mantendremos informado a través de nuestras redes sociales y nuestra página web sobre
                    la reanudación de nuestras operaciones.
                </label>
                <label className='label-4 mb-5'>¡Gracias por tu comprensión y paciencia!
                </label>
                <HopButton
                    className='login-buy-here-button'
                    look='secondary'
                    content={'Go Back'}
                    type='button'
                    onClick={handleBackClick}
                />
            </div>
        </TempStyles>);
};

export default TempPage;
