import { setFlowAction, toogleSidebar } from 'app.action';
import { Footer } from 'components/global/Footer/Footer';
import { Sidebar } from 'components/global/Sidebar/Sidebar';
import { Toolbar } from 'components/global/Toolbar/Toolbar';
import TopContent from 'components/global/TopContent/TopContent';
import { setPassengersQtyAction, setTerritoryAction } from 'modules/Affiliation';
import { useCallback, useEffect } from 'react';
import { Toaster } from 'react-hot-toast';
import LoadingOverlay from 'react-loading-overlay';
import { useDispatch, useSelector } from 'react-redux';
import { useRoutes, useSearchParams } from 'react-router-dom';
import PulseLoader from 'react-spinners/PulseLoader';
import { RootState, setAuth } from 'store';
import GlobalStyle from './App.styles';
import { routes } from './router/app.router';
import './shared/ehi18n/i18n';
import i18n from './shared/ehi18n/i18n';
import TempPage from "./temp/Temp.page";

const App = (props: any) => {
	const routing = useRoutes(routes);
	const dispatch = useDispatch();
	const [searchParams] = useSearchParams();

	const territory = JSON.parse(localStorage.getItem('territory') || '{}');
	const userLocal = JSON.parse(localStorage.getItem('user') || '{}');
	const passengerQty = Number(localStorage.getItem('passengerQty') || '1');
	const flow = localStorage.getItem('flow') || '';

	useEffect(() => {
		setStorageToState();
		const param = Array.from(searchParams.entries());
		if (param && param.length > 0) {
			if (param[0][0] === 'lang') {
				i18n.changeLanguage(param[0][1]);
			}
		}
	}, []);

	const { isOverlayLoading, isSidebar, showFooter } = useSelector((state: RootState) => state.app);
	const { isAuthenticated, user } = useSelector((state: RootState) => state.auth);

	const toogleAppSideBar = useCallback(() => {
		dispatch(toogleSidebar(true));
	}, []);

	const setStorageToState = () => {
		dispatch(setTerritoryAction(territory));
		dispatch(setFlowAction(flow));
		dispatch(setPassengersQtyAction(passengerQty));
		dispatch(setAuth(userLocal));
	};

	return (
		<div className="page-container">
			{isOverlayLoading && (
				<LoadingOverlay {...props} active={isOverlayLoading} spinner={<PulseLoader color="#33CCCC"/>}/>
			)}
			<GlobalStyle/>
			<TempPage />
			{/*<TopContent />		*/}
			{/*{isAuthenticated && <Sidebar isOpen={isSidebar} username={user?.fullName} userid={user?.userId} />}*/}
			{/*{isAuthenticated && user && <Toolbar label={user?.fullName} onMenuClick={toogleAppSideBar} />}*/}
			{/*<div className="pages-content">{routing}</div>*/}

			{/*{showFooter && <Footer />}*/}
			{/*<Toaster*/}
			{/*	position="top-center"*/}
			{/*	reverseOrder={false}*/}
			{/*	containerStyle={{*/}
			{/*		fontFamily: 'Spartan Regular',*/}
			{/*		fontWeight: '400',*/}
			{/*		fontSize: '1rem',*/}
			{/*		alignItems: 'center',*/}
			{/*		margin: '0px',*/}
			{/*		top: '0'*/}
			{/*	}}*/}
			{/*	toastOptions={{*/}
			{/*		error:{*/}
			{/*			style: {*/}
			{/*				background: 'red',*/}
			{/*				width: '100vw',*/}
			{/*				color: 'white',*/}
			{/*				borderRadius: '2px',*/}
			{/*				fontSize: '20px'*/}
			{/*			}*/}
			{/*		}*/}
			{/*	}}*/}
			{/*/>*/}
		</div>
	);
};
export default App;
