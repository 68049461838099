import PaypalButtonStyles from './PaypalButton.styles';
import { PayPalButtons } from '@paypal/react-paypal-js';
import { useCallback } from 'react';

const PaypalButton = (props: any): JSX.Element => {
  const {
    purchaseDescription,
    amount,
    handleOnApprove,
    handleOnError,
    handleOnCancel,
    handleOnClick,
    readyToPay,
  } = props;

  const createOrder = useCallback(
    (data: any, actions: any) => {
      return actions.order.create({
        purchase_units: [
          {
            description: purchaseDescription,
            amount: {
              value: amount,
            },
          },
        ],
      });
    },
    [amount, readyToPay]
  );

  return (
    <PaypalButtonStyles>
      <PayPalButtons
        // @ts-ignore
        forceReRender={createOrder}
        style={{
          tagline: false,
          shape: 'pill',
          layout: 'horizontal',
        }}
        onClick={() => {
          handleOnClick();
        }}
        createOrder={createOrder}
        onApprove={async (data, actions) => {
          const order = await actions.order?.capture();
          handleOnApprove(order);
        }}
        onCancel={() => handleOnCancel()}
        onError={(error) => {
          handleOnError(error);
        }}
        disabled={!readyToPay}
      />
    </PaypalButtonStyles>
  );
};

export default PaypalButton;
