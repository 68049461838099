import { RouteObject } from 'react-router';
import RoutesMapPage from './Map/RoutesMap.page';
import RoutesReservationPage from './Reservation/RoutesReservation.page';
import ReservationConfirmPage from './ReservationConfirm/ReservationConfirm.page';

export const ReservationMapRoutes: Array<RouteObject> = [
	{
		path: '/routes-map',
		element: <RoutesMapPage />,
	},
	{
		path: '/routes-reservation',
		element: <RoutesReservationPage />,
	},
	{
		path: '/routes-confirmation',
		element: <ReservationConfirmPage />,
	},
];
