import { HopButton } from 'components/global/HopButton/HopButton';
import { InputGroup } from 'components/global/InputGroup/InputGroup';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router';
import { RootState } from 'store';
import AffilRegisterStyles from './AffilRegister.styles';

const AffilRegister = (): JSX.Element => {
	const { ticketCode } = useSelector((state: RootState) => state.affiliation);
	const { user } = useSelector((state: RootState) => state.auth);
	const navigate = useNavigate();
	const { t } = useTranslation();

	const goToIdForm = () => {
		navigate('/registry-finish');
	};
	

	const onCancel = () => {
		navigate(-1);
		
	};

	return (
		<AffilRegisterStyles>
			<h1 className="title-2 affil-register-title">{t('membershipConfirmation')}</h1>
			<label className="affil-register-ticket label-4">{t('ticketNumber')}</label>
			<h1 className="title-2 affil-register-title">#{ticketCode}</h1>
			<div className="affil-register-form">
				<InputGroup
					label={t('ticketNumber')}
					inputType="text"
					placeholder={t('ticketNumberPlaceholder')}
					value={ticketCode}
				/>
				<InputGroup
					value={user?.fullName}
					label={t('fullName')}
					inputType="text"
					placeholder={t('fullNamePlaceholder')}
				/>
				<InputGroup value={user?.userEmail} label={t('email')} inputType="text" placeholder={t('emailPlaceholder')} />
				<InputGroup
					label={t('phoneNumber')}
					inputType="text"
					placeholder={t('phoneNumberPlaceholder')}
					value={user?.phoneNumber}
				/>
			</div>
			<div className="affil-register-actions">
				<HopButton onClick={goToIdForm} look="primary" content={t('continue')} />
				<HopButton onClick={onCancel} look="secondary" content={t('back')} />
			</div>
		</AffilRegisterStyles>
	);
};

export default AffilRegister;
