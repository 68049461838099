import { HopButton } from 'components/global/HopButton/HopButton';
import { ItemInfo } from 'components/global/ItemInfo/ItemInfo';
import { QRView } from 'components/global/QRView/QRView';
import { FC, ReactElement, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { TicketService } from 'shared/services';
import { LocalStorageService } from 'shared/services/localStorage.service';
import { RootState, setTicket, setTicketItinerary } from 'store';
import { ProfileService } from '../profile.service';
import ProfileDashboardStyles from './ProfileDashboard.styles';

// interface IProfile {}

const ProfilePage: FC = (): ReactElement => {
	const dispatch = useDispatch();
	const navigate = useNavigate();
	let printContent: any;

	const { user } = useSelector((state: RootState) => state.auth);
	const { ticket } = useSelector((state: RootState) => state.ticket);
	const { time, pickupPoint, returnPoint } = useSelector((state: RootState) => state.itinerary);
	const { t } = useTranslation();

	useEffect(() => {
		localStorage.setItem('flow', 'login');
		if (user) {
			getTicketByNumber();
			getTicketItineraryByTicketUuid();
		}
	}, [user]);

	const goToBuyMembership = () => {
		navigate('/affiliation-territory');
	};

	const goToMyProfile = () => {
		navigate('/profile-update');
	};

	const downloadMembership = () => {
		// createPdfFromHtml(printContent);

		ProfileService.getPdfTicket(user, ticket).then((response: any) => {
			// console.log("THIS IS THE RESPONSE FROM MAIN==>",response)
			const url = window.URL.createObjectURL(new Blob([response.data]));
			const link = document.createElement('a');
			link.href = url;
			link.setAttribute('download', 'file.pdf'); //or any other extension
			document.body.appendChild(link);
			link.click();
		});
	};

	const getTicketByNumber = async () => {
		try {
			const ticketCodeFromBuyFlow = LocalStorageService.getLocalStorage()?.ticketResult?.ticket?.uuid;
			const ticketCodeFromLoginFlow = user?.ticketNumber ? user?.ticketNumber : '';
			const ticketCode = ticketCodeFromBuyFlow ? ticketCodeFromBuyFlow : ticketCodeFromLoginFlow;
			let result;

			if (process.env.REACT_APP_EHP_ENV === 'DEV') {
				result = await TicketService.getTicketByNumberV2(ticketCode);
			} else {
				result = await TicketService.getTicketByNumber(ticketCode);
			}

			if (!ticket) {
				dispatch(setTicket(result));
			}
		} catch (e) {
			console.log(e);
		}
	};

	const getTicketItineraryByTicketUuid = async () => {
		try {
			const ticketCodeFromBuyFlow = LocalStorageService.getLocalStorage()?.ticketResult?.ticket?.uuid;
			const ticketCodeFromLoginFlow = user?.ticketNumber ? user?.ticketNumber : '';
			const ticketCode = ticketCodeFromBuyFlow ? ticketCodeFromBuyFlow : ticketCodeFromLoginFlow;

			let result: any;
			if (process.env.REACT_APP_EHP_ENV === 'DEV') {
				result = await TicketService.getTicketItineraryByTicketV2(ticketCode);
			} else {
				result = await TicketService.getTicketItineraryByTicket(ticketCode);
			}
			console.log(result);
			if (result) {
				const tempTime = result.time.split(':');
				result.time = `${tempTime[0]}:${tempTime[1]}`;
				dispatch(setTicketItinerary(result));
			}
		} catch (e) {
			console.log(e);
		}
	};

	const onBuyCredit = async () => {
		try {
			navigate('/affiliation-territory');
		} catch (e) {
			console.log(e);
		}
	};

	return (
		<ProfileDashboardStyles>
			<div
				ref={(el) => {
					printContent = el;
				}}>
				<h1 className="title-2">{t('myProfile')}</h1>
				<ItemInfo
					name={user?.fullName}
					id={user?.userId}
					email={user?.userEmail}
					userType={user?.isNational ? 'nacional' : 'extranjero'}
				/>
				{!ticket && (
					<div className="buy-membership-container">
						<label className="label-4">{t('noMembershipt')}</label>
						<HopButton
							className="memb-button"
							look="primary"
							content={t('buyCredit')}
							type="button"
							onClick={goToBuyMembership}
						/>
					</div>
				)}
				{ticket && (
					<div className="ticket-membership-container">
						<QRView
							maxTrips={ticket.usesAllowed}
							currentTrips={ticket.usesAvailable}
							expirationDate={ticket.expirationDate}
							qr={ticket.ticketNumber}
							members={ticket.personNumber}
							uuid={ticket.uuid}
							passengersQty={ticket.personNumber}
							territoryName={ticket.territoryName}
							qr_ticket_user={ticket?.qrUrl}
						/>

						<br />
						<h1 className="title-2"> {t('itinerary')}</h1>
						<div className="itineraryClass">
							{''}
							<span className="itineraryClassTimeAndPickup">
								{time} from {pickupPoint}{' '}
							</span>{' '}
							<br /> <span className="itineraryClassReturn">to {returnPoint}</span>
						</div>

						<HopButton
							className="memb-button"
							look="secondary"
							content={t('checkSchedule')}
							type="button"
							onClick={() =>
								window.open(
									'https://uploads-ssl.webflow.com/624faa926cf8da07fc2d9486/634f470366d5388d1020bd9e_mapa-schedules-ingles.pdf',
									'_blank'
								)
							}
						/>
						{/* <HopButton
							className="memb-button"
							look="secondary"
							content={t('downloadMembership')}
							type="button"
							onClick={downloadMembership}
						/> */}
						<HopButton
							className="memb-button"
							look="secondary"
							content={t('buyCredit')}
							type="button"
							onClick={onBuyCredit}
						/>

						{/* <Itinerary /> */}
					</div>
				)}
				<div className="buy-membership-container">
					<HopButton
						className="memb-button"
						look="primary"
						content={t('editMyProfile')}
						type="button"
						onClick={goToMyProfile}
					/>
				</div>
			</div>
		</ProfileDashboardStyles>
	);
};

export default ProfilePage;
