import styled from 'styled-components';
import colors from 'theme/colors';
import {breakpoints} from "theme/breakpoints";

interface IHopRadioButtonStyles {
	active: boolean;
}

const HopRadioButtonStyles = styled.button<IHopRadioButtonStyles>`
  padding-left: 10px;
  margin-bottom: 7px;
  display: flex;
  background: transparent;
  border: 0;
  align-items: center;
  padding: 3px 0 1px 28px;
  position: relative;
  cursor: pointer;
  text-align: left;

  &:before {
    content: "";
    width: 16px;
    height: 16px;
    border: 2px solid
    
      ${({ active }) => (active ? colors.blueTurquoise : colors.grayTower)};
    border-radius: 20px;
    border-color: ${({ active }) => (active ? colors.blueTurquoise : "#000")};
    position: absolute;
    left: 0;
    top: 50%;
    transform: translateY(-50%);
  }

  &:after {
    content: "";
    width: 10px;
    height: 10px;
    background-color: ${({ active }) =>
      active ? colors.blueTurquoise : colors.white};
    border-radius: 20px;
    position: absolute;
    left: 5px;
    top: 50%;
    transform: translateY(-50%);
  }

  & a,
  & p {
    color: ${colors.blueDianne};
    line-height: 1;
    font-size: 16px;
    @media (${breakpoints.smallPC}) {
		font-size: 29px;
    }
  }
`;

export default HopRadioButtonStyles;
