import ehpAxios from 'shared/axios/axios.service';

export const getCommerceByCodeUr = async (urlShorcut: string): Promise<any> => {
	try {
		const result = await ehpAxios.get(`/commerce/urlCode/${urlShorcut}`);
		return result.data;
	} catch (e) {
		throw e;
	}
};
