import { FirebaseStorageService } from 'shared/firebase/firebase-storage.service';
import {
  cleanMember,
  cleanPassword,
  cleanUserId,
  setAuth,
  setCommerceUuid,
  setMember,
  setPassword,
  setUserId,
} from 'store';
import {
  MembRegistryDataModel,
  MembRegistryDataModel as Model,
} from './MembRegister.model';
import { RegistryService } from './MembRegister.service';

export const registerMember = (data: Model.IRegistryMemberState) => {
  return setMember(data);
};

export const resetMember = () => {
  return cleanMember();
};

export const registerPassword = (data: Model.IRegistryPasswordState) => {
  return setPassword(data);
};

export const resetPassword = () => {
  return cleanPassword();
};

export const registerId = async (data: Model.IRegistryIdState) => {
  try {
    let fileUploadResult;
    if (data.file) {
      fileUploadResult = await FirebaseStorageService.uploadFile(
        data.file,
        FirebaseStorageService.FB_STORAGE_PATHS.PASSENGERS
      );
      data.fileUrl = fileUploadResult;
    }

    return setUserId({
      fileUrl: data.fileUrl,
      id: data.id,
    });
  } catch (e) {
    throw e;
  }
};

export const setCommerceUuidAction = (commerceUuid: string) => {
  return setCommerceUuid(commerceUuid);
};

export const resetId = () => {
  return cleanUserId();
};

export const registerUserEHPFull = async (
  data: MembRegistryDataModel.IEHUser
) => {
  try {
    const ehpResult = await RegistryService.registerUserEHP(
      data.userId,
      data.userEmail,
      data.userEmail,
      '',
      data.isNational,
      data.fullName,
      data.billToAddress,
      data.billToCity,
      data.billToState,
      data.billToZipPostCode,
      data.billToCountry,
      data.phoneNumber
    );
    data.uuid = ehpResult.uuid;

    return data;
  } catch (e) {
    throw e;
  }
};

export const authenticateRegisteredUser = async (
  data: MembRegistryDataModel.IEHUser
) => {
  try {
    return setAuth({
      user: data,
      isAuthenticated: true,
    });
  } catch (e) {
    throw e;
  }
};
