import styled from 'styled-components';
import { breakpoints } from 'theme/breakpoints';
import colors from 'theme/colors';

const TimeSelectorStyles = styled.div`
  .seats {
    font-family: Spartan Regular, sans-serif;
    font-size: 12px;
    line-height: 24px;
    color: ${colors.grayJuniper};
  }
  .btn {
    font-family: Spartan Regular, sans-serif;
    font-size: 16px;
    border: 2px solid ${colors.sanctuarySpa};
    background: ${colors.white};
    color: ${colors.sanctuarySpa};
    max-width: 45px;
    &.active {
      background: ${colors.sanctuarySpa};
      color: ${colors.white};
      
    }
    &[disabled] {
      background-color: ${colors.white};
      border: 2px solid ${colors.orochimaru};
      color: ${colors.orochimaru};
    }
  }

  .option_label {
    color: ${colors.sanctuarySpa};
    font-size: 20px;
    font-family: Spartan Semibold, sans-serif;
    display: flex;
    justify-content: space-between;
    align-items: baseline;
    font-weight: bold;
    margin-left: 30px;
    &--seat {
      font-family: Spartan Regular, sans-serif;
      font-size: 14px;
      color: ${colors.grayJuniper};
      /* color: white; */
    }
    &.active {
      background: ${colors.blueDianne};
      color:white;
    }
    &.disabled {
      background: none;
      color: #b7b8b8;
      .option_label--seat {
        color: #b7b8b8;
      }
    }
  }
  .Time__control {
    border: solid 2px ${colors.dullBull};
    line-height: 24px;
  }
  .Time__indicator-separator {
    display: none;
  }
  .Time__single-value {
    .option_label {
      color: ${colors.blueDianne};

      &--seat {
        display: none;
      }
    }
  }
  .Time__option--is-selected {
    background: ${colors.blueDianne};
    color: #FFFF;
    .option_label {
      color: ${colors.white};
      &--seat {
        color: ${colors.white};
      }
    }
  }
`;

export default TimeSelectorStyles;
