import { createUserWithEmailAndPassword, getAuth, UserCredential } from 'firebase/auth';
import { collection, doc, getFirestore, setDoc } from 'firebase/firestore';
import moment from 'moment';
import { EHFirebaseApp } from 'shared';
import ehpAxios from 'shared/axios/axios.service';
import { MembRegistryDataModel as Model } from './MembRegister.model';

export module RegistryService {
	export const createUserWithCredentials = async (data: Model.IFBRegistryRequest): Promise<UserCredential> => {
		try {
			const firebaseAuth = getAuth(EHFirebaseApp);
			const result = await createUserWithEmailAndPassword(firebaseAuth, data.email!, data.password!);
			return result;
		} catch (e) {
			throw e;
		}
	};

	export const saveUserFirebase = async (data: Model.IFBRegistryRequest) => {
		try {
			const firestore = getFirestore(EHFirebaseApp);
			const userRef = collection(firestore, 'Users');

			const myDoc: any = {
				DNI: data?.id || '',
				idType: data?.idType || '',
				picture: data?.fileUrl ? data?.fileUrl : '',
				fullName: data.fullName || '',
				type: 'PASAJERO',
				firebaseId: data.firebaseId || '',
				email: data.email || '',
				phoneNumber: data.phone || '',
				ticket: data.ticket || '',
				uuid: data.uuid || '',
				status: data.status || '',
				created: new Date(),
			};
			console.log('registring user in firestore', myDoc);
			const myReference = doc(userRef, data.firebaseId);
			await setDoc(myReference, myDoc);

			return myDoc;
		} catch (e) {
			throw e;
		}
	};

	export const registerUserEHP = async (
		firebaseId?: string,
		userEmail?: string,
		userName?: string,
		userPassword?: string,
		isNational?: boolean,
		fullName?: string,
		billToAddress?: string,
		billToCity?: string,
		billToState?: string,
		billToZipPostCode?: string,
		billToCountry?: string,
		phoneNumber?: string
	): Promise<Model.IEHRegistryResponse> => {
		try {
			const result = await ehpAxios.post('/user', {
				firebaseId: firebaseId,
				userId: firebaseId,
				fullName,
				userEmail,
				userName,
				userPassword,
				isNational,
				userType: 'PASAJERO',
				createdAt: moment(new Date()).format('YYYY-MM-DD HH:mm:ss'),
				billToAddress,
				billToAddress2: billToAddress,
				billToCity,
				billToState,
				billToZipPostCode,
				billToCountry,
				phoneNumber
			});

			return result.data;
		} catch (e) {
			console.log(e);
			throw e;
		}
	};
}
