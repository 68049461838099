import styled from 'styled-components';
import { breakpoints } from 'theme/breakpoints';
import colors from 'theme/colors';
import { buttonsStyles } from 'theme/typography';

const MembFinishStyles = styled.div`
	max-width: 390px;
	margin: 0 auto;
	padding-top: 48px;
	background-color: ${colors.white};

	@media ${breakpoints.phone} {
		zoom: unset;
		padding: 30px 35px 0;
	}

	@media ${breakpoints.tablet} {
		padding-top: 104px;
		max-width: 640px;
		zoom: 80%;
	}

	.memb-finish-container {
		padding: 0 0px;
	}

	.memb-finish-title {
		color: ${colors.blueDianne};
		margin-bottom: 35px;
		text-align: center;
	}

	.memb-finish-subtitle {
		color: ${colors.grayJuniper};
		margin-bottom: 21px;
	}

	.memb-finish-form {
		margin-bottom: 20px;
		display: flex;
		flex-direction: column;
		align-items: center;
	}

	.memb-finish-photo-container {
		display: flex;
		align-items: center;
		margin: 20px 0px;

		@media ${breakpoints.tablet} {
			margin: 36px 0px;
		}

		p {
			line-height: 1.2;
			color: ${colors.blueDianne};
			padding: 0 20px;
		}
	}

	.memb-finish-photo {
		width: 104px;
		height: 104px;
		border-radius: 100%;
	}

	.memb-finish-photo-action {
		margin: 0 auto 35px !important;
	}

	.memb-button {
		margin: ${buttonsStyles.verticalMarginSpacing}
		width: 210px;
		cursor: pointer;
		font-size:1.2rem;
		line-height:28px;
		max-height: 60px !important;

		@media ${breakpoints.phone} {
			width: 250px;
		}

		&:last-of-type {
			margin-bottom: 0;
			margin-left: 10px;
		}

		@media ${breakpoints.tablet} {
			width: 421px;
			padding: 12px 10px;
		}
	}

	.memb-terms-condition-container {
		@media ${breakpoints.tablet} {
			margin: 52px 0px;
		}

		display: flex;
		align-items: center;
		justify-content: center;
		flex-direction: column;
	}

	.form-errors {
		margin-top: 10px;
		text-align: center;
		color: ${colors.orangeOutrageous};
	}

	.memb-finish-terms {
		width: 320px;
		margin-bottom: 10px;
	}

	.memb-finish-actions {
		display: flex;
		justify-content: space-between;
	}
`;

export default MembFinishStyles;
