import styled from 'styled-components';
import { breakpoints } from 'theme/breakpoints';
import { buttonsStyles } from 'theme/typography';

const ProfileStyles = styled.div`
	text-align: center;
	@media ${breakpoints.tablet} {
		text-align: center;
	}

	.title-2 {
		margin-bottom: 20px;
        font-size: 30px;
        font-family: Spartan Regular;
        text-transform: capitalize;
        margin-top: 6px;
        letter-spacing: 1px;
	}

	.buy-membership-container {
		width: 100%;
		text-align: center;
		justify-content: center;
		display: flex;
		flex-direction: column;
		align-items: center;
		margin-top: 20px;

		label {
			margin-bottom: 20px;
		}
	}

	.ticket-membership-container {
		width: 100%;
		text-align: center;
		justify-content: center;
		display: flex;
		flex-direction: column;
		align-items: center;
		margin-top: 20px;
	}

	.itineraryClass {
    font-size: 18px;
	}

	.itineraryClassTimeAndPickup {
		font-family: Spartan Bold,sans-serif;
    font-weight: 700;
	}

	.itineraryClassReturn {
		margin-left: 12px;
	}


	.memb-button {
		margin: ${buttonsStyles.verticalMarginSpacing}
		width: 210px;
		cursor: pointer;
		font-size:1.2rem;
		line-height:28px;
		max-height: 60px !important;

		@media ${breakpoints.phone} {
			width: 250px;
		}

		&:last-of-type {
			margin-bottom: 0;
		}

		@media ${breakpoints.tablet} {
			width: 421px;
			padding: 12px 10px;
		}
	}
`;

export default ProfileStyles;
