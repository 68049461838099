export namespace TicketDataModel {
	export type ITicketCode = string;

	export interface ITicket {
		uuid?: string;
		territory?: string;
		territoryName?: string;
		category?: string;
		categoryName?: string;
		categoryType?: string;
		categoryTypeName?: string;
		ticketNumber?: string;
		purchaseDate?: string;
		expirationDate?: string;
		usesAllowed?: number;
		dailyUsesAllowed?: number;
		price?: number;
		discount?: number;
		personNumber?: number;
		finalPrice?: number;
		usesAvailable?: number;
		qrUrl?: string;
	}

	export interface ITicketItinerary {
		time?: string;
		pickupPoint?: string;
		returnPoint?: string;
	}

	export interface IBuyTicketResponse {
		firebaseId?: string;
		commerce?: string;
		ticket?: ITicket;
		user?: IBuyTicketUserResponse;
	}

	export interface IBuyTicketUserResponse {
		uuid?: string;
		firebaseId?: string;
	}

	export interface IBuyTicketRequest {
		firebaseId?: string;
		commerce?: string;
		ticket?: IBuyTicketDetail;
		payment?: IBuyTicketPayment;
		user?: IBuyTicketUser;
	}

	export interface IBuyTicketUser {
		firebaseId?: string;
		name?: string;
		email?: string;
		phone?: string;
		uuid?: string;
	}

	export interface IBuyTicketDetail {
		territory?: string;
		category?: string;
		categoryType?: string;
		personNumber?: number;
		ticketNumber?: string;
		status?: string;
		useDate?: Date;
		pickUpPoint?: string;
		returnPoint?: string;
		ticketType?: string;
		parkTickets?:Array<any>;
		ticketsPrice?:number;
	}

	export interface IBuyTicketPayment {
		status?: I_BUY_TICKET_STATUS;
		amount?: number;
		activityCode?: string;
		date?: string;
		time?: string;
		operation?: string;
		type?: string;
		txAmount?: number;
		flow?: string;
	}

	export enum I_BUY_TICKET_STATUS {
		APPROVED = 'APPROVED',
		CANCELED = 'CANCELED',
		DENIED = 'DENIED',
	}
}
