import {
	FacebookAuthProvider,
	getAuth,
	GoogleAuthProvider,
	sendPasswordResetEmail,
	signInWithEmailAndPassword,
	signInWithPopup,
	updatePassword,
	User,
	UserCredential,
} from 'firebase/auth';
import { MembRegistryDataModel } from 'modules/Membership/MembRegister.model';
import { EHFirebaseApp } from 'shared';
import ehpAxios from 'shared/axios/axios.service';
import { LoginDataModel as Model } from './login.model';

export module LoginService {
	export const signIn = async (auth: Model.ILoginRequest): Promise<UserCredential> => {
		try {
			const firebaseAuth = getAuth(EHFirebaseApp);
			const result = await signInWithEmailAndPassword(firebaseAuth, auth.email, auth.password);
			return result;
		} catch (e) {
			throw e;
		}
	};

	export const loginWithCode = async (auth: Model.ILoginTicket): Promise<MembRegistryDataModel.IEHUser> => {
		try {
			const result = await ehpAxios.post(`user/login/ticket`, auth);
			return result.data;
		} catch (e) {
			console.log(e);
			throw e;
		}
	};

	export const changePassword = async (newPassword: string): Promise<boolean> => {
		try {
			const firebaseAuth = getAuth(EHFirebaseApp);
			await updatePassword(firebaseAuth.currentUser!, newPassword);
			return true;
		} catch (e) {
			throw e;
		}
	};

	export const forgotPassword = async (email: string): Promise<boolean> => {
		try {
			const firebaseAuth = getAuth(EHFirebaseApp);
			await sendPasswordResetEmail(firebaseAuth, email);
			return true;
		} catch (e) {
			throw e;
		}
	};

	export const signInWithFacebook = async () => {
		try {
			const firebaseAuth = getAuth(EHFirebaseApp);
			const provider = new FacebookAuthProvider();
			provider.addScope('email');
			provider.setCustomParameters({
				display: 'popup',
			});

			const result = await signInWithPopup(firebaseAuth, provider);
			return result;
		} catch (e) {
			console.log(e);
			throw e;
		}
	};

	export const signInWithGoogle = async () => {
		try {
			const firebaseAuth = getAuth(EHFirebaseApp);
			const provider = new GoogleAuthProvider();
			provider.addScope('email');
			provider.setCustomParameters({
				display: 'popup',
			});

			const result = await signInWithPopup(firebaseAuth, provider);
			return result;
		} catch (e) {
			console.log(e);
			throw e;
		}
	};

	export const getAuthToken = async (): Promise<string> => {
		try {
			const firebaseAuth = getAuth(EHFirebaseApp);
			const token = await firebaseAuth?.currentUser?.getIdToken()!;
			return token;
		} catch (e) {
			throw e;
		}
	};

	export const getCurrentUser = async (): Promise<User | undefined> => {
		try {
			const firebaseAuth = getAuth(EHFirebaseApp);
			if (firebaseAuth && firebaseAuth.currentUser) {
				return firebaseAuth?.currentUser;
			} else {
				return undefined;
			}
		} catch (e) {
			throw e;
		}
	};
}
