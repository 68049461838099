import styled from 'styled-components';
import { breakpoints } from 'theme/breakpoints';
import colors from 'theme/colors';

const SelectCountryStyles = styled.div`
	display: flex;
	flex-direction: column;
	width: 100%;
	margin-bottom: 20px;

	@media ${breakpoints.tablet} {
		margin-bottom: 30px;
	}

	&:last-of-type {
		margin-bottom: 0;
	}

	.form-errors {
		margin-top: 10px;
		text-align: center;
		color: ${colors.orangeOutrageous};
	}

	label {
		color: ${colors.blueDianne};
		margin-left: 10px;
		margin-bottom: 8px;
		font-family: Spartan Regular;
		font-style: normal;
		font-weight: 400;
		line-height: 16px;

		@media ${breakpoints.tablet} {
			margin-bottom: 17px;
		}
	}

	select {
		background-color: ${colors.purpleLight};
		border: 2px solid #6b8995;
		border-radius: 10px;
		font-family: Spartan Regular;
		font-style: normal;
		font-weight: 400;
		font-size: 16px;
		line-height: 15px;
		font-size: 1.2rem;
		color: ${colors.darkBlue};
        padding-left: 24px;
        height: 54px;

		&::placeholder {
			font-size: 1.2rem;
			color: ${colors.darkBlue};
		}

		@media ${breakpoints.tablet} {
			font-size: 24px;
			line-height: 22px;
		}
	}
`;

export default SelectCountryStyles;