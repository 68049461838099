import { setFlowAction, toogleLoading } from 'app.action';
import ErrorIcon from 'asset/error_icon.png';
import { AffiliationItemInfo } from 'components/global/AffiliationItemInfo/AffiliationItemInfo';
import { HopButton } from 'components/global/HopButton/HopButton';
import { HopRadioButton } from 'components/global/HopRadioOption/HopRadioButton';
import { InputGroup } from 'components/global/InputGroup/InputGroup';
import { Whatsapp } from 'components/global/Whatsapp/Whatsapp';
import PaypalButton from 'components/PaypalButton/PaypalButton.page';
import TextDivider from 'components/TextDivider/TextDivider.page';
import TitleBanner from 'modules/Affiliation/TitleBanner/TitleBanner.page';
import { ChangeEvent, FC, ReactElement, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import PhoneInput from 'react-phone-input-2';
import 'react-phone-input-2/lib/material.css';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { TicketDataModel } from 'shared/models';
import { TicketService } from 'shared/services';
import { TICKET_TYPE } from 'shared/services/constants';
import { LocalStorageService } from 'shared/services/localStorage.service';
import mixpanel from 'shared/services/mixpanel';
import { PaymentService } from 'shared/services/payment.service';
import { RootState, setAuth } from 'store';
import { SelectCountry } from '../../../components/global/Select/SelectCountry';
import {
	setBuyTicketAction,
	setPassengersQtyAction,
	setTerritoryAction,
	setTicketConfigAction,
} from '../../Affiliation/affiliation.action';
import { registerUserEHPFull } from '../MembRegister.action';
import { MembRegistryDataModel } from '../MembRegister.model';
import MembRegisterStyles from './MembRegister.styles';
import { useForm, Controller } from 'react-hook-form';
import CMembRegister from "./CMembRegister";

interface IFormRegistry extends MembRegistryDataModel.IEHUser {}

const MembershipRegisterPage: FC = (props): ReactElement => {
	const [nationality, setNationality] = useState('nacional');
	const dispatch = useDispatch();
	const navigate = useNavigate();
	const { t } = useTranslation();
	// const { watch, register, handleSubmit, getValues, formState, setValue } = useForm<any>();
	// const { member } = useSelector((state: RootState) => state.registry);
	// const flow = localStorage.getItem('flow');
	// const { ticketConfig, passengersQty } = useSelector(
	//   (state: RootState) => state.payment
	// );
	const [terms, setTerms] = useState(false);
	const [termsError, setTermsError] = useState('');
	const [phoneError, setPhoneError] = useState(false);
	const [paymentError, setPaymentError] = useState('');
	const [selectedCountry, setSelectedCountry] = useState('Costa Rica');
	const [searchParams] = useSearchParams();
	// const [readyToPay, setReadyToPay] = useState(false);
	const { isAuthenticated } = useSelector((state: RootState) => state.auth);
	const {
		watch,
		control,
		handleSubmit,
		getValues,
		formState: { isDirty, dirtyFields },
		reset,
		setValue,
	} = useForm<CMembRegister>({
		defaultValues: new CMembRegister(),
	});

	useEffect(() => {
		const localData = LocalStorageService.getLocalStorage();
		const additionalAmountTotal = +localData?.additionalAmount * +localData?.passengerQty;
		const ticketsAdditional=+localData?.ticketsPrice * +localData?.passengerQty;
		const totalAmount = +localData?.selectedOption?.price + additionalAmountTotal  + ticketsAdditional;

		dispatch(toogleLoading(true));
		const param = Array.from(searchParams.entries());
		const resultPayment: any = {};
		param.forEach((element: Array<any>) => {
			resultPayment[element[0]] = element[1];
		});

		if (resultPayment?.wp_cancel === 'yes') {
			setPaymentError('Payment canceled');
			onTransactionFailed('Payment canceled');

			mixpanel.track('Purchase Failed', {
				'new user': true,
				'purchase-amount': Number(totalAmount),
				payment: resultPayment?.flow ?? 'credit-card',
				'success-purchase': false,
			});
			dispatch(toogleLoading(false));
		} else if (resultPayment?.description === 'Transaction is approved') {
			onTransactionApproved(resultPayment);
		} else if (resultPayment?.description) {
			setPaymentError(resultPayment?.description);
			onTransactionFailed('Payment Failed');
			mixpanel.track('Purchase Failed', {
				'new user': true,
				'purchase-amount': Number(totalAmount),
				payment: resultPayment?.flow ?? 'credit-card',
				'success-purchase': false,
			});
			dispatch(toogleLoading(false));
		} else {
			dispatch(toogleLoading(false));
		}
		const country = LocalStorageService.getLocalStorage()?.user?.billToCountry;
		country && setSelectedCountry(country);
		setValue('billToCountry', country);
	}, []);

	useEffect(() => {
		const values = getValues();
		console.log('localData', localData);
		if (localData?.user?.phoneNumber) {
			setValue('phoneNumber', localData.user.phoneNumber);
		} else {
			setValue('phoneNumber', values?.phoneNumber);
		}
		setValue('billToCountry', selectedCountry);
	}, [terms]);

	const onTransactionApproved = async (resultPayment: any) => {
		const localData = LocalStorageService.getLocalStorage();
		const commerceUuid = localStorage.getItem('commerceUuid') || '';
		const result = await registerUserEHPFull(localData?.user);
		mixpanel.identify(result.uuid);
		mixpanel.people.set({
			Email: result?.userEmail,
			Country: result?.billToCountry,
			phone: result?.phoneNumber,
			name: result?.fullName,
			territory: localData?.territory?.territoryName,
			'user-address': result.billToAddress,
		});
		mixpanel.people.set_once({
			'First Purchase': new Date(),
		});
		mixpanel.people.increment('tickets');
		const user = {
			...localData?.user,
			...result,
		};

		const additionalAmountTotal = +localData?.additionalAmount * +localData?.passengerQty;
		const ticketsAdditional=localData?.ticketsPrice* +localData?.passengerQty
		const totalAmount = +localData?.selectedOption?.finalPrice + additionalAmountTotal + ticketsAdditional;

		try {
			let result;
			if (process.env.REACT_APP_EHP_ENV === 'DEV') {
				const points = [{
					useDate: localData?.pointInfo?.useDate,
					pickUpPoint: localData?.pointInfo?.pickUpTime?.uuid,
					returnPoint: localData?.returnMultiplePoint,
				}];
				const buyObj = {
					territory: localData?.territory?.territoryUuid,
					category: localData?.ticketCategory?.uuid,
					categoryType: localData?.selectedOption?.configUuid,
					personNumber: localData?.passengerQty,
					status: 'ACTIVE',
					points,
					ticketType: TICKET_TYPE.PAYMENT,
					paymentMethod: 'PAYMENT',
					totalAmout: Number(totalAmount),
					parkTickets: localData?.parkTickets
				}

				const ticketPayment: TicketDataModel.IBuyTicketRequest = {
					firebaseId: user?.uuid,
					commerce: commerceUuid,
					user: {
						firebaseId: user?.uuid,
						name: user?.fullName,
						email: user?.userEmail,
						uuid: user?.uuid,
					},
					ticket: buyObj,
					payment: {
						status: TicketDataModel.I_BUY_TICKET_STATUS.APPROVED,
						amount: Number(totalAmount),
						activityCode: resultPayment.tpt,
						date: Date(),
						time: Date(),
						operation: resultPayment.OrderHash,
						type: 'PAYMENT',
						txAmount: 0,
						flow: resultPayment?.flow ?? 'commerce',
					},
				};

				console.log('BUYOBJ', buyObj);

				result = await TicketService.buyTicketNumberV2(ticketPayment);
			} else {
				const ticketPayment: TicketDataModel.IBuyTicketRequest = {
					firebaseId: user?.uuid,
					commerce: commerceUuid,
					user: {
						firebaseId: user?.uuid,
						name: user?.fullName,
						email: user?.userEmail,
						uuid: user?.uuid,
					},
					ticket: {
						territory: localData?.territory?.territoryUuid,
						category: localData?.ticketCategory?.uuid,
						categoryType: localData?.selectedOption?.configUuid,
						personNumber: localData?.passengerQty,
						status: 'ACTIVE',
						useDate: localData?.pointInfo?.useDate,
						pickUpPoint: localData?.pointInfo?.pickUpTime?.uuid,
						returnPoint: localData?.pointInfo?.returnTime,
						ticketType: TICKET_TYPE.PAYMENT,
						parkTickets:localData?.parkTickets,
						ticketsPrice:localData?.ticketsPrice
					},
					payment: {
						status: TicketDataModel.I_BUY_TICKET_STATUS.APPROVED,
						amount: Number(totalAmount),
						activityCode: resultPayment.tpt,
						date: Date(),
						time: Date(),
						operation: resultPayment.OrderHash,
						type: 'PAYMENT',
						txAmount: 0,
						flow: resultPayment?.flow ?? 'commerce',
					},
				};

				result = await TicketService.buyTicketNumber(ticketPayment);
			}

			mixpanel.track('Ticket Successful', {
				'new user': !isAuthenticated,
				'ticket-number': result?.ticket?.ticketNumber,
				'purchase-amount': Number(totalAmount),
				payment: resultPayment?.flow ?? 'credit-card',
			});
			user.uuid = result.user?.uuid;
			LocalStorageService.updateLocalStorage({
				ticketResult: result,
			});
			dispatch(setTerritoryAction(localData?.territory));
			dispatch(setTicketConfigAction({ ...localData?.selectedOption }));
			dispatch(setFlowAction('commerce'));
			dispatch(setPassengersQtyAction(localData?.passengerQty));
			dispatch(
				setAuth({
					user: {
						...user,
					},
					isAuthenticated: true,
				})
			);
			dispatch(setBuyTicketAction(result));
			dispatch(toogleLoading(false));
			navigate('/profile');
		} catch (e) {
			console.log(e);
		}
	};

	const onTransactionFailed = async (error: string) => {
		const localData = LocalStorageService.getLocalStorage();
		const commerceUuid = localStorage.getItem('commerceUuid') || '';
		const result = await registerUserEHPFull(localData?.user);
		const user = {
			...localData?.user,
			...result,
		};

		const additionalAmountTotal = +localData?.additionalAmount * +localData?.passengerQty;
		const ticketsAdditional=localData?.ticketsPrice* +localData?.passengerQty

		const totalAmount = +localData?.selectedOption?.finalPrice + additionalAmountTotal +ticketsAdditional;

		try {
			const ticketPayment: TicketDataModel.IBuyTicketRequest = {
				firebaseId: user?.uuid,
				commerce: commerceUuid,
				user: {
					firebaseId: user?.uuid,
					name: user?.fullName,
					email: user?.userEmail,
					phone: user?.phoneNumber,
					uuid: user?.uuid,
				},
				ticket: {
					territory: localData?.territory?.territoryUuid,
					category: localData?.ticketCategory?.uuid,
					categoryType: localData?.selectedOption?.configUuid,
					personNumber: localData?.passengerQty,
					status: 'ACTIVE',
					useDate: localData?.pointInfo?.useDate,
					pickUpPoint: localData?.pointInfo?.pickUpTime?.uuid,
					returnPoint: localData?.pointInfo?.returnTime,
					ticketType: TICKET_TYPE.PAYMENT,
				},
				payment: {
					status: TicketDataModel.I_BUY_TICKET_STATUS.APPROVED,
					amount: Number(totalAmount),
					date: Date(),
					time: Date(),
					type: 'PAYMENT',
					txAmount: 0,
					flow: 'commerce',
				},
			};

			await TicketService.sendFailedNotification(ticketPayment, error);
		} catch (e) {
			console.log(e);
		}
	};

	const onSubmit = async (formValues: CMembRegister) => {
		console.log("fORM vALUE",formValues)
		const localData: any = LocalStorageService.getLocalStorage();
		console.log("LOCAL DATA==>",localData)
		trackRegister();
		try {
			if (terms) {
				if (!formValues.phoneNumber) {
					setPhoneError(true);
					return;
				} else if (phoneError) {
					console.error('PHONE NUMBER MISSING');
					return;
				}

				formValues.userType = 'PASAJERO';
				formValues.billToCountry = selectedCountry;
				LocalStorageService.updateLocalStorage({
					user: formValues,
				});
				mixpanel.track('Continue with payment', {
					'new user': !isAuthenticated,
					payment: 'credit-card',
				});

				if (process.env.REACT_APP_EHP_ENV === 'DEV') {
					navigate(`/registry?description=Transaction is approved&OrderHash=TEST${PaymentService.generateToken()}`);
					navigate(0);
				} else {
					goToPayment();
				}
			} else {
				setTermsError(t('termError'));
			}
		} catch (e) {
			console.log(e);
		}
	};

	const onCancel = () => {
		// reset();
		// dispatch(resetMember());
		// navigate('/ticket/buy/tickets');
		navigate(-1)
	};

	const goToPayment = async () => {
		dispatch(toogleLoading(true));
		// const ticketCode = await TicketService.getTicketNumber();
		// localStorage.setItem('ticketCode', `${ticketCode}`);
		// dispatch(setTicketCodeAction(ticketCode));
		await PaymentService.onPay();
		dispatch(toogleLoading(false));
	};

	const changeCountry = (e: ChangeEvent<HTMLSelectElement>) => {
		setSelectedCountry(e.target.value);
		setValue('billToCountry', e.target.value);
	};

	const handleOnApprove = (order: any) => {
		const formValues = getValues();
		formValues.userType = 'PASAJERO';
		formValues.billToCountry = selectedCountry;
		LocalStorageService.updateLocalStorage({
			user: formValues,
		});
		if (order?.status === 'COMPLETED') {
			const resultPayment = {
				tpt: order?.payer?.payer_id,
				OrderHash: order?.id,
				flow: 'PAYPAL',
			};
			onTransactionApproved(resultPayment);
		} else {
			setPaymentError('Payment Failed');
			onTransactionFailed('Payment Failed');
			mixpanel.track('Purchase Failed', {
				'new user': true,
				'purchase-amount': Number(totalAmount),
				payment: 'Paypal',
				'success-purchase': false,
			});
		}
	};

	const localData: any = LocalStorageService.getLocalStorage();
	const additionalAmount = localData?.additionalAmount;

	const additional = +additionalAmount * +localData.passengerQty;
	const ticketsAdditional=localData?.ticketsPrice* +localData?.passengerQty
	const totalAmount = +localData?.selectedOption?.finalPrice + additional +ticketsAdditional;
	const descriptionPaypal = `Membership Type: ${localData?.ticketCategory?.categoryName}, People #: ${localData?.passengerQty},
   Aditional Amout: $${additionalAmount}, Price: $${localData?.selectedOption?.finalPrice}`;
	const trackRegister = () => {
		const values = getValues();

		mixpanel.register({
			Email: values?.userEmail,
			Country: selectedCountry,
			phone: values?.phoneNumber,
			name: values?.fullName,
		});

		mixpanel.track('User Registration Form', {
			'new-user': !isAuthenticated,
		});
	};
	const localStorageData = LocalStorageService.getLocalStorage();


	const watchedValues = watch();
	const { phoneNumber, userEmail, billToCountry, isNational, userType, userId, fullName } = getValues();

	const readyToPay = phoneNumber?.length > 0 && userEmail?.length > 0 && billToCountry?.length > 0 && userType?.length > 0 && userId?.length > 0 && fullName?.length > 0 && terms;

	console.log('VALUES RENDER --->', {
		phoneNumber, userEmail, billToCountry, isNational, userType, userId, fullName
	});
	console.log('readyToPay', readyToPay);
	console.log('watchedValues', watchedValues);


	return (
		<MembRegisterStyles>
			{/* <h1 className='title-2 memb-register-title'>{t('ehRegistration')}</h1> */}
			<TitleBanner
				territoryName={localStorageData?.territory?.territoryName}
				// commerce={commerce?.name}
			/>
			<AffiliationItemInfo
				members={localData?.passengerQty}
				amount={localData?.selectedOption?.finalPrice}
				regularPrice={localData?.selectedOption?.price}
				typeName={localStorageData?.selectedOption?.typeValue}
				pointInfo={localData?.pointInfo}
				additionalAmount={additionalAmount}
				passengerNumber={localData?.passengerQty}
				parkTickets={localData?.parkTickets}
				ticketsPrice={localData?.ticketsPrice}
			/>

			<div className="item-alert-warning label-2">
				{t('registerReminder1')} <strong>{t('registerReminder2')}</strong>
			</div>

			<form className="memb-register-form-container" onSubmit={handleSubmit(onSubmit)}>
				<div className="memb-register-form">
					<Controller
						name={'fullName'}
						control={control}
						render={({ field: { onChange, value } }) => (
							<InputGroup
								autofocus
								label={''}
								inputType="text"
								placeholder={t('fullNamePlaceholder')}
								// registerProps={register('fullName', {
								// 	value: localData?.user?.fullName || '',
								// 	required: 'Required',
								// })}
								onChange={onChange}
								// errorMsg={`${formState.errors?.fullName?.message ? formState.errors?.fullName?.message : ''}`}
							/>
						)}
					/>
					<Controller
						name={'userEmail'}
						control={control}
						render={({ field: { onChange, value } }) => (
							<InputGroup
								label={''}
								inputType="email"
								placeholder={t('email')}
								onChange={onChange}
								// registerProps={register('userEmail', {
								// 	value: localData?.user?.userEmail || '',
								// 	required: t('emptyEmail'),
								// 	pattern: {
								// 		value:
								// 			/^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
								// 		message: t('emailErrorFormat'),
								// 	},
								// })}
								// errorMsg={`${formState.errors?.userEmail?.message ? formState.errors?.userEmail?.message : ''}`}
							/>
						)}
					/>




					{/* <div className='memb-register-nationality'>
            <HopRadioButton
              value='nacional'
              label={t('costaRicanTypeId')}
              active={nationality === 'nacional'}
              className='label-5'
              clickEvent={(value) => setNationality(value as string)}
              name='idType'
            />
            <HopRadioButton
              value='extranjero'
              label={t('touristTypeId')}
              active={nationality === 'extranjero'}
              className='label-5'
              name='idType'
              clickEvent={(value) => setNationality(value as string)}
            />
          </div> */}

					{/* <InputGroup
            label={''}
            inputType='text'
            placeholder={t('phonePlaceholder')}
            registerProps={register('phoneNumber', {
              required: 'Required',
              value: localData?.user?.phoneNumber || '',
              pattern: {
                value: /[0-9]/,
                message: t('phoneFormat'),
              },
            })}
            errorMsg={`${formState.errors?.phoneNumber?.message
                ? formState.errors?.phoneNumber?.message
                : ''
              }`}
          /> */}

					{/* <InputPhone
            label={''}
            inputType='text'
            placeholder="wqwq"
            registerProps={register}
            errorMsg="sasa"
          /> */}

					{/* <Controller
            render={(props) => (
              <PhoneInput
                placeholder="Numéro de téléphone"
                // inputRef={register}
                inputStyle={{
                  width: "368px",
                  height: "56px",
                  fontSize: "15px",
                  paddingLeft: "60px",
                  borderRadius: "5px"
                }}
                inputProps={{
                  name: "phone_number",
                  required: true,
                  autoFocus: true
                }}
                enableSearch={true}
                // id="phone_number"
                specialLabel="Telephone"
                // name="phone_number"
                // autoComplete="phone_number"
                onChange={(value) => setValue("phoneNumber",value)}
              // error={!!errors.phone_number}
              // helperText={
              //   errors.phone_number && "Un numéro valide est obligatoire"
              // }
              />
            )}
            defaultValue=""
            name="phone_number"
            control={control}
            rules={{ required: true }}
          /> */}

					<Controller
						name={'phoneNumber'}
						control={control}
						render={({ field: { onChange, value } }) => (
							<PhoneInput
								value={localData?.user?.phoneNumber || ''}
								placeholder={t('phonePlaceholder')}
								// inputRef={register}
								inputStyle={{
									width: '100%',
									height: '56px',
									fontSize: '24px',
									paddingLeft: '60px',
									borderRadius: '10px',
									border: '2px solid #6b8995',
								}}
								country="us"
								inputProps={{
									name: 'phone_number',
									// required: true,
									autoFocus: false,
								}}
								enableSearch={true}
								// id="phone_number"
								// specialLabel="Country"
								// name="phone_number"
								// autoComplete="phone_number"
								onChange={onChange}
								// error={!!errors.phone_number}
								// helperText={
								//   errors.phone_number && "Un numéro valide est obligatoire"
								// }
							/>
						)}
					/>


					{phoneError && <p className="form-errors">{'Required'}</p>}
					{/* 
interface IPhoneInput {
    required: boolean;
    disabled: boolean;
    name?: string;
    label?: string;
    value?: string;
    onChange: any;
} */}

					<Controller
						name={'userId'}
						control={control}
						render={({ field: { onChange, value } }) => (
							<InputGroup
								label={''}
								inputType="text"
								placeholder={t('idNumber')}
								onChange={onChange}
								// registerProps={register('userId', {
								// 	required: 'Required',
								// 	value: localData?.user?.userId || '',
								// })}
								// errorMsg={`${formState?.errors?.userId?.message ? formState?.errors?.userId?.message : ''}`}
							/>
						)}
					/>



					<h1 className="title-2 memb-register-subtitle">{t('billingCountryAddress')}</h1>

					{/* Country */}
					<SelectCountry label={''} value={selectedCountry} onChange={changeCountry} />


					{/* <InputGroup
            label={t('state')}
            inputType='text'
            placeholder={t('statePlaceholder')}
            registerProps={register('billToState', {
              required: 'Required',
            })}
            errorMsg={`${formState.errors?.billToState?.message}`}
          />

          <InputGroup
            label={t('city')}
            inputType='text'
            placeholder={t('cityPlaceholder')}
            registerProps={register('billToCity', {
              required: 'Required',
            })}
            errorMsg={`${formState.errors?.billToCity?.message}`}
          />

          <InputGroup
            label={t('zip')}
            inputType='text'
            placeholder={t('zipPlaceholder')}
            registerProps={register('billToZipPostCode', {
              required: 'Requitred',
            })}
            errorMsg={`${formState.errors?.billToZipPostCode?.message}`}
          /> */}

					<Controller
						name={'billToAddress'}
						control={control}
						render={({ field: { onChange, value } }) => (
							<InputGroup
								label={''}
								inputType="text"
								placeholder={t('cityAddress')}
								onChange={onChange}
								// registerProps={register('billToAddress', {
								// 	required: 'Required',
								// 	value: localData?.user?.billToAddress || '',
								// })}
								// errorMsg={`${formState.errors?.billToAddress?.message ? formState.errors?.billToAddress?.message : ''}`}
							/>
						)}
					/>

					<div className="memb-terms-condition-container">
						<HopRadioButton
							value={!terms}
							label={`${t('termsConditionsStart')} <a href="https://www.easyhop.co/terms">${t(
								'termsConditionsLink'
							)}</a> ${t('termsConditionsEnd')}`}
							active={terms}
							className="label-4 memb-finish-terms"
							href="https://www.easyhop.co/terms"
							clickEvent={(value) => {
								setTerms(value as boolean);
								if (value) {
									setTermsError('');
								}
							}}
						/>
						{termsError && <div className="item-alert-warning label-2">{termsError}</div>}

						{paymentError && (
							<div className="item-alert-warning-payment label-2">
								<img src={ErrorIcon} alt="ErrorIcon" />
								<div>
									<h1 className="item-alert-warning-payment-title">There was a problem</h1>
									<p>Your Payment was not approved:</p>
									<p>{paymentError}</p>
								</div>
							</div>
						)}
					</div>
				</div>

				<div className="memb-register-social">
					{process.env.REACT_APP_EHP_ENV === 'DEV' ? (
						<>
							<HopButton
								look="primary"
								className={`memb-button ${!readyToPay && 'disabled-button'}`}
								content={'Pay (Develop)'}
								type="submit"
								disabled={!readyToPay}
							/>
						</>
					) : (
						<>
							<HopButton
								look="primary"
								className={`memb-button ${!readyToPay && 'disabled-button'}`}
								content={t('continueWithPayment')}
								type="submit"
								disabled={!readyToPay}
							/>
							<TextDivider text="Or, pay with Paypal" />
							<div
								className="paypal-section"
								onClick={() => {
									mixpanel.track('Continue with payment Paypal', {
										'new user': !isAuthenticated,
									});
								}}>
								<PaypalButton
									amount={totalAmount}
									purchaseDescription={descriptionPaypal}
									handleOnApprove={handleOnApprove}
									handleOnCancel={() => {
										setPaymentError('Payment canceled');
										onTransactionFailed('Payment canceled');
										mixpanel.track('Purchase Failed', {
											'new user': true,
											'purchase-amount': Number(totalAmount),
											payment: 'Paypal',
											'success-purchase': false,
										});
									}}
									handleOnError={(err: any) => {
										setPaymentError(err);
										onTransactionFailed('Payment Failed');
										mixpanel.track('Purchase Failed', {
											'new user': true,
											'purchase-amount': Number(totalAmount),
											payment: 'Paypal',
											'success-purchase': false,
										});
									}}
									handleOnClick={() => trackRegister()}
									readyToPay={readyToPay}
								/>
							</div>
						</>
					)}

					<HopButton look="secondary" className="memb-button" content={t('back')} type="reset" onClick={onCancel} />
				</div>
				<Whatsapp />
			</form>
		</MembRegisterStyles>
	);
};

export default MembershipRegisterPage;
