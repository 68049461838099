import { ReservationDataModel } from 'shared/models/reservation.model';
import { TerritoryDataModel } from 'shared/models/territory.model';
import {
	setCenter,
	setCurrentPosition,
	setPoints,
	setRoutes,
	setSelectedReservation,
	setTrip,
	setTripData,
	setTripPosition,
} from 'store/slices/reservation.slice';
import { RoutesDataModel } from './routes.model';

export const setCurrentPositionAction = (position: GeolocationPosition) => {
	return setCurrentPosition(position);
};

export const setTripDataAction = (data: any) => {
	return setTripData(data);
};

export const setTripPositionAction = (position: any) => {
	return setTripPosition(position);
};

export const setTripAction = (trip: ReservationDataModel.Trip) => {
	return setTrip(trip);
};

export const setCenterAction = (center: any) => {
	return setCenter(center);
};

export const setPointsAction = (points: Array<TerritoryDataModel.IPoint>) => {
	return setPoints(points);
};

export const setRoutesAction = (routes: Array<TerritoryDataModel.IRoute>) => {
	return setRoutes(routes);
};

export const setSelectedReservationAction = (selectedReservation: RoutesDataModel.SelectedBooking) => {
	return setSelectedReservation(selectedReservation);
};
