import { HopButton } from 'components/global/HopButton/HopButton';
import { InputGroup } from 'components/global/InputGroup/InputGroup';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router';
import { RootState } from 'store';
import AffilItineraryStyles from './AffilItinerary.styles';
import { AffiliationItemInfo } from '../../../components/global/AffiliationItemInfo/AffiliationItemInfo';
import { useState } from 'react';
import { CategoryDataModel } from '../../../shared/models';
import { Itinerary } from '../../../components/global/Itinerary/Itinerary';
import CalendarSelector from '../../../components/global/CalendarSelector/CalendarSelector';
import { CommerceDataModel } from 'shared/models/commerce.model';
import TitleBanner from '../TitleBanner/TitleBanner.page';
import { LocalStorageService } from 'shared/services/localStorage.service';

const AffilItinerary = (): JSX.Element => {
  const { ticketCode } = useSelector((state: RootState) => state.affiliation);
  const { user } = useSelector((state: RootState) => state.auth);
  const navigate = useNavigate();
  const { t } = useTranslation();
  const [selectedOption, setSelectedOption] =
    useState<CategoryDataModel.TicketConfigurationResponse>();
  const [selectedCategory, setSelectedCategory] =
    useState<CategoryDataModel.ICategory>();
  const [passengerNumber, setPassengerNumber] = useState<number>(1);
  const [commerce, setCommerce] = useState<CommerceDataModel.ICommerce>();

  const goToIdForm = () => {
    navigate('/registry-finish');
  };

  const onCancel = () => {
    navigate(-1);
  };

  const onAddTrip = () => {
    navigate('/ticket/buy/trip');
  };

  const localStorageData = LocalStorageService.getLocalStorage();

  return (
    <AffilItineraryStyles>
      <h1 className='title-2 affil-register-title'>{t('titleItinerary')}</h1>
      <TitleBanner
        territoryName={localStorageData?.territory?.territoryName}
        commerce={commerce?.name}
      />
      <div className='aff-info'>
        <AffiliationItemInfo
          members={selectedOption?.typeKey !== 'one' ? passengerNumber : 1}
          amount={selectedOption?.finalPrice}
          regularPrice={selectedOption?.price}
          typeName={selectedCategory?.categoryKey}
          editButton={true}
        />
      </div>
      <div className='content'>
        <div className='instructions'>{t('instructionsItinerary')}</div>
        <div className='calendar'>
          <CalendarSelector
            required={false}
            disabled={false}
            onSetDate={(data: any) => {
              console.info('set date', data);
            }}
          />
        </div>
        <div className='itinerary'>
          <Itinerary addAction={onAddTrip} />
        </div>
      </div>
      <div className='affil-itinerary-actions'>
        <HopButton onClick={() => {}} look='primary' content={t('continue')} />
        <HopButton onClick={onCancel} look='secondary' content={t('back')} />
      </div>
    </AffilItineraryStyles>
  );
};

export default AffilItinerary;
