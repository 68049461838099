class CMembRegister {
    phoneNumber: string;
    isNational: boolean;
    userType: string;
    billToCountry: string;
    fullName: string;
    userEmail: string;
    userId: string;
    billToAddress?: string;

    constructor() {
        this.phoneNumber = '';
        this.isNational = true;
        this.userType = 'PASAJERO';
        this.billToCountry = '';
        this.fullName = '';
        this.userEmail = '';
        this.userId = '';
        this.billToAddress = '';
    }
}

export default CMembRegister;
