import { configureStore } from '@reduxjs/toolkit';
import { AffiliationSlice, AppSlice, PaymentSlice, RegistrySlice, TicketSlice, TicketItinerarySlice } from 'store';
import { AuthSlice } from './slices/auth.slice';
import LangSliceReducer from './slices/lang-selected.slice';
import { ReservationSlice } from './slices/reservation.slice';

const store = configureStore({
	reducer: {
		auth: AuthSlice.reducer,
		registry: RegistrySlice.reducer,
		app: AppSlice.reducer,
		affiliation: AffiliationSlice.reducer,
		payment: PaymentSlice.reducer,
		ticket: TicketSlice.reducer,
		reservation: ReservationSlice.reducer,
		lang: LangSliceReducer,
		itinerary: TicketItinerarySlice.reducer,
	},
	middleware: (getDefaultMiddleware) =>
		getDefaultMiddleware({
			serializableCheck: {
				// Ignore these action types
				ignoredActions: ['registry/setUserId'],
				// Ignore these field paths in all actions
				ignoredActionPaths: ['meta.arg', 'payload.timestamp'],
				// Ignore these paths in the state
				ignoredPaths: ['items.dates'],
			},
		}),
});

// Infer the `RootState` and `AppDispatch` types from the store itself
export type RootState = ReturnType<typeof store.getState>;
// Inferred type: {posts: PostsState, comments: CommentsState, users: UsersState}
export type AppDispatch = typeof store.dispatch;

export default store;
