import { RouteObject } from 'react-router';
import ForgotPasswordEmailPage from './ForgotPasswordEmail.page';

export const ForgotPasswordRoutes: Array<RouteObject> = [
	{
		path: '/forgot-password-email',
		element: <ForgotPasswordEmailPage />,
	},
	{
		path: '/forgot-password-update',
		element: <ForgotPasswordEmailPage />,
	},
	{
		path: '/forgot-password-confirm',
		element: <ForgotPasswordEmailPage />,
	},
];
