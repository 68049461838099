import { setFlowAction, toogleLoading } from "app.action";
import { AffiliationItemInfo } from "components/global/AffiliationItemInfo/AffiliationItemInfo";
import { HopButton } from "components/global/HopButton/HopButton";
import {
  ISelectionBoxData,
  SelectionBox,
} from "components/global/SelectionBox/SelectionBox";
import { Whatsapp } from "components/global/Whatsapp/Whatsapp";
import { setCommerceUuidAction } from "modules/Membership/MembRegister.action";
import { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router";
import { useSearchParams } from "react-router-dom";
import { CategoryDataModel } from "shared/models";
import { CommerceDataModel } from "shared/models/commerce.model";
import { CategoryService } from "shared/services";
import { CommerceService } from "shared/services/commerce.service";
import { LocalStorageService } from "shared/services/localStorage.service";
import mixpanel from "shared/services/mixpanel";
import { PaymentService } from "shared/services/payment.service";
import { TerritoryService } from "shared/services/territory.service";
import { RootState } from "store";
import { setTerritoryAction } from "..";
import { TerritoryDataModel } from "shared/models/territory.model";
import { PointSeatsDataModel } from "shared/models/pointSeats.model";

import {
  setPassengersQtyAction,
  setTicketConfigAction,
} from "../affiliation.action";
import TitleBanner from "../TitleBanner/TitleBanner.page";
import AffilSelectionStyles from "./AffilTicketParkOnly.styles";
import {
  ChakraProvider,
  FormControl,
  FormLabel,
  Container,
  Stack,
  Select,
} from "@chakra-ui/react";

const AffilTicketParkOnlyPage = (): JSX.Element => {
  const [commerce, setCommerce] = useState<CommerceDataModel.ICommerce>();

  const { t } = useTranslation();

  const navigate = useNavigate();
  const dispatch = useDispatch();

  // * STATES FOR THE WEB PAGE

  const [dataFake, setDataFake] = useState<Array<TerritoryDataModel.IPark>>([
    // {uuid:'82abdc85-64cc-48ce-839f-f42bcb09337c', name: "Baldi Entrance + Meal", price: "21.50" },
    // {uuid:'afa44cda-8ea2-4cd7-ab9b-dae59321b39c', name: "Mistico Entrance", price: "27.00" },
    // {uuid:'a1188ef6-6c2f-45d6-ab4a-24d4252148b7', name: "Baldi Entrance", price: "38.00" },
    // {uuid:'7bb7b153-a30c-4d5c-b8d8-9b5f0ee95c9f', name: "Arenal 1968 Entrance", price: "21.50" },
    // {uuid:'b4df68dd-1c77-4c81-8763-452ddc9f9700', name: "Paradise Hot Spring Entrance", price: "30.00" },
    // {uuid:'b5c4e0fb-3cc9-4e91-b25b-a6aa46b030bd', name: "Hotel Los Lagos Entrance", price: "36.00" },
    // {uuid:'216f6cea-5577-4277-8179-3449397dca7b', name: "Termales de Arenal Entrance", price: "5.5" },
    // {uuid:'2443ee13-1877-47df-a5a7-8ac480ce5538', name: "Parque Ecologico Entrance", price: "13" },
    // {uuid:'eb84b670-d0df-4867-b811-1012fc0cb813', name: "Day Pass Parque Ecologico", price: "27" },
  ]);
  const [parks, setParks] = useState<Array<TerritoryDataModel.IPark>>([]);
  const [ticketsSelected, setTicketsSelected] = useState<any>([]);
  const [ticketsPrice, setTicketsPrice] = useState(5);
  const [additionalAmount, setAdditionalAmount] = useState<number>(5);
  const [additions, setAdditions] = useState(0);

  //  * Use Effect a the Begining of the page
  useEffect(() => {
    try {
      init();
    } catch (e) {
      console.log(e);
    }
  }, []);
  //  * Use Effect  when you update the tickets Select we calculate the price
  useEffect(() => {
    let amountForTickets = 0;
    ticketsSelected.forEach((element: any) => {
      amountForTickets = amountForTickets + Number(element.price);
    });
    setTicketsPrice(amountForTickets);
  }, [ticketsSelected]);

  //  * Use Effect  when you update the tickets Select we calculate the price
  useEffect(() => {
    console.log("this is tickets price", ticketsPrice);
    setAdditionalAmount(ticketsPrice);
  }, [ticketsPrice]);

  useEffect(() => {
    console.log("ADDITIONS UPDATED", additions);
  }, [additions]);

  // METHOD WHEN YOU CLICK AN OPTION OF A PARK
  const updateSelectedTickets = (index: number) => {
    const ticketSelected = parks[index];
    let checked: boolean;
    if (ticketsSelected.some((e: any) => e.park_name === ticketSelected.park_name)) {
      console.log("ALREADY CLICKED");
      checked = true;
      const indexOfElement = ticketsSelected.indexOf(ticketSelected);
      const copyOfTicketsSelected = [...ticketsSelected]; // make a separate copy of the array
      copyOfTicketsSelected.splice(indexOfElement, 1);
      setTicketsSelected(copyOfTicketsSelected);
      /* vendors contains the element we're looking for */
    } else {
      console.log("NO BEING CLICKED");
      checked = false;
      setTicketsSelected([...ticketsSelected, ticketSelected]);
      console.log(" IF SECTION TICKETS SELECTED AFTER METHOD", ticketsSelected);
    }
  };

  const init = async () => {
    const territory = LocalStorageService.getLocalStorage().territory;
    const localData = await LocalStorageService.getLocalStorage();
    const parksInfo = await TerritoryService.getParksByTerritory(
      "a03d86ac-9376-4db3-b4ca-a9fbf8b886bb"
    );
    setParks(parksInfo);
    setAdditions(localData?.additionalAmount)
    // // setDataFake(parksInfo)
    if(localData.parkTickets.length>0){
      console.log("SI HAY TICKETS PREVIOS")
      console.log(localData.parkTickets)
      setTicketsSelected(localData.parkTickets)
    }
  };

  const onSetParkTickets = () => {
    const text = ticketsSelected.map((e:any, index: any)=>`${index + 1} - ${e.park_name} ($${e.price}) `)
    window.location.href = `https://wa.me/50661309784?text=${text.join(', ')}`;
  };

  const onCancel = () => {
    navigate("/");
  };

  const goToRegisterPage = async () => {
    navigate("/registry");
  };

  const localStorageData = LocalStorageService.getLocalStorage();

  return (
    <AffilSelectionStyles>
      <TitleBanner
        territoryName="La Fortuna"
        commerce="Parks"
      />

      <div className="selection-parks-main-area">
        <h2 className="affil-parks-title-2">{t("ticketParkTitle2")}</h2>
        <h1 className="affil-parks-title-1">
          {t("ticketParkTitle")} {localStorageData?.territory?.territoryName}
        </h1>

        <div className="affil-park-checkBox-section">
          {parks.map((item, index) => {
            let checked: boolean;
            
             if (ticketsSelected.some((e:any)=> e.park_name === item.park_name)) {
              checked=true
              console.log("EL ELEMENTO SI SI  EXISTE",item)
            }
            else{
              console.log("EL ELEMENTO NO EXISTE",item)
              checked=false
            }
            return (
              <div className="container-option">
                <input
                  className="affil-park-checkBox"
                  type="checkbox"
                  key={index}
                  checked={checked}
                  onClick={() => {
                    updateSelectedTickets(index);
                  }}
                  value={String(item.price)}
                />
                <label className="affil-park-checkBox-label">
                  {" "}
                  <span className="affil-park-checkBox-label-price">
                    ${parseFloat(String(item.price))}
                  </span>{" "}
                  {String(item.park_name)}
                </label>
              </div>
            );
          })}
        </div>
      </div>
      <div className="affil-selection-actions">
        <HopButton
          onClick={onSetParkTickets}
          look="primary"
          content={t("continueParkTicketsButton")}
        />
        <HopButton onClick={onCancel} look="secondary" content={t("back")} />
      </div>

      {/* <Whatsapp /> */}
    </AffilSelectionStyles>
  );
};

export default AffilTicketParkOnlyPage;
