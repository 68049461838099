import styled from 'styled-components';
import { breakpoints } from 'theme/breakpoints';
import colors from 'theme/colors';
import shadows from 'theme/shadows';

const AffilConfirmStyles = styled.div`
	max-width: 390px;
	margin: 0 auto;
	padding-top: 48px;
	background-color: ${colors.white};

	@media ${breakpoints.tablet} {
		max-width: 600px;
	}

	.affil-confirm-container {
		padding: 0 35px;
	}

	.affil-confirm-title {
		color: ${colors.blueDianne};
		margin-bottom: 35px;
		text-align: center;
		padding: 0 35px;
	}

	.affil-confirm-desc {
		color: ${colors.blueDianne};
		text-align: center;
		line-height: 1.2;
		margin-bottom: 24px;
	}

	.affil-confirm-ticket {
		text-align: center;
		background: #ffffff;
		box-shadow: ${shadows.black_o10};
		border-radius: 20px;
		padding: 16px 30px 40px;
		color: ${colors.blueDianne};
		margin-bottom: 30px;
	}

	.affil-confirm-ticket-title {
		color: ${colors.grayJuniper};
		margin-bottom: 15px;
	}

	.affil-confirm-ticket-number {
		font-family: Spartan Bold;
		margin-bottom: 25px;
	}

	.affil-confirm-ticket-desc {
	}

	.affil-confirm-ticket-mail {
		margin: 48px 0px;
	}

	.affil-confirm-mail-container {
		display: flex;
		align-items: center;
		justify-content: center;
		gap: 13px;
	}

	.affil-confirm-ticket-tel {
		margin-bottom: 30px;
	}

	.affil-confirm-ticket-action {
		margin: 0 auto;
		width: 265px;
		height: 50px;
		@media ${breakpoints.tablet} {
			width: 350px;
		}
	}

	.affil-activate-action {
		margin: 20px auto 0 auto !important;
	}

	.affil-confirm-action {
		margin: 0 auto;
		width: 265px;
		height: 50px;
		@media ${breakpoints.tablet} {
			width: 350px;
		}
	}
`;

export default AffilConfirmStyles;
