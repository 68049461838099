import { HopButton } from 'components/global/HopButton/HopButton';
import { InputGroup } from 'components/global/InputGroup/InputGroup';
import { LoginService } from 'modules/login';
import { MembRegistryDataModel } from 'modules/Membership/MembRegister.model';
import { FC, ReactElement } from 'react';
import { useForm } from 'react-hook-form';
import toast from 'react-hot-toast';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { RootState } from 'store';
import ProfilePasswordStyles from './ProfilePassword.styles';

interface IFormRegistry extends MembRegistryDataModel.IRegistryPasswordState {
	confirmPassword?: string;
}

const ProfilePasswordPage: FC = (): ReactElement => {
	const dispatch = useDispatch();
	const navigate = useNavigate();
	const { t } = useTranslation();
	const { register, handleSubmit, reset, formState, getValues } = useForm<IFormRegistry>();
	const { user } = useSelector((state: RootState) => state.auth);

	const onSubmit = async (formValues: IFormRegistry) => {
		try {
			const loginRequest = {
				email: user?.userEmail || '',
				password: formValues.currentPassword || '',
			};
			const loginResult = await LoginService.signIn(loginRequest);
			if (loginResult && formValues.password) {
				const result = await LoginService.changePassword(formValues.password);
				if (result) {
					goToProfilePasswordFinish();
				} else {
					toast.error(t('passwordUpdateError'));
				}
			} else {
				toast.error(t('passwordUpdateError'));
			}
		} catch (e) {
			toast.error(t('passwordUpdateError'));
			console.log(e);
		}
	};

	const goToProfilePasswordFinish = () => {
		navigate('/profile-password-finish');
	};

	return (
		<ProfilePasswordStyles>
			<h1 className="title-2 memb-password-title">{t('changePassword')}</h1>
			<form onSubmit={handleSubmit(onSubmit)}>
				<div className="memb-password-form">
					<InputGroup
						label={t('currentPassword')}
						inputType="password"
						placeholder={t('currentPasswordPlaceholder')}
						registerProps={register('currentPassword', {
							required: 'La contraseña actual no puede estar vacia',
						})}
						errorMsg={formState.errors?.currentPassword?.message}
					/>

					<InputGroup
						label={t('password')}
						inputType="password"
						placeholder={t('passwordPlaceholder')}
						registerProps={register('password', {
							required: 'La contraseña no puede estar vacia',
							pattern: {
								value: /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)[a-zA-Z\d]{8,}$/,
								message: 'La contraseña debe tener 8 caracteres, una mayuscula y un numero',
							},
						})}
						errorMsg={formState.errors?.password?.message}
					/>
					<InputGroup
						label={t('confirmPassword')}
						inputType="password"
						placeholder={t('confirmPasswordPlaceholder')}
						registerProps={register('confirmPassword', {
							required: 'La contraseña no puede estar vacia',
							validate: (value?: string) => {
								const { password } = getValues();
								return password === value || t('currentPasswordError');
							},
						})}
						errorMsg={formState.errors?.confirmPassword?.message}
					/>
				</div>

				<div className="memb-password-actions">
					<HopButton className="memb-button" look="primary" content={t('save')} type="submit" />
				</div>
			</form>
		</ProfilePasswordStyles>
	);
};

export default ProfilePasswordPage;
