import { AffiliationRoutes } from 'modules/Affiliation/affiliation.route';
import { ForgotPasswordRoutes } from 'modules/ForgotPassword/ForgotPassword.route';
import { LoginRoutes } from 'modules/login';
import { MembershipRoutes } from 'modules/Membership';
import { ProfileRoutes } from 'modules/Profile/profile.route';
import { ReservationMapRoutes } from 'modules/Routes/routes.route';
import { RouteObject } from 'react-router';

export const routes: Array<RouteObject> = [
	...LoginRoutes,
	...MembershipRoutes,
	...ProfileRoutes,
	...AffiliationRoutes,
	...ReservationMapRoutes,
	...ForgotPasswordRoutes,
];
