import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { TicketDataModel } from 'shared/models';

export interface TicketItinerarySliceState extends TicketDataModel.ITicketItinerary {}

export const TicketItinerarySlice = createSlice({
	name: 'itinerary',
	initialState: {
		time: undefined,
		pickupPoint: undefined,
		returnPoint: undefined,
	} as TicketItinerarySliceState,
	reducers: {
		setTicketItinerary: (state: TicketItinerarySliceState, action: PayloadAction<any>) => {
			state.time = action.payload.time;
			state.pickupPoint = action.payload.pickup;
			state.returnPoint = action.payload.return;
		},
	},
});

export const { setTicketItinerary } = TicketItinerarySlice.actions;
