import styled from 'styled-components';
import { breakpoints } from 'theme/breakpoints';

const ReservationConfirmStyles = styled.div`
	display: flex;
	width: 100%;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	gap: 20px;
	margin-top: 20px;

	.routes-reservation-actions {
		display: flex;
		width: 100%;
		align-items: center;
		justify-content: center;
		background: #ffffff;
		gap: 25px;
		flex-direction: column;
		button {
			@media ${breakpoints.phone} {
				width: 250px;
			}
			@media ${breakpoints.tablet} {
				width: 421px;
				padding: 12px 10px;
			}
		}
	}
`;

export default ReservationConfirmStyles;
