import { createGlobalStyle } from 'styled-components';
import { typography } from 'theme/typography';
import LeagueSpartanBold from './fonts/LeagueSpartan-Bold.ttf';
import LeagueSpartanMedium from './fonts/LeagueSpartan-Medium.ttf';
import LeagueSpartanRegular from './fonts/LeagueSpartan-Regular.ttf';
import colors from './theme/colors';

const GlobalStyle = createGlobalStyle`
  @font-face {
    font-family: Spartan Regular;
    src: url(${LeagueSpartanRegular});
  }
  @font-face {
    font-family: Spartan Medium;
    src: url(${LeagueSpartanMedium});
  }
  @font-face {
    font-family: Spartan Bold;
    src: url(${LeagueSpartanBold});
  }

  html, body, #root {
    height: 100%
  }

  body {
    margin: 0;
    padding: 0;
    background: ${colors.white};
    overscroll-behavior-y: none;

    * {
      box-sizing: border-box;
    }
    
  }
  
  .pages-content {
    z-index: 700;
  }

  .page-container {
    margin: 0;
    padding: 0;
    height: 100%;
    display: grid;
    grid-template-rows: auto auto auto 1fr;
    grid-template-columns: 100%;

    ._loading_overlay_wrapper {
      position: fixed;
      z-index: 1000;
      height: 100vh;
      width: 100vw;
    }
  }

  h1,h2,h3,h4,h5, p {
    margin: 0;
  }

  ${typography}
`;

export default GlobalStyle;
