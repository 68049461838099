import styled from 'styled-components';
import { breakpoints } from 'theme/breakpoints';
import colors from 'theme/colors';
import { buttonsStyles } from 'theme/typography';

const ForgotPasswordStyles = styled.div`
	max-width: 390px;
	margin: 0 auto;
	padding-top: 48px;
	background-color: ${colors.white};
	display: flex;
	flex-direction: column;
	align-items: center;

	justify-content: center;
	text-align: center;

	@media ${breakpoints.phone} {
		zoom: unset;
		padding: 30px 35px 0;
	}

	@media ${breakpoints.tablet} {
		padding-top: 104px;
		max-width: 640px;
		zoom: 80%;
	}

	.login-logo {
		width: 130px;
		height: 105px;
		margin-bottom: 50px;
	}

	.memb-password-title {
		color: ${colors.blueDianne};
		margin-bottom: 100px;
		text-align: center;
		padding: 0 35px;
	}

	.memb-password-form {
		margin-bottom: 20px;
		padding: 0 35px;
		display: flex;
		flex-direction: column;
		align-items: center;
	}

	.memb-button {
		margin: ${buttonsStyles.verticalMarginSpacing}
		width: 210px;
		cursor: pointer;
		font-size:1.2rem;
		line-height:28px;
		max-height: 60px !important;

		@media ${breakpoints.phone} {
			width: 250px;
		}

		&:last-of-type {
			margin-bottom: 0;
			margin-left: 10px;
		}

		@media ${breakpoints.tablet} {
			width: 421px;
			padding: 12px 10px;
		}
	}

	.memb-password-actions {
		display: flex;
		justify-content: center;
		padding: 0 35px;
	}
`;

export default ForgotPasswordStyles;
