import styled from 'styled-components';
import { breakpoints } from 'theme/breakpoints';
import colors from 'theme/colors';

const InputGroupStyles = styled.div`
	display: flex;
	flex-direction: column;
	width: 100%;
	margin-bottom: 20px;

	@media ${breakpoints.tablet} {
		margin-bottom: 30px;
	}

	&:last-of-type {
		margin-bottom: 0;
	}

	.form-errors {
		font-size: 12px;
		font-weight: 400;
		line-height: initial;
		margin-top: 10px;
		text-align: left;
		color: ${colors.orangeOutrageous};
	}

	label {
		color: ${colors.blueDianne};
		margin-bottom: 8px;
		font-family: Spartan Regular;
		font-size: 20px;
		font-style: normal;
		font-weight: 400;
		line-height: initial;

		@media ${breakpoints.tablet} {
			margin-bottom: 17px;
		}
	}

	input {
		background-color: transparent;
		border: 2px solid #6b8995;
		border-radius: 10px;
		padding: 10px 20px;
		font-family: Spartan Regular;
		font-style: normal;
		font-weight: 400;
		font-size: 16px;
		line-height: 15px;
		font-size: 1.2rem;

		&::placeholder {
			font-size: 1.2rem;
			color: ${colors.darkBlue};
		}

		@media ${breakpoints.tablet} {
			padding: 16px 40px;
			font-size: 24px;
			line-height: 22px;
		}
		&:focus {
			outline: none;
		}
	}
`;

export default InputGroupStyles;
