import { setFlowAction, toogleLoading } from 'app.action';
import mail from 'asset/mail.svg';
import { HopButton } from 'components/global/HopButton/HopButton';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { TicketDataModel } from 'shared/models';
import { PaymentDataModel } from 'shared/models/payment.model';
import { TicketService } from 'shared/services';
import { RootState, setAuth } from 'store';
import {
  setBuyTicketAction,
  setPassengersQtyAction,
  setTerritoryAction,
  setTicketCodeAction,
  setTicketConfigAction,
} from '../affiliation.action';
import AffilConfirmStyles from './AffilConfirm.styles';

const AffilConfirmPage = (): JSX.Element => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { ticketConfig } = useSelector((state: RootState) => state.payment);

  const [ticketCode, setTicketCode] = useState<string>();

  const territory = JSON.parse(localStorage.getItem('territory') || '{}');
  const ticketConfigLocal = JSON.parse(
    localStorage.getItem('ticketConfig') || '{}'
  );
  const ticketCategory = JSON.parse(
    localStorage.getItem('ticketCategory') || '{}'
  );
  const user = JSON.parse(localStorage.getItem('user') || '{}');
  const passengerQty = Number(localStorage.getItem('passengerQty') || '1');
  const commerceUuid = localStorage.getItem('commerceUuid') || '';
  const flow = localStorage.getItem('flow') || '';
  const [expCode, setExpCode] = useState('');
  const { t } = useTranslation();

  const [searchParams] = useSearchParams();

  useEffect(() => {
    dispatch(toogleLoading(true));
    const param = Array.from(searchParams.entries());

    if (param && param.length > 0) {
      const resultPayment: any = {};

      param.forEach((element: Array<any>) => {
        resultPayment[element[0]] = element[1];
      });

      if (process.env.REACT_APP_EHP_ENV === 'DEV') {
        getUserTicketV2(resultPayment).finally(() => {
          dispatch(toogleLoading(false));
          goToMyProfile();
        });
      } else {
        getUserTicket(resultPayment).finally(() => {
          dispatch(toogleLoading(false));
          goToMyProfile();
        });
      }
    }
  }, []);

  const goToMyProfile = () => {
    navigate('/profile');
  };

  const goToAffiliationRegistration = () => {
    navigate('/affiliation-register');
  };

  const goToIdForm = () => {
    navigate('/registry-finish');
  };

  const getTicketCode = async () => {
    try {
      const result = await TicketService.getTicketNumber();
      setTicketCode(result || '');
      dispatch(setTicketCodeAction(result));
      return result;
    } catch (e) {
      console.log(e);
      throw e;
    }
  };

  const setStorageToState = () => {
    dispatch(setTerritoryAction(territory));
    dispatch(setTicketConfigAction({ ...ticketConfig, ...ticketConfigLocal }));
    dispatch(setFlowAction(flow));
    dispatch(setPassengersQtyAction(passengerQty));
    dispatch(setAuth(user));
  };

  const getUserTicket = async (
    resultPayment: PaymentDataModel.IPagueloFacilResult
  ) => {
    try {
      const ticket = await getTicketCode();
      localStorage.setItem('ticketCode', ticket);
      const ticketPayment: TicketDataModel.IBuyTicketRequest = {
        firebaseId: user?.user.firebaseId,
        commerce: commerceUuid,
        user: {
          firebaseId: user?.user.firebaseId,
          name: user?.user.fullName,
          email: user?.user.userEmail,
          uuid: user?.user.uuid,
        },
        ticket: {
          territory: territory?.territoryUuid,
          category: ticketCategory?.uuid,
          categoryType: ticketConfigLocal?.configUuid,
          personNumber: passengerQty,
          ticketNumber: ticket,
          status: 'ACTIVE',
          useDate: ticketConfigLocal?.pointInfo?.useDate,
          pickUpPoint: ticketConfigLocal?.pointInfo?.pickUpTime?.uuid,
          returnPoint: ticketConfigLocal?.pointInfo?.returnTime,
        },
        payment: {
          status:
            resultPayment.Estado === 'Aprobada'
              ? TicketDataModel.I_BUY_TICKET_STATUS.APPROVED
              : TicketDataModel.I_BUY_TICKET_STATUS.DENIED,
          amount: Number(resultPayment.TotalPagado),
          activityCode: resultPayment.activityCode,
          date: resultPayment.Fecha,
          time: resultPayment.Hora,
          operation: resultPayment.Oper,
          type: resultPayment.Tipo,
          txAmount: Number(resultPayment.txAmount),
          flow: flow === 'commerce' ? 'commerce' : 'profile',
        },
      };

      const result = await TicketService.buyTicketNumber(ticketPayment);
      user.uuid = result.user?.uuid;
      setStorageToState();
      dispatch(setBuyTicketAction(result));
    } catch (e) {
      console.log(e);
    }
  };
  const getUserTicketV2 = async (
    resultPayment: PaymentDataModel.IPagueloFacilResult
  ) => {
    try {
      const ticket = await getTicketCode();
      localStorage.setItem('ticketCode', ticket);

      const points = [{
        useDate: ticketConfigLocal?.pointInfo?.useDate,
        pickUpPoint: ticketConfigLocal?.pointInfo?.pickUpTime?.uuid,
        returnPoint: ticketConfigLocal?.pointInfo?.returnTime,
      }];

      const buyObj = {
        territory: territory?.territoryUuid,
        category: ticketCategory?.uuid,
        categoryType: ticketConfigLocal?.configUuid,
        personNumber: passengerQty,
        status: 'ACTIVE',
        points,
        ticketType: flow === 'commerce' ? 'commerce' : 'profile',
        paymentMethod: resultPayment.Tipo,
        totalAmout: Number(resultPayment.TotalPagado)
      }

      const ticketPayment: TicketDataModel.IBuyTicketRequest = {
        firebaseId: user?.user.firebaseId,
        commerce: commerceUuid,
        user: {
          firebaseId: user?.user.firebaseId,
          name: user?.user.fullName,
          email: user?.user.userEmail,
          uuid: user?.user.uuid,
        },
        ticket: buyObj,
        payment: {
          status:
              resultPayment.Estado === 'Aprobada'
                  ? TicketDataModel.I_BUY_TICKET_STATUS.APPROVED
                  : TicketDataModel.I_BUY_TICKET_STATUS.DENIED,
          amount: Number(resultPayment.TotalPagado),
          activityCode: resultPayment.activityCode,
          date: resultPayment.Fecha,
          time: resultPayment.Hora,
          operation: resultPayment.Oper,
          type: resultPayment.Tipo,
          txAmount: Number(resultPayment.txAmount),
          flow: flow === 'commerce' ? 'commerce' : 'profile',
        },
      };

      console.log('buyObj', buyObj);

      const result = await TicketService.buyTicketNumberV2(ticketPayment);
      user.uuid = result.user?.uuid;
      setStorageToState();
      dispatch(setBuyTicketAction(result));
    } catch (e) {
      console.log(e);
    }
  };

  const resendEmailInvitation = async () => {
    try {
      await TicketService.resendTicket(
        ticketCode || '',
        user?.user.email,
        user?.user.fullName
      );
    } catch (e) {
      console.log(e);
    }
  };

  return (
    <AffilConfirmStyles>
      <div className='affil-confirm-container'>
        <h1 className='title-2 affil-confirm-title'>
          {t('signUpConfirmation')}
        </h1>
        <p className='paragraph affil-confirm-desc'>{t('ticketSuccess')}</p>
        <div className='affil-confirm-ticket'>
          <h2 className='label-4 affil-confirm-ticket-title'>
            {t('membershipCode')}:
          </h2>
          <p className='label-2 affil-confirm-ticket-number'>{ticketCode}</p>
          <p className='paragraph affil-confirm-ticket-desc'>
            {t('ticketSuccess1')}
          </p>
          <div className='affil-confirm-mail-container'>
            <img src={mail} />
            <p className='label-3 affil-confirm-ticket-mail'>
              {user?.user.userEmail}
            </p>
          </div>

          <HopButton
            onClick={resendEmailInvitation}
            className='affil-confirm-ticket-action'
            look='secondary'
            content={t('resendActivation')}
          />
        </div>
        <HopButton
          onClick={goToMyProfile}
          className='affil-confirm-action'
          look='primary'
          content={t('myProfile')}
        />
      </div>
    </AffilConfirmStyles>
  );
};

export default AffilConfirmPage;
