import styled from 'styled-components';
import { breakpoints } from 'theme/breakpoints';
import colors from 'theme/colors';

const AffilRegisterStyles = styled.div`
	max-width: 390px;
	margin: 0 auto;
	padding-top: 48px;
	background-color: ${colors.white};

	@media ${breakpoints.tablet} {
		max-width: 600px;
	}

	.affil-register-title {
		color: ${colors.blueDianne};
		margin-bottom: 35px;
		text-align: center;
		padding: 0 35px;
	}

	.affil-register-form {
		margin-top: 20px;
		margin-bottom: 31px;
		padding: 0 35px;
	}

	.affil-register-info {
		background-color: ${colors.bluePolar};
		padding: 21px 35px;
		margin-bottom: 26px;
		text-align: center;

		p {
			margin-bottom: 15px;

			&:last-of-type {
				margin-bottom: 0;
			}
		}
	}

	.affil-register-ticket {
		color: #6b8995;
		display: flex;
		justify-content: center;
	}

	.affil-register-nationality {
		display: flex;

		button {
			margin-right: 30px;

			&:last-of-type {
				margin-right: 0;
			}
		}
	}

	.affil-register-actions {
		display: flex;
		justify-content: space-between;
		padding: 0 35px;

		button {
			width: 250px;
			height: 45px;
		}
	}
`;

export default AffilRegisterStyles;
