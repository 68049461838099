import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { CategoryDataModel } from 'shared/models';
import { PaymentDataModel } from 'shared/models/payment.model';
import { TerritoryDataModel } from 'shared/models/territory.model';

export interface PaymentSliceState {
	ticketConfig?: CategoryDataModel.TicketConfigurationResponse;
	territory?: TerritoryDataModel.ITerritory;
	paymentResult?: PaymentDataModel.IPagueloFacilResult;
	passengersQty?: number;
}

export const PaymentSlice = createSlice({
	name: 'app',
	initialState: {
		ticketConfig: undefined,
	} as PaymentSliceState,
	reducers: {
		setTicketConfig: (
			state: PaymentSliceState,
			action: PayloadAction<CategoryDataModel.TicketConfigurationResponse>
		) => {
			state.ticketConfig = action.payload;
		},
		setTerritory: (state: PaymentSliceState, action: PayloadAction<TerritoryDataModel.ITerritory>) => {
			state.territory = action.payload;
		},
		setPaymentResult: (state: PaymentSliceState, action: PayloadAction<PaymentDataModel.IPagueloFacilResult>) => {
			state.paymentResult = action.payload;
		},

		setPassengersQty: (state: PaymentSliceState, action: PayloadAction<number>) => {
			state.passengersQty = action.payload;
		},
	},
});

export const { setTicketConfig, setTerritory, setPaymentResult, setPassengersQty } = PaymentSlice.actions;
