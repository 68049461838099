import { CategoryDataModel, TicketDataModel } from 'shared/models';
import { TerritoryDataModel } from 'shared/models/territory.model';
import {
  setBuyTicket,
  setPassengersQty,
  setTerritory,
  setTicket,
  setTicketCode,
  setTicketConfig,
} from 'store';

export const setUserTicketAction = (ticket: TicketDataModel.ITicket) => {
  return setTicket(ticket);
};

export const setBuyTicketAction = (
  ticket: TicketDataModel.IBuyTicketResponse
) => {
  return setBuyTicket(ticket);
};

export const setTicketCodeAction = (code: string) => {
  return setTicketCode(code);
};

export const setTerritoryAction = (
  territory: TerritoryDataModel.ITerritory
) => {
  return setTerritory(territory);
};

export const setTicketConfigAction = (
  ticketConfig: CategoryDataModel.TicketConfigurationResponse
) => {
  return setTicketConfig(ticketConfig);
};

export const setPassengersQtyAction = (passengersQty: number) => {
  return setPassengersQty(passengersQty);
};
