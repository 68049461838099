import styled from 'styled-components';
import colors from '../../../theme/colors';

const QRViewStyles = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  gap: 0;

  .qr-view-trips {
    font-weight: bolder;
    color: #33cccc;
  }

  .qrTicketImage {
    width: 250px;
    height: 250px;
  }

  .ticket {
    &--head {
      margin-top: 4px;
      display: flex;
      flex-direction: column;
      gap: 10px;

      &__title,
      &__subtitle {
        font-size: 14px;
        font-family: Spartan Regular, sans-serif;
        color: ${colors.blueDianne};
        line-height: 12px;
      }

      &__subtitle {
        font-family: Spartan Bold, sans-serif;
        font-size: 18px;
        font-weight: 700;
        letter-spacing: 0.8px;
      }

      &__qr {
        font-family: Spartan Bold, sans-serif;
        font-size: 32px;
        color: ${colors.blueDianne};
        margin-top: 4px;
      }
    }

    &--body {
      &__qr {
        max-width: 175px;
      }
    }

    &--footer {
      margin-top: -10px;
      display: flex;
      flex-direction: column;
      gap: 9px;

      &__title,
      &__date {
        font-family: Spartan Regular, sans-serif;
        font-size: 18px;
        color: ${colors.blueDianne};
      }

      &__date,
      &__trips {
        font-family: Spartan Bold, sans-serif;
        font-size: 20px;
      }

      &__trips {
        font-size: 35px;
        color: ${colors.lightMutedCyan};
      }
    }

    &--extras {
      display: flex;
      flex-direction: column;
      margin-top: 5px;
      &__item {
        display: flex;
        justify-content: flex-start;
        align-items: center;
        gap: 10px;
        &--detail {
          font-family: Spartan Medium, sans-serif;
          font-size: 16px;
          color: ${colors.blueDianne};
        }
      }
    }
  }
`;

export default QRViewStyles;
