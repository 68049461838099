import { createSlice, PayloadAction } from '@reduxjs/toolkit';

export interface AffiliationSliceState {
	ticketCode: string;
	commerceUuid: string;
}

export const AffiliationSlice = createSlice({
	name: 'app',
	initialState: {
		ticketCode: '',
	} as AffiliationSliceState,
	reducers: {
		setTicketCode: (state: AffiliationSliceState, action: PayloadAction<string>) => {
			state.ticketCode = action.payload;
		},
		setCommerceUuid: (state: AffiliationSliceState, action: PayloadAction<string>) => {
			state.commerceUuid = action.payload;
		},
	},
});

export const { setTicketCode, setCommerceUuid } = AffiliationSlice.actions;
