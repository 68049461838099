import { HopButton } from 'components/global/HopButton/HopButton';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router';
import { RootState } from 'store';
import MembConfirmStyles from './MembConfirm.styles';

const MembershipConfirmPage = (): JSX.Element => {
	const navigate = useNavigate();
	const { buyTicket } = useSelector((state: RootState) => state.ticket);
	const { ticketCode } = useSelector((state: RootState) => state.affiliation);
	const { t } = useTranslation();
	const goToMyProfile = () => {
		navigate('/profile');
	};

	const goToBuyMembership = () => {
		navigate('/affiliation-territory');
	};

	return (
		<MembConfirmStyles>
			<div className="memb-confirm-container">
				<h1 className="title-2 memb-confirm-title">{t('membershipRegistry')}</h1>
				<div className="memb-confirm-ticket">
					{!buyTicket && (
						<h2 className="label-2 memb-confirm-ticket-title">
							Se ha registrado
							<br /> correctamente
						</h2>
					)}
					{buyTicket && (
						<h2 className="label-2 memb-confirm-ticket-title">
							Su tiquete #{ticketCode}
							<br /> y membresía han sido activadas
						</h2>
					)}
					<p className="paragraph memb-confirm-ticket-desc">
						Le recordamos que mantenga una foto clara para que pueda identificarse fácilmente con el chofer.
					</p>
					<p className="paragraph memb-confirm-ticket-desc">
						Si no sube foto, será necesario siempre llevar una identificación para poder realizar el viaje
					</p>

					{!buyTicket && (
						<div>
							<HopButton
								onClick={goToBuyMembership}
								className="memb-button"
								look="secondary"
								content="Comprar membresia"
							/>
							<HopButton onClick={goToMyProfile} className="memb-button" look="secondary" content="Ver mi perfil" />
						</div>
					)}

					{buyTicket && (
						<div>
							<HopButton onClick={goToMyProfile} className="memb-button" look="secondary" content="Ver mi membresia" />
						</div>
					)}
				</div>
				{!buyTicket && (
					<div className="next-button-container">
						<HopButton onClick={goToMyProfile} className="memb-button" look="primary" content="Aceptar" />
					</div>
				)}
			</div>
		</MembConfirmStyles>
	);
};

export default MembershipConfirmPage;
