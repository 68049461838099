export const sizes = {
  xs: "0px",
  sm: "600px",
  md: "900px",
  lg: "1200px",
  xl: "1536px",
};

export const breakpoints = {
  phone: `(min-width: ${sizes.xs})`,
  tablet: `(min-width: ${sizes.sm})`,
  smallPC: `(min-width: ${sizes.md})`,
  pc: `(min-width: ${sizes.lg})`,
  bigPC: `(min-width: ${sizes.xl})`,
};
