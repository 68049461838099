import moment from 'moment';
import { FC, ReactElement } from 'react';
import { useTranslation } from 'react-i18next';
import QRViewStyles from './QRView.styles';
import iconTicket from 'asset/icon-ticket.svg';
export interface IQRView {
  qr?: string;
  expirationDate?: string;
  maxTrips?: number;
  currentTrips?: number;
  members?: number;
  credit?: number;
  uuid?: string;
  firebaseId?: string;
  passengersQty?: number;
  territoryName?: string;
  qr_ticket_user?: string;
}

export const QRView: FC<IQRView> = (props: IQRView): ReactElement => {
  const {
    qr,
    expirationDate,
    maxTrips,
    currentTrips,
    members,
    credit,
    uuid,
    firebaseId,
    passengersQty,
    territoryName,
    qr_ticket_user,
  } = props;
  const { t } = useTranslation();

  return (
    <QRViewStyles>
      <div className='ticket--head'>
        <span className='ticket--head__title'>{t('profileMembership')}</span>
        <div className='ticket--head__subtitle'>
          {members || ''} {t('member')} {territoryName}
        </div>
        <h1 className='ticket--head__qr'>#{qr || ''}</h1>
      </div>
      <div className='ticket--body'>
        {/* <QRCode value={uuid || ''} size={120} /> */}
        <img className='ticket--body__qr' src={qr_ticket_user} alt='QR Code' />
      </div>
      <div className='ticket--footer'>
        <span className='ticket--footer__title'>
          {t('profileMembershipDate')}
        </span>
        <span className='ticket--footer__date'>
          {moment(expirationDate).format('YY/MM/DD') || ''}
        </span>
        <span className='ticket--footer__title'>{t('availableTrips')}: </span>
        <span className='ticket--footer__trips'>
          {currentTrips}/{maxTrips}
        </span>
      </div>
      {/* <div className='ticket--extras'>
        <div className='ticket--extras__item'>
          <img
            className='ticket--extras__item--icon'
            src={iconTicket}
            alt='icon-ticket'
          />
          <div className='ticket--extras__item--detail'>
            Includes hop in Volcano
          </div>
        </div>

        <div className='ticket--extras__item'>
          <img
            className='ticket--extras__item--icon'
            src={iconTicket}
            alt='icon-ticket'
          />
          <div className='ticket--extras__item--detail'>
            Includes entrance to Baldi
          </div>
        </div>
      </div> */}
      {credit && (
        <p className='label-4'>
          {t('actualCredit')}: <br />
          <span className='label-1'>$ {credit}</span>
        </p>
      )}
    </QRViewStyles>
  );
};
