import axios, { AxiosRequestConfig, AxiosResponse } from 'axios';

const ehpAxios = axios.create({
	baseURL: process.env.REACT_APP_EHP_URL,
	headers: {
		'Content-type': 'application/json',
		Accept: 'application/json',
	},
});

ehpAxios.interceptors.request.use((request: AxiosRequestConfig) => {
	const token = localStorage.getItem('token');
	const isApiUrl = request?.url?.startsWith(process.env.REACT_APP_EHP_URL || '');

	if (token && isApiUrl) {
		request.headers = {
			Authorization: `Bearer ${token}`,
			Accept: 'application/json',
		};
	}
	return request;
});

ehpAxios.interceptors.response.use(
	(response: AxiosResponse) => {
		return response;
	},
	async function (error) {
		//localStorage.removeItem('token');
		//window.location.href = '/';
		return Promise.reject(error);
	}
);

export default ehpAxios;
