import { ChangeEvent, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router';
import AffilTripStyles from './AffilTrip.styles';
import calendarAsset from 'asset/calendar.png';
import arrowAsset from 'asset/arrow-icon.svg';
import InfoIcon from 'asset/error_icon.png';

import {
  ChakraProvider,
  FormControl,
  FormLabel,
  Container,
  Stack,
} from '@chakra-ui/react';
import Select from 'react-select';

import {
  InputGroup,
  Input as InputComponent,
  InputRightElement,
} from '@chakra-ui/react';

import { SingleDatepicker } from 'chakra-dayzed-datepicker';
import { TimeSelector } from '../../../components/global/timeSelector/TimeSelector';
import { HopButton } from '../../../components/global/HopButton/HopButton';
import { RootState } from '../../../store';
import { TerritoryService } from 'shared/services/territory.service';
import { TerritoryDataModel } from 'shared/models/territory.model';
import { PointSeatsDataModel } from 'shared/models/pointSeats.model';
import { PointSeatsService } from 'shared/services/pointSeats.sevice';
import { DIRECTION } from 'shared/services/constants';
import { setTicketConfigAction } from '../affiliation.action';
import { Whatsapp } from 'components/global/Whatsapp/Whatsapp';
import { LocalStorageService } from 'shared/services/localStorage.service';
import { PointAdditionalsService } from 'shared/services/pointsAdditionals.sevice';
import TitleBanner from '../TitleBanner/TitleBanner.page';
import { CommerceDataModel } from 'shared/models/commerce.model';
import { AffiliationItemInfo } from 'components/global/AffiliationItemInfo/AffiliationItemInfo';
import mixpanel from 'shared/services/mixpanel';
import { toogleLoading } from 'app.action';
import moment from 'moment';

export interface IStops {
  pick?: {
    zone: string;
    label: string;
    time: string;
  };
  down?: {
    zone: string;
    label: string;
    time: string;
  };
}

const AffilTrip = (): JSX.Element => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { ticketConfig } = useSelector((state: RootState) => state.payment);

  const navigate = useNavigate();

  const [date, setDate] = useState(new Date());
  const [fromHours, setFromHours] =
    useState<PointSeatsDataModel.IPointSeat[]>();
  const [toHours, setToHours] = useState<PointSeatsDataModel.IPointSeat[]>();
  const [pickUpPoint, setPickUpPoint] = useState<TerritoryDataModel.IPoint>();
  const [returnPoint, setReturnPoint] = useState<TerritoryDataModel.IPoint>();
  const [pickUpPointsList, setPickUpPointsList] =
    useState<TerritoryDataModel.IPoint[]>();
  const [returnPointsList, setReturnPointsList] =
    useState<TerritoryDataModel.IPoint[]>();
  const [pickTime, setPicktime] = useState<PointSeatsDataModel.IPointSeat>();
  const [additionals, setAdditionals] = useState<any>(null);
  const [additionalAmount, setAdditionalAmount] = useState<number>(0);
  const [route, setRoute] = useState<any>({});

  useEffect(() => {
    getSeatsAndHours();
  }, [pickUpPoint, returnPoint]);

  useEffect(() => {
    mixpanel.track('Set Itinerary Date', {
      'new user': !isAuthenticated,
      'use date': date.toDateString(),
    });
  }, [date]);
  //Actions
  const gotToSelector = async () => {
    const localData = LocalStorageService.getLocalStorage();
    dispatch(
      setTicketConfigAction({
        ...ticketConfig,
        pointInfo: {
          useDate: date,
          pickUpTime: pickTime,
          returnTime: toHours ? toHours[0]?.uuid : '',
          pickUpPoint: pickUpPoint,
          returnPoint: returnPoint,
        },
      })
    );
    LocalStorageService.updateLocalStorage({
      pointInfo: {
        useDate: date,
        pickUpTime: pickTime,
        returnTime: toHours ? toHours[0]?.uuid : '',
        pickUpPoint: pickUpPoint,
        returnPoint: returnPoint,
        additionals: additionals,
      },
    });
    const additional = +additionalAmount * +localData?.passengerQty;
    const totalAmount = +localData.selectedOption?.finalPrice + additional;

    mixpanel.register({
      territory: localData?.territory?.territoryName,
      'membership-type': localData?.selectedOption?.typeValue,
      people: localData?.passengerQty,
      from: pickUpPoint?.name,
      to: returnPoint?.name,
      'pickup-time': pickTime?.time_hour,
      additional: +additionalAmount
        ? +additionalAmount * +localData?.passengerQty
        : 0,
      'total-amout': totalAmount,
    });
    mixpanel.track('Choose Itinerary', {
      'new user': !isAuthenticated,
    });
    console.log('TERRITORY==>', localData.territory.territoryUuid);
    const parksInfo = await TerritoryService.getParksByTerritory(
      localData.territory.territoryUuid
    );
    console.log('PARKS INFO==>', parksInfo);
    if (parksInfo.length > 0) {
      navigate('/ticket/buy/tickets');
    } else {
      navigate('/registry');
    }
    // navigate('/ticket/buy/commerce');
    // navigate('/registry');
    // navigate('/ticket/buy/tickets');
  };

  //FUNCTION FOR SETTING TIME AND PICK UP POINTS AFTER SELECTING TIME
  const selectTime = async () => {
    const localData = LocalStorageService.getLocalStorage();
    // setPicktime(item);
    dispatch(
      setTicketConfigAction({
        ...ticketConfig,
        pointInfo: {
          useDate: date,
          pickUpTime: pickTime,
          returnTime: toHours ? toHours[0]?.uuid : '',
          pickUpPoint: pickUpPoint,
          returnPoint: returnPoint,
        },
      })
    );
    LocalStorageService.updateLocalStorage({
      pointInfo: {
        useDate: date,
        pickUpTime: pickTime,
        returnTime: toHours ? toHours[0]?.uuid : '',
        pickUpPoint: pickUpPoint,
        returnPoint: returnPoint,
        additionals: additionals,
      },
    });
    const additional = +additionalAmount * +localData?.passengerQty;
    const totalAmount = +localData.selectedOption?.finalPrice + additional;

    // mixpanel.register({
    //   territory: localData?.territory?.territoryName,
    //   'membership-type': localData?.selectedOption?.typeValue,
    //   people: localData?.passengerQty,
    //   from: pickUpPoint?.name,
    //   to: returnPoint?.name,
    //   'pickup-time': pickTime?.time_hour,
    //   additional: +additionalAmount
    //     ? +additionalAmount * +localData?.passengerQty
    //     : 0,
    //   'total-amout': totalAmount,
    // });
    // mixpanel.track('Choose Itinerary', {
    //   'new user': !isAuthenticated,
    // });
    // navigate('/ticket/buy/commerce');
    // navigate('/registry');
    // setPicktime(item);
  };

  const onSetStops = () => {
    gotToSelector();
  };

  const onCancel = () => {
    navigate('/ticket/buy/commerce');
  };

  const getAdditionals = async () => {
    const territory = LocalStorageService.getLocalStorage().territory;
    const route = await TerritoryService.getRouteByTerritory(
      territory.territoryUuid
    );
    console.log('ROUTE FROM ADDITIONS', route[0]);
    const additionalsResponse =
      await PointAdditionalsService.findAdditionalsByPoints(
        `${pickUpPoint?.sort}`,
        `${returnPoint?.sort}`,
        `${route[0].uuid}`
      );
    // const additionalsResponse =
    //   await PointAdditionalsService.findAdditionalsByPoints(
    //     `${pickUpPoint?.sort}`,
    //     `${returnPoint?.sort}`
    //   );
    console.log('Aditional Response', additionalsResponse);
    if (additionalsResponse) {
      mixpanel.track('Set Itinerary Additional Cost', {
        'new user': !isAuthenticated,
        'additional cost': true,
      });
      setAdditionals(additionalsResponse);
    } else {
      setAdditionals(null);
      mixpanel.track('Set Itinerary Additional Cost', {
        'new user': !isAuthenticated,
        'additional cost': false,
      });
    }
    dispatch(toogleLoading(false));
  };

  const getSeatsAndHours = async () => {
    if (pickUpPoint && returnPoint) {
      dispatch(toogleLoading(true));
      if (pickUpPoint?.sort < returnPoint?.sort) {
        const seatHoursFrom =
          await PointSeatsService.getSeatsAndHourByPointUuid(
            pickUpPoint?.uuid,
            DIRECTION.GOING
          );
        const seatHoursTo = await PointSeatsService.getSeatsAndHourByPointUuid(
          returnPoint?.uuid,
          DIRECTION.RETURN
        );
        // const seatHoursFromInventory = await PointSeatsService.getSeatsAndHourByPointUuidWithInventory(
        //   seatHoursFrom,
        //   date
        // );
        // console.log("seatHoursFrom",seatHoursFrom)
        // console.log("seatHoursTo",seatHoursTo)
        // console.log("DATE",date)
        // console.log("seatHoursFromInventory",seatHoursFromInventory)
        if (!fromHours) {
          setFromHours(seatHoursFrom);
        } else if (fromHours[0]?.uuid !== seatHoursFrom[0].uuid) {
          setFromHours(seatHoursFrom);
        }
        // setFromHours(seatHoursFromInventory);
        setToHours(seatHoursTo);
      } else {
        const seatHoursFrom =
          await PointSeatsService.getSeatsAndHourByPointUuid(
            pickUpPoint?.uuid,
            DIRECTION.RETURN
          );
        const seatHoursTo = await PointSeatsService.getSeatsAndHourByPointUuid(
          returnPoint?.uuid,
          DIRECTION.GOING
        );
        if (!fromHours) {
          setFromHours(seatHoursFrom);
        } else if (fromHours[0]?.uuid !== seatHoursFrom[0].uuid) {
          setFromHours(seatHoursFrom);
        }
        setToHours(seatHoursTo);
        // const seatHoursFromInventory = await PointSeatsService.getSeatsAndHourByPointUuidWithInventory(
        //   seatHoursFrom,
        //   date
        // );
        // setFromHours(seatHoursFromInventory);
      }
      await getAdditionals();
    }
  };

  const handleDropdownsSelection = (value: string, direction: string) => {
    if (direction === 'FROM') {
      setPickUpPoint(pickUpPointsList?.find((key) => key.uuid === value));
      const dataMix: any = pickUpPointsList?.find((key) => key.uuid === value);
      mixpanel.track('Set Itinerary FROM', {
        'new user': !isAuthenticated,
        from: dataMix.name,
      });
    } else {
      LocalStorageService.updateLocalStorage({
        returnMultiplePoint: value,
      });
      setReturnPoint(returnPointsList?.find((key) => key.uuid === value));
      const dataMix: any = returnPointsList?.find((key) => key.uuid === value);
      mixpanel.track('Set Itinerary TO', {
        'new user': !isAuthenticated,
        to: dataMix.name,
      });
    }
  };

  const init = async () => {
    const territory = LocalStorageService.getLocalStorage().territory;
    console.log('TERRITORY==>', territory);
    const routeResult = await TerritoryService.getRouteByTerritory(
      territory.territoryUuid
    );
    setRoute(routeResult[0]);
    console.log('THIS IS THE ROUTE==>', routeResult);
    const points: TerritoryDataModel.IPoint[] =
      await TerritoryService.getPointsByTerritory(territory?.territoryUuid);
    const returnPoints = [...points];
    console.log('TERRITORY UUID==>', territory?.territoryUuid);
    setPickUpPointsList(points);
    setReturnPointsList(returnPoints.reverse());
    const localData = LocalStorageService.getLocalStorage();
    localData?.pointInfo?.pickUpPoint &&
      setPickUpPoint(localData?.pointInfo?.pickUpPoint);
    localData?.pointInfo?.returnPoint &&
      setReturnPoint(localData?.pointInfo?.returnPoint);
    localData?.pointInfo?.pickUpTime &&
      setPicktime(localData?.pointInfo?.pickUpTime);
    localData?.pointInfo?.useDate &&
      setDate(new Date(localData?.pointInfo?.useDate));
    dispatch(toogleLoading(false));
  };

  const handleAdditionals = () => {
    const localData = LocalStorageService.getLocalStorage();
    if (additionals) {
      if (localData?.selectedOption?.typeKey === 'one') {
        setAdditionalAmount(additionals?.one_way);
        LocalStorageService.updateLocalStorage({
          additionalAmount: additionals?.one_way,
        });
      } else if (localData?.selectedOption?.typeKey === 'two') {
        setAdditionalAmount(additionals?.round_trip);
        LocalStorageService.updateLocalStorage({
          additionalAmount: additionals?.round_trip,
        });
      } else if (localData?.selectedOption?.typeKey === 'daily') {
        setAdditionalAmount(additionals?.daily);
        LocalStorageService.updateLocalStorage({
          additionalAmount: additionals?.daily,
        });
      } else if (localData?.selectedOption?.typeKey === 'three') {
        setAdditionalAmount(additionals?.three);
        LocalStorageService.updateLocalStorage({
          additionalAmount: additionals?.three,
        });
      } else {
        setAdditionalAmount(0);
        LocalStorageService.updateLocalStorage({
          additionalAmount: 0,
        });
      }
    } else {
      setAdditionalAmount(0);
      LocalStorageService.updateLocalStorage({
        additionalAmount: 0,
      });
    }
  };

  useEffect(() => {
    handleAdditionals();
  }, [additionals]);

  const { isAuthenticated } = useSelector((state: RootState) => state.auth);

  useEffect(() => {
    try {
      dispatch(toogleLoading(true));
      init();
      mixpanel.track('Init Itinerary', {
        'new user': !isAuthenticated,
      });
      mixpanel.time_event('Choose Itinerary');
    } catch (e) {
      console.log(e);
    }
  }, []);

  const localStorageData = LocalStorageService.getLocalStorage();
  const [commerce, setCommerce] = useState<CommerceDataModel.ICommerce>();

  return (
    <ChakraProvider>
      <AffilTripStyles>
        {/* <h1 className='title-2 affil-selection-title'>{t('ehStoptitle')}</h1> */}

        <TitleBanner
          territoryName={localStorageData?.territory?.territoryName}
          commerce={commerce?.name}
        />

        {/* <div className='item-alert-warning label-2'>
            {localStorageData?.territory?.territoryName === 'La Fortuna'
              ? t('territoryFortunaInfo')
              : t('territorySantaTereInfo')}
          </div> */}

        {additionals &&
          // <div className='item-alert-warning label-2'>
          //   <img
          //     className='infoIcon'
          //     src='https://firebasestorage.googleapis.com/v0/b/easyhop-ac384.appspot.com/o/resources%2Ferror-icon.svg?alt=media&token=be264cfc-b56e-4955-b5c3-6ddcd8342bbb'
          //     alt='Info'
          //   />
          //   <div>
          //     {`${t('additional_cost')}: ${returnPoint?.name}`}
          //     <div className='warning-list'>
          //       {/* {additionals?.daily > 0 && (
          //           <p>
          //             <strong>${additionals?.daily}</strong> for daily trip per
          //             person
          //           </p>
          //         )}
          //         {additionals?.one_way > 0 && (
          //           <p>
          //             <strong>${additionals?.one_way}</strong> for one trip per
          //             person
          //           </p>
          //         )}
          //         {additionals?.round_trip > 0 && (
          //           <p>
          //             <strong>${additionals?.round_trip}</strong> for round trip
          //             per person
          //           </p>
          //         )} */}
          //     </div>
          //   </div>
          // </div>
          null}

        <h2 className='title-3 affil-selection-subtitle'>
          {t('ehStopsubtitle')}
        </h2>
        <div className='affil-stop-form'>
          <Container maxW='70%'>
            <Stack spacing={3}>
              <FormControl>
                <FormLabel>{t('formLabelDate')}</FormLabel>
                <InputGroup className='calendarGroup'>
                  <InputRightElement
                    pointerEvents='none'
                    children={<img src={calendarAsset} alt='' />}
                  />
                  <SingleDatepicker
                    date={date}
                    minDate={moment().subtract(1, 'days').toDate()}
                    // mixpanel.track('Choose Itinerary', {
                    //   'new user': !isAuthenticated,
                    // });
                    onDateChange={(date) => {
                      const dataMix = date;
                      setDate(date);
                      console.log(date);

                      // console.log('ACTUALICE LA HORA')
                      // console.log(date)
                      return setDate(date);
                    }}
                  />
                </InputGroup>
              </FormControl>
              <FormControl>
                <FormLabel>{t('formLabelLeaving')}</FormLabel>
                <Select
                  className='basic-single'
                  isDisabled={false}
                  isLoading={false}
                  isClearable={false}
                  isRtl={false}
                  isSearchable={true}
                  name='Leaving'
                  options={pickUpPointsList
                    ?.sort(function (a, b) {
                      const nameA = a.name.toUpperCase(); // ignore upper and lowercase
                      const nameB = b.name.toUpperCase(); // ignore upper and lowercase
                      if (nameA > nameB) {
                        return -1;
                      }
                      if (nameA < nameB) {
                        return 1;
                      }

                      // names must be equal
                      return 0;
                    })
                    .reverse()
                    .map((key) => {
                      return {
                        value: key.uuid,
                        label: key.name,
                        item: key,
                      };
                    })}
                  onChange={(newValue: any) => {
                    handleDropdownsSelection(newValue?.item?.uuid, 'FROM');
                  }}
                  value={
                    pickUpPoint?.uuid
                      ? {
                          value: pickUpPoint?.uuid,
                          label: pickUpPoint?.name,
                          item: pickUpPoint,
                        }
                      : {
                          value: '',
                          label: 'Write down Location',
                          item: {},
                        }
                  }
                  placeholder={'Write down Location'}
                />
                {/* <Select
                  placeholder='Select option'
                  onChange={(e) => handleDropdownsSelection(e, 'FROM')}
                  value={pickUpPoint?.uuid}
                >
                  {pickUpPointsList?.map((point) => {
                    return <option value={point?.uuid}>{point?.name}</option>;
                  })}
                </Select> */}
              </FormControl>
              <FormControl>
                <FormLabel>{t('formLabelTo')}</FormLabel>
                <Select
                  className='basic-single'
                  isDisabled={false}
                  isLoading={false}
                  isClearable={false}
                  isRtl={false}
                  isSearchable={true}
                  name='To'
                  options={returnPointsList
                    ?.sort(function (a, b) {
                      const nameA = a.name.toUpperCase(); // ignore upper and lowercase
                      const nameB = b.name.toUpperCase(); // ignore upper and lowercase
                      if (nameA > nameB) {
                        return -1;
                      }
                      if (nameA < nameB) {
                        return 1;
                      }

                      // names must be equal
                      return 0;
                    })
                    .reverse()
                    .map((key) => {
                      return {
                        value: key.uuid,
                        label: key.name,
                        item: key,
                      };
                    })
                    .filter((key) => {
                      return key.value !== pickUpPoint?.uuid;
                    })}
                  onChange={(newValue: any) => {
                    handleDropdownsSelection(newValue?.item?.uuid, 'TO');
                  }}
                  value={
                    returnPoint?.uuid
                      ? {
                          value: returnPoint?.uuid,
                          label: returnPoint?.name,
                          item: returnPoint,
                        }
                      : {
                          value: '',
                          label: 'Write down Location',
                          item: {},
                        }
                  }
                  placeholder={'Write down Location'}
                />
                {/* <Select
                  placeholder='Select option'
                  onChange={(e) => handleDropdownsSelection(e, 'TO')}
                  value={returnPoint?.uuid}
                >
                  {returnPointsList?.map((point) => {
                    return (
                      point?.uuid !== pickUpPoint?.uuid && (
                        <option value={point?.uuid}>{point?.name}</option>
                      )
                    );
                  })}
                </Select> */}
              </FormControl>
            </Stack>
          </Container>

          {/* Aditional cost warning was here */}

          <Container marginY='25px'>
            {fromHours && (
              <FormControl>
                {/* <FormLabel textAlign='center'>
                  leaving{' '}
                  <img className='arrow-icon' src={arrowAsset} alt='arrow' />
                  <br />
                  {`${pickUpPoint?.name} to ${returnPoint?.name}`}
                </FormLabel> */}
                <Container marginY='25px' maxW='75%'>
                  <FormLabel>{t('formLabelTime')}</FormLabel>
                  <TimeSelector
                    data={fromHours}
                    date={date}
                    reservation_time={10}
                    selectedItem={pickTime?.uuid}
                    selectionEvent={
                      pickTime?.uuid === pickTime?.uuid
                        ? (item) => {
                            setPicktime(item);
                            selectTime();
                            mixpanel.track('Set Itinerary Time', {
                              'new user': !isAuthenticated,
                              'pickup-time': item?.time_hour,
                            });
                          }
                        : () => {}
                    }
                  />
                </Container>
              </FormControl>
            )}
          </Container>
          {/* <Container marginY='33px'>
            {toHours && (
              <FormControl>
                <FormLabel textAlign='center'>
                  Schedule returning from
                  <br />
                  {`${returnPoint?.name} to ${pickUpPoint?.name}`}
                </FormLabel>
                <TimeSelector
                  data={toHours}
                  selectedItem={'1'}
                  isReadOnly={true}
                  selectionEvent={() => {}}
                />
              </FormControl>
            )}
          </Container> */}
        </div>
        <AffiliationItemInfo
          members={localStorageData?.passengerQty}
          amount={localStorageData?.selectedOption?.finalPrice}
          regularPrice={localStorageData?.selectedOption?.price}
          typeName={localStorageData?.selectedOption?.typeValue}
          pointInfo={localStorageData?.pointInfo}
          additionalAmount={additionalAmount}
          passengerNumber={localStorageData?.passengerQty}
        />
        <div className='affil-stop-actions'>
          <HopButton
            onClick={onSetStops}
            look='primary'
            content={t('confirm_stop')}
            disabled={!pickTime?.uuid}
          />
          <HopButton onClick={onCancel} look='secondary' content={t('back')} />
        </div>
      </AffilTripStyles>
      <Whatsapp />
    </ChakraProvider>
  );
};

export default AffilTrip;
