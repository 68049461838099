import styled from 'styled-components';
import { breakpoints } from 'theme/breakpoints';
import colors from 'theme/colors';
import { buttonsStyles } from 'theme/typography';

const ProfileUpdateStyles = styled.div`
	max-width: 390px;
	margin: 0 auto;
	padding-top: 48px;
	background-color: ${colors.white};

	@media ${breakpoints.phone} {
		zoom: unset;
	}

	@media ${breakpoints.tablet} {
		padding-top: 80px;
		max-width: 640px;
		zoom: 80%;
	}

	.profile-update-picture {
		display: flex;
		flex-direction: column;
		align-items: center;
	}

	.profile-update-avatar {
		width: 128.8px;
		height: 128.8px;
		border-radius: 100%;
	}

	.profile-update-title {
		color: ${colors.blueDianne};
		margin-bottom: 35px;
		text-align: center;
		padding: 0 35px;
	}

	.profile-update-info {
		text-align: center;
	}

	.profile-update-ticket-label {
		color: ${colors.grayJuniper};
		margin-bottom: 15px;
	}

	.profile-update-ticket-value {
		font-family: Spartan Bold;
		margin-bottom: 35px;
	}

	.profile-update-form {
		margin-bottom: 10px;
		padding: 0 35px;
		display: flex;
		flex-direction: column;
		align-items: center;
	}

	.profile-update-actions {
		display: flex;
		justify-content: space-between;
		padding: 0 35px;

		button {
			cursor: pointer;
			width: 150px;
			@media ${breakpoints.smallPC} {
				width: 421px;
				padding: 12px 10px;
			}
		}

		@media ${breakpoints.smallPC} {
			justify-content: center;
			padding: 0px;
			flex-direction: column;
			align-items: center;
		}
	}

	.profile-update-social {
		display: flex;
		flex-direction: column;
		align-items: center;
		margin-bottom: 20px;
	}

	.memb-button {
		margin: ${buttonsStyles.verticalMarginSpacing}
		width: 210px;
		cursor: pointer;
		font-size:1.2rem;
		line-height:28px;
		max-height: 60px !important;

		@media ${breakpoints.phone} {
			width: 250px;
		}

		&:last-of-type {
			margin-bottom: 0;
		}

		@media ${breakpoints.tablet} {
			width: 421px;
			padding: 12px 10px;
		}
	}
`;

export default ProfileUpdateStyles;
