import { FC, ReactElement } from 'react';
import { useTranslation } from 'react-i18next';
import ItemInfoStyles from './ItemInfo.styles';

export interface IItemInfo {
	name?: string;
	id?: string;
	email?: string;
	avatar?: string;
	userType?: string;
}

export const ItemInfo: FC<IItemInfo> = (props: IItemInfo): ReactElement => {
	const { name, id, email, userType } = props;
	const { t } = useTranslation();

	return (
		<ItemInfoStyles>
			<div className="iteminfo-container">
				<div className="iteminfo-user">
					<label className="label-4">{name}</label>
					<label className="label-4">{id}</label>
					<label className="label-4">{email}</label>
					<label className="label-4">{t(userType || '')}</label>
				</div>
			</div>
		</ItemInfoStyles>
	);
};
