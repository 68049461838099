import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import translationEN from './en.ehi18n.json';
import translationES from './es.ehi18n.json';

i18n.use(initReactI18next).init({
	resources: {
		en: {
			translation: translationEN,
		},
		es: {
			translation: translationES,
		},
	},
});

i18n.changeLanguage('en');
export default i18n;
