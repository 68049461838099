import styled from 'styled-components';
import colors from 'theme/colors';

const WhatsappStyles = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  align-content: center;

  img {
    margin-right: 5px;
    height: 37px;
    width: 37px;
  }

  a {
    font-style: normal;
    font-weight: 500;
    font-size: 18px;
    text-align: center;
    text-decoration-line: underline;
    color: ${colors.darkBlue};
  }
`;

export default WhatsappStyles;
