import { UseFormRegisterReturn } from "react-hook-form";
import HopRadioButtonStyles from "./HopRadioButton.styles";

interface IHopRadioButton {
  label?: string;
  value: string | boolean;
  className?: string;
  active: boolean;
  clickEvent?: (value: string | boolean) => void;
  registerProps?: UseFormRegisterReturn;
  name?: string;
  isLink?: boolean;
  link?: JSX.Element;
  href?: string
}

export function HopRadioButton({
  label,
  value,
  clickEvent,
  className,
  active,
  registerProps,
  name,
  isLink,
  link,
}: IHopRadioButton): JSX.Element {
  return (
    <HopRadioButtonStyles
      type="button"
      name={name}
      {...registerProps}
      className={className}
      active={active}
      onClick={() => clickEvent && clickEvent(value)}
    >
      {!isLink && label && <span dangerouslySetInnerHTML={{__html: `${label}`}}></span>}
      {isLink && link}
      {active}
    </HopRadioButtonStyles>
  );
}
