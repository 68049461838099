
import { RouteObject } from "react-router";
import LoginPage from "./Login.page";

export const LoginRoutes: Array<RouteObject> = [
  {
    path: '/',
    element: <LoginPage />
  },
  {
    path: '/:email/:transfer',
    element: <LoginPage />
  }
];